<template>
  <div>
    <div class="el-input" :class="'el-input--'+size">
      <input class="el-input__inner" v-bind:value="formatValue" ref="input" v-on:input="updatevalue($event.target.value)" v-on:blur="onBlur" v-on:focus="selectAll" />
    </div>
  </div>
</template>

<script>
import accounting from "accounting";
export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
      desc: "数值"
    },
    symbol: {
      type: String,
      default: "",
      desc: "货币标识符"
    },
    decimal: {
      type: Number,
      default: 2,
      desc: "小数位"
    },
    size:{
      type:String,
      default:'medium',
      desc:"大小"
    },
    elvalue: [String, Number]
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    formatValue() {
      if (this.focused) {
        return this.value ? accounting.unformat(this.value) : "";
      } else {
        if (this.value === 0) {
          return accounting.formatMoney(this.value, this.symbol, this.decimal);
        } else if (
          this.value === "" ||
          this.value === null ||
          this.value === undefined
        ) {
          return "";
        } else {
          return accounting.formatMoney(this.value, this.symbol, this.decimal);
        }
      }
    }
  },
  methods: {
    updatevalue(value) {
      var formatvalue = value ? accounting.unformat(value) : "";
      this.$emit("input", formatvalue);
    },
    onBlur() {
      this.focused = false;
      this.$emit("blur", event);
      this.dispatch("ElFormItem", "el.form.blur", [this.value]);
    },
    selectAll(event) {
      this.focused = true;
      setTimeout(() => {
        event.target.select();
      }, 0);
    },

    dispatch(componentName, eventName, params) {
      var parent = this.$parent || this.$root;
      var name = parent.$options.componentName;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;

        if (parent) {
          name = parent.$options.componentName;
        }
      }
      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params));
      }
    }
  }
};
</script>