/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
/**
 * @description 系统全局配置
 */
export default {
	/**
	 * @description 全局请求域名
	 */
	globalUrl: 'https://bb2t.tp-shop.cn',
	/**
	 * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
	 */
	tokenCookieExpires: 1,
	/**
	 * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天
	 */
	passCookieExpires: 1,
	/**
	 * @description 此处修改网站名称
	 */
	webName: '',
	/**
	 * @description 是否只保持一个子菜单的展开
	 */
	uniqueOpened: true,
	/**
	 * @description token key
	 */
	TokenKey: 'JSellerToken',

	/**
	 * @description 请求超时时间，毫秒（默认2分钟）
	 */
	timeout: 10000,

	/**
	 * 底部文字，支持html语法
	 */
	footerTxt: '',

	/**
	 * 备案号
	 */
	caseNumber: '',

	Seller: 'JSeller',
}
