export const en = {
    "index":{
        "welcome":"Home",
        "order_list":"Order list",
        "founds":"Join group list",
        "delivery":"Delivery goods",
        "freight_templates":"Freight templates",
        "group_manage":"Group purchase management",
    },
    "welcome":{
        "set_store":"Edit store settings",
        "basic_message":"Basic information",
        "seller":"Seller",
        "dmini_authority":"Administrative authority",
        "administrator":"Administrator",
        "store_name":"Store name",
        "store_state":"Store state",
        "store_class":"Store class",
        "validity":"Validity period",
        "lastlogin":"Last login",
        "plat_contact_mation":"Platform contact information",
        "qqone":"QQ1",
        "qqtwo":"QQ2",
        "phone":"Phone",
        "goods_prompt":"Shop and product prompt",
        "pending_matters": "The store information you need to pay attention to and the pending items",
        "release_condition":"Store product release condition",
        "have_published":"Have published/Can the goods",
        "unlimited":"Unlimited",
        "on_offer":"On sale",
        "check_pending":"Pending review",
        "out_sold_out":"Sold off the shelves illegally",
        "wait_reply":"Consultation to be answered",
        "system_notice":"System notice",
        "notice_fine_system":"Notice of fine system",
        "on_fine":"Announcement on reporting fine system",
        "about_fake":"Instructions on reporting counterfeit goods",
        "trading_tips":"Trading tips",
        "processing_trade_orders": "The trade orders you need to process immediately (only the number of orders in the last 7 days is displayed here)",
        "recently_sold":"Recently sold",
        "order_transaction": "Order in transaction",
        "obligation":"Pending payment",
        "send_goods":"Pending shipment",
        "return_request":"Return request",
        "barter":"Replacement/Repair application",
        "partial_order":"Partial shipment order",
        "pending_order":"Pending confirmation order",
        "sales_statistics":"Sales statistics",
        "periodic_statistics":"Periodic statistics of the order volume and order amount of merchants and shops",
        "project":"Project",
        "order_size":"Order size",
        "order_amount":"Order amount",
        "yesterday_sales":"Yesterday sales",
        "monthly_sales":"Monthly sales",
        "item_number":"Single item sales ranking",
        "master_one_month": "Master the most popular products within 30 days and replenish the supply in time",
        "ranking":"Ranking",
        "merchandise_news":"Product information",
        "sales_volume":"Sales",
        "store_promotion":"Store operation promotion",
        "reasonable": "Reasonable participation in promotional activities can effectively increase product sales",
        "snap_activities":"Snap activities",
        "have_opened":"Have opened",
        "participate_platform": "Participate in panic buying activities initiated by the platform to increase the volume of merchandise transactions and shop views",
        "sales_promotion":"Product promotion",
        "within_allotted_time":"Promote discounts and promotions for selected products in the shop within the specified time period",
        "group":"Group buying activity",
        "merchant_customization":"Merchants customize the standard and rules of free delivery to promote purchase conversion rate",
        "order_preferential":"Order preferential",
        "preferential_goods": "Commodity discount packages, multiple collocations, more affordable, essential marketing methods for merchants",
        "voucher":"Voucher",
        "custom_voucher":"Custom voucher usage rules and uniformly displayed by the platform for buyers to collect",
    },
    "image_upload":{
        "upload_pictures":"上传图片",
        "rush_manage":"抢购管理",
        "group_manage":"团购管理",
        "goods_sale":"商品促销",
        "order_sale":"订单促销",
        "assemble_manage":"拼团管理",
        "advance_manage":"预售管理",
        "bargain_manage":"砍价管理",
        "coupon_manage":"代金券管理",
    }
}
  
export const zh = {
    "index":{
        "welcome":"首页",
        "order_list":"订单列表",
        "founds":"拼团列表",
        "delivery":"发货商品",
        "freight_templates":"运费模板",
        "group_manage":"团购管理",
    },
    "welcome":{
        "set_store":"编辑店铺设置",
        "basic_message":"基本信息",
        "seller":"卖家",
        "dmini_authority":"管理权限",
        "administrator":"管理员",
        "store_name":"店铺名称",
        "store_state":"店铺状态",
        "store_class":"店铺等级",
        "validity":"有效期",
        "lastlogin":"最后登录",
        "plat_contact_mation":"平台联系方式",
        "qqone":"QQ1",
        "qqtwo":"QQ2",
        "phone":"电话",
        "goods_prompt":"店铺及商品提示",
        "pending_matters":"您需要关注的店铺信息以及待处理事项",
        "release_condition":"店铺商品发布情况",
        "have_published":"已发布/可传商品",
        "unlimited":"不限",
        "on_offer":"出售中",
        "check_pending":"待审核",
        "out_sold_out":"违规下架",
        "wait_reply":"待回复咨询",
        "system_notice":"系统公告",
        "notice_fine_system":"罚款制度公告",
        "on_fine":"关于举报罚款制度公告",
        "about_fake":"关于伪劣货品举报说明",
        "trading_tips":"交易提示",
        "processing_trade_orders":"您需要立即处理的交易订单(这里只显示最近7天的订单数量)",
        "recently_sold":"近期售出",
        "order_transaction":"交易中的订单",
        "obligation":"待付款",
        "send_goods":"待发货",
        "return_request":"退货申请",
        "barter":"换货/维修申请",
        "partial_order":"部分发货订单",
        "pending_order":"待确认订单",
        "sales_statistics":"销售情况统计",
        "periodic_statistics":"按周期统计商家店铺的订单量和订单金额",
        "project":"项目",
        "order_size":"订单量",
        "order_amount":"订单金额",
        "yesterday_sales":"昨日销量",
        "monthly_sales":"月销量",
        "item_number":"单品销售排名",
        "master_one_month":"掌握30日内最热销的商品及时补充货源",
        "ranking":"排名",
        "merchandise_news":"商品信息",
        "sales_volume":"销量",
        "store_promotion":"店铺运营推广",
        "reasonable":"合理参加促销活动可以有效提升商品销量",
        "snap_activities":"抢购活动",
        "have_opened":"已开通",
        "participate_platform":"参与平台发起的抢购活动提高商品成交量及店铺浏览量",
        "sales_promotion":"商品促销",
        "within_allotted_time":"在规定时间段内对店铺中所选商品进行打折促销活动",
        "group":"团购活动",
        "merchant_customization":"商家自定义满即送标准与规则，促进购买转化率",
        "order_preferential":"订单优惠",
        "preferential_goods":"商品优惠套装、多重搭配更多实惠、商家必备营销方式",
        "voucher":"代金券",
        "custom_voucher":"自定义代金券使用规则并由平台统一展示供买家领取",
    },
    "image_upload":{
        "upload_pictures":"上传图片",
        "rush_manage":"抢购管理",
        "group_manage":"团购管理",
        "goods_sale":"商品促销",
        "order_sale":"订单促销",
        "assemble_manage":"拼团管理",
        "advance_manage":"预售管理",
        "bargain_manage":"砍价管理",
        "coupon_manage":"代金券管理",
    }
}
  