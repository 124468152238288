/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState, mapMutations,mapActions } from "vuex";
import axios from "axios";
import setSymbol from "@/mixins/setSymbol.js";
export default {
  name: "app",
  components: {},
  computed: {
    ...mapState(["openFlag"]),
  },
  mixins:[setSymbol],
  created() {
    if (process.env.VUE_APP_ISOPENSOURCE=='true') {
        this.getVersion();
    }
    console.log(process.env.VUE_APP_ISOPENSOURCE);
  },
  mounted() {
  },
  methods: {
    ...mapMutations(["updateOpenFlag"]),
   
    getVersion() {
      //获取开源提示
      axios
        .get("http://www.vueshop.com.cn/index.php?version=java")
        .then((res) => {
          // console.log(res)
          if (res.data.status == 1) {
            if (res.data.version != process.env.VUE_APP_VERSION) {
              this.updateOpenFlag(true);
              this.$confirm(res.data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  // this.$message({
                  // 	type: 'success',
                  // 	message: '删除成功!'
                  // });
                  window.location.href = "http://www.vueshop.com.cn/";
                })
                .catch(() => {
                  // this.$message({
                  // 	type: 'info',
                  // 	message: '已取消删除'
                  // });
                });
            }
          }
        });
    },
  },
};
</script>

<style>
	.tablebox{
		margin-bottom: 20px;
	}
</style>
