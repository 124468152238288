export const en = {
	"index":{
	    "seller_trend":"seller_trend",
	    "seller_manage":"seller_manage",

	},
	
	"trend":{
	   "delete":"Delete",
	   "isdelete":"是否确认删除?",
	   "hint":"提示",
	   "confirm":"确定",
	   "cancel":"取消",
	   "successfully_delete":"删除成功",
	   "undeleted":"已取消删除",
	
	},
	
}

export const zh = {
	"index":{
	"seller_trend":"动态发布",
	"seller_manage":"动态管理",

	},	
	"trend":{
		"delete":"Delete",
		"isdelete":"是否确认删除?",
		"confirm_delete":"确认删除?",
		"hint":"提示",
		"confirm":"确定",
		"cancel":"取消",
		"successfully_delete":"删除成功",
		"undeleted":"已取消删除",
	}
}