export const en = {
    "index":{
        "add_goods":"Release goods",
        "in_sale": "Goods on sale",
        "goods_offline":"Goods in the warehouse",
        "stock_list":"Stock management",
        "spec":"Product specification",
        "brand_manage":"Brand management",
        "goods_excel":"Import excel",
    },
    "edit_goods":{
        "select_goods_category":"Select goods category",
        "product_details":"Fill in product details",
        "upload_pictures":"Upload product pictures",
        "release_success": "Product release success",
        "general_information":"General information",
        "basic_information":"Basic product information",
        "goods_category":"Goods category",
        "application":"One-click application",
        "applying":"Category application in progress",
        "tax_rate":"Tax rate",
        "product_name":"Product name",
        "product_abstract":"Product introduction",
        "product_an":"Product item number",
        "commodity_brand":"Commodity brand",
        "shop_price":"Shop price",
        "market_price":"Market price",
        "cost_price":"Cost price",
        "distribution_gold":"Distribution gold",
        "product_image":"Product image",
        "commodity_video":"Commodity video",
        "unsupported_playback":"Your browser does not support video playback",
        "gram":"Gram",
        "weight":"Product weight",
        "stere":"Cubic meter",
        "volume_goods":"Product volume",
        "package_mailed":"Whether it is delivered?",
        "template_freight":"Freight templates",
        "stocks":"Total inventory",
        "virtual_sales":"Virtual sales volume",
        "present_exp":"Presenting points",
        "credits_exchange":"Exchange points",
        "commodity_keywords":"Commodity keywords",
        "candicine":"Product details description",
        "gallery_php":"Product album",
        "specification_goods":"Product specifications",
        "please_enter_specifications":"Please enter specifications",
        "prompt_action":"Operation prompt",
        "check_following_steps":"When the product is released, if the specifications are not displayed, please check the following steps",
        "general_information2": `Whether to select product category in the "General Information" tab`,
        "whether_bind_goods":"If the product category has been selected and the specifications have not been displayed, please contact the platform to confirm whether the product category is bound to the product model",
        "contact_platform":"If there is no suitable specification name, please contact the platform",
        "add_specification":"Add specification",
        "property_without": "When publishing the product, if the properties are not displayed, please check the following steps",
        "no_properties_displayed": "If the product category has been selected and the attributes have not been displayed, please contact the platform to confirm whether the product category is bound to the product model",
        "appropriate_attribute_name": "If there is no suitable attribute name, please contact the platform",
        "preserve":"Preserve",
        "region":"Region",
        "commodity_title":"The length of the product title name is at least 3 characters and the longest is 50 Chinese characters",
        "commodity_synopsis": "The longest product description cannot exceed 140 Chinese characters",
        "item_number":"Merchant item number refers to the serial number of the product managed by the merchant",
        "province":"May not fill in",
        "please_select":"Please select",
        "please_enter_amount":"Please enter the amount",
        "please_enter_weight":"Please enter weight",
        "please_enter_volume":"Please enter the volume",
        "please_enter_inventory":"Please enter inventory",
        "please_enter_present_exp":"Please enter the gift points",
        "please_credits_exchange":"Please enter the points to redeem",
        "keyword_spacing": "Multiple keywords, separated by spaces",
        "editor_content":"Display editor content (view from console)",
        "specifications_combination":"Commodity specification combination",
        "price":"Price",
        "quantity_stock":"Stock quantity",
        "commodity_attribute":"Commodity attribute",
        "unlimited":"Unlimited",
        "application_approved":"申请成功",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "successfully_added":"添加成功",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "fail_delete":"删除失败,错误原因:",
        "picture_size":"上传图片大小不能超过 2MB!",
        "cause_error":"添加失败,错误原因:",
        "quick_time":"请上传正确的视频格式",
        "quick_size":"视频大小不能超过50MB",
		"spec_item_error":"请填写规格项名称！"
    },
    "in_sale":{
        "re_examination": "After the product is removed from the shelves, platform review is required before relisting",
        "top_down": "Sorting numbers from large to small, such as the product category page, the default mobile phone home page.",
        "pc_hot": "The home page of the pc store shows recommended products and new products, and the store details on the mobile phone show hot deals",
        "seek":"Search",
        "complete":"All",
        "trade_name":"Trade name",
		"goods_sn": "商品货号",
        "redact":"Edit",
        "delete":"Delete",
        "product_name":"Product name",
        "price":"Price",
        "grounding":"On",
        "new_product":"New",
        "hot_sale":"Hot",
        "recommend":"Recommend",
        "repertory":"Inventory",
        "date_issued":"Shelf time",
        "please_enter_content":"Please enter content",
        "sort":"Sort",
        "operate":"Operation",
        "goods_sale":"出售中的商品",
        "operate_successfully":"操作成功",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
		"commodity_classification":"商品分类",
		"brand":"品牌",
    },
    "goods_offline":{
        "re_examination": "After the product is removed from the shelves, platform review is required before relisting.",
        "top_down": "Sorting numbers from large to small, such as the product category page, the default mobile phone home page.",
        "pc_hot": "The home page of the pc store shows recommended products and new products, and the store details on the mobile phone show hot deals",
        "complete":"All",
        "trade_name":"Trade name",
        "seek":"Search",
        "grounding":"On the shelf",
        "product_name":"Product name",
        "audit_status":"Audit status",
        "price":"Price",
        "repertory":"Inventory",
        "operate":"Operation",
        "copyreader":"Edit",
        "delete":"Delete",
        "check_pending":"Pending review",
        "verified":"Verified",
        "not_pass":"Not passed",
        "goods_warehouse":"Goods in the warehouse",
        "offending_merchandise":"Offending merchandise",
        "subject_review":"该商品需通过审核才能上架",
        "operate_successfully":"操作成功",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "stock_list":{
        "product_name":"Product name",
        "specification_name":"Specification name",
        "seek":"Search",
        "serial_number":"Number",
        "specification_goods":"Product specifications",
        "price":"Price",
        "quantity_stock":"Stock quantity",
        "condition":"State",
        "regular":"Normal",
        "early_warning":"Warning",
        "stock_list":"Stock list",
        "stock_log":"Stock log",
        "incorrect_quantity":"库存数量不正确",
        "amend_succeed":"修改成功!",
        "fail_modify":"修改失败,错误原因:",
    },
    "spec":{
        "add_specification_value": "The platform needs to add the specification name bound under the corresponding category, and the seller can add the specification value here.",
        "specification_items":"Specification items",
        "please_enter_specifications":"Please enter specifications",
        "operate":"Operation",
        "remove":"Remove",
        "add_specification":"Add specification",
        "present":"Submit",
        "fail_delete":"'删除失败。失败原因：",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
    },
    "brand_manage":{
        "brand_name":"Brand name",
        "seek":"Search",
        "brand_names":"Brand names",
        "brand_classific":"Brand classification",
        "status":"Status",
        "operate":"Operation",
        "redact":"Edit",
        "remove":"Remove",
        "brand_manage":"Brand list",
        "brand_add":"Add brand",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "brand":{
        "brand_name":"品牌名称",
        "brand_website":"品牌网址",
        "category_parent":"上级分类",
        "brand_logo":"品牌logo",
        "sort":"排序",
        "brand_describe":"品牌描述",
        "submit":"提 交",
        "brand_manage":"品牌列表",
        "brand_add":"添加品牌",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "successfully_added":"添加成功",
    },
    "stock_log":{
        "number":"编号",
        "goods_name":"商品名称",
        "goods_specification":"商品规格",
        "order_number":"订单号",
        "repertory":"库存",
        "stock_type":"库存类型",
        "goods_inventory":"货品库存",
        "commodity_stocks":"商品库存",
        "operator":"操作人",
        "timeof_log":"日志时间",
        "brand_add":"添加品牌",
        "stock_list":"库存列表",
        "stock_log":"库存日志",
    },
    "goods_violation":{
        "rule_one":"商品下架以后，重新上架之前需要平台审核.",
        "rule_two":"排序数字由大到小,如商品分类页面，默认的手机端首页.",
        "rule_three":"pc端店铺首页显示推荐商品与新品，手机端店铺详情显示热卖",
        "trade_name":"商品名",
        "seek":"搜索",
        "product_name":"商品名称",
        "bans_reason":"违规禁售原因",
        "noremark":"无备注",
        "price":"价格",
        "repertory":"库存",
        "operate":"操作",
        "apply_again":"重新申请审核",
        "compile":"编辑",
        "remove":"删除",
        "goods_offline":"仓库中的商品",
        "goods_violation":"违规的商品",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
        "operate_successfully":"操作成功",
    },
    "goods_excel":{
        "operation_tip":"TPshop商城操作提示：",
        "handle_one":"请将商品excel文件上传,商品excel文件请按照下方提供的excel模板来录入数据.(可点击下方按钮下载excel模板)",
        "handle_two":"修改excel文件时请勿修改其表格结构、行列名称，修改完成后务必不要修改文件编码格式及文件后缀名.",
        "handle_three":"excel数据录入时商品货号可自定义填写但不能重复.",
        "handle_four":"若想为商品导入图片,可将图片名修改为商品的货号,如商品货号为TP001,为此商品导入图片时可将图片名称修改为TP001(图片格式不限于jpg).",
        "handle_five":"上传时请注意商品分类为必选项.",
        "handle_six":"只需点复制按钮.",
        "handle_seven":"若需完善商品信息,可在上传完成后在商品列表点击编辑操作.",
        "ppt":"模板下载",
        "step_one":"步骤 1:上传文件",
        "step_two":"步骤 2:上传图片",
        "step_three":"步骤 3:提交",
        "goods_category":"商品分类",
        "copy":"复制",
        "store_classification":"本店分类",
        "excelfile_amount":"Excel文件内容数量",
        "tolead":"导入",
        "upload_files":"上传文件",
        "picture_file":"图片文件",
        "upload_pictures":"上传图片",
        "submit":"提交",
        "next_step":"下一步",
        "limited_choice":"当前限制选择 1 个文件，本次选择了",
        "total_choosing":"个文件，共选择了",
        "afile":"个文件",
        "content_copied":"内容已复制到剪切板！请粘贴到excel商品分类",
        "replication_failed":"抱歉，复制失败！",
        "classification":"内容已复制到剪切板！请粘贴到excel本店分类",
        "import_success_next":"导入成功!请点击下一步",
        "noselected_file":"没有选择文件",
        "more_photos":"照片比excle表格的内容多",
        "import_success":"导入成功!",
    }
}
  
export const zh = {
    "index":{
        "add_goods":"发布商品",
        "in_sale":"出售中的商品",
        "goods_offline":"仓库中的商品",
        "stock_list":"库存管理",
        "spec":"商品规格",
        "brand_manage":"品牌管理",
        "goods_excel":"excel导入",
    },
    "edit_goods":{
        "select_goods_category":"选择商品分类",
        "product_details":"填写商品详情",
        "upload_pictures":"上传商品图片",
        "release_success":"商品发布成功",
        "general_information":"通用信息",
        "basic_information":"商品基本信息",
        "goods_category":"商品分类",
        "application":"一键申请",
        "applying":"类目申请中",
        "tax_rate":"税率",
        "product_name":"商品名称",
        "product_abstract":"商品简介",
        "product_an":"商品货号",
        "commodity_brand":"商品品牌",
        "shop_price":"本店售价",
        "market_price":"市场价",
        "cost_price":"成本价",
        "distribution_gold":"分销金",
        "product_image":"商品图片",
        "commodity_video":"商品视频",
        "unsupported_playback":"您的浏览器不支持视频播放",
        "gram":"克",
        "weight":"商品重量",
        "stere":"立方米",
        "volume_goods":"商品体积",
        "package_mailed":"是否包邮",
        "template_freight":"运费模板",
        "stocks":"总库存",
        "virtual_sales":"虚拟销售量",
        "present_exp":"赠送积分",
        "credits_exchange":"兑换积分",
        "commodity_keywords":"商品关键词",
        "candicine":"商品详情描述",
        "gallery_php":"商品相册",
        "specification_goods":"商品规格",
        "please_enter_specifications":"请输入规格",
        "prompt_action":"操作提示",
        "check_following_steps":"发布商品时, 如果规格没有显示出来请检查以下步骤",
        "general_information2":`"通用信息"选项卡中是否选择商品分类`,
        "whether_bind_goods":"如果已选择商品分类，还没有显示出规格，请联系平台确认该商品分类是否绑定商品模型",
        "contact_platform":"如果没有合适的规格名称，请联系平台",
        "add_specification":"添加规格",
        "property_without":"发布商品时, 如果属性没有显示出来请检查以下步骤",
        "no_properties_displayed":"如果已选择商品分类，还没有显示出属性，请联系平台确认该商品分类是否绑定商品模型",
        "appropriate_attribute_name":"如果没有合适的属性名称，请联系平台",
        "preserve":"保 存",
        "region":"地区",
        "commodity_title":"商品标题名称长度至少3个字符，最长50个汉字",
        "commodity_synopsis":"商品简介最长不能超过140个汉字",
        "item_number":"商家货号是指商家管理商品的编号",
        "province":"可不填",
        "please_select":"请选择",
        "please_enter_amount":"请输入金额",
        "please_enter_weight":"请输入重量",
        "please_enter_volume":"请输入体积",
        "please_enter_inventory":"请输入库存",
        "please_enter_present_exp":"请输入赠送积分",
        "please_credits_exchange":"请输入兑换积分",
        "keyword_spacing":"多个关键词，用空格隔开",
        "editor_content":"显示编辑器内容（从控制台查看）",
        "specifications_combination":"商品规格组合",
        "price":"价格",
        "quantity_stock":"库存数量",
        "commodity_attribute":"商品属性",
        "unlimited":"不限",
        "application_approved":"申请成功",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "successfully_added":"添加成功",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "fail_delete":"删除失败,错误原因:",
        "picture_size":"上传图片大小不能超过 2MB!",
        "cause_error":"添加失败,错误原因:",
        "quick_time":"请上传正确的视频格式",
        "quick_size":"视频大小不能超过50MB",
		"spec_item_error":"请填写规格项名称！"
    },
    "in_sale":{
        "re_examination":"商品下架以后，重新上架之前需要平台审核",
        "top_down":"排序数字由大到小,如商品分类页面，默认的手机端首页.",
        "pc_hot":"pc端店铺首页显示推荐商品与新品，手机端店铺详情显示热卖",
        "seek":"搜索",
        "complete":"全部",
        "trade_name":"商品名",
		"goods_sn": "商品编号",
        "redact":"编辑",
        "delete":"删除",
        "product_name":"商品名称",
        "price":"价格",
        "grounding":"上架",
        "new_product":"新品",
        "hot_sale":"热卖",
        "recommend":"推荐",
        "repertory":"库存",
        "date_issued":"上架时间",
        "please_enter_content":"请输入内容",
        "sort":"排序",
        "operate":"操作",
        "goods_sale":"出售中的商品",
        "operate_successfully":"操作成功",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
		"commodity_classification":"商品分类",
		"brand":"品牌",
    },
    "goods_offline":{
        "re_examination":"商品下架以后，重新上架之前需要平台审核.",
        "top_down":"排序数字由大到小,如商品分类页面，默认的手机端首页.",
        "pc_hot":"pc端店铺首页显示推荐商品与新品，手机端店铺详情显示热卖",
        "complete":"全部",
        "trade_name":"商品名",
        "seek":"搜索",
        "grounding":"上架",
        "product_name":"商品名称",
        "audit_status":"审核状态",
        "price":"价格",
        "repertory":"库存",
        "operate":"操作",
        "copyreader":"编辑",
        "delete":"删除",
        "check_pending":"待审核",
        "verified":"审核通过",
        "not_pass":"未通过",
        "goods_warehouse":"仓库中的商品",
        "offending_merchandise":"违规的商品",
        "subject_review":"该商品需通过审核才能上架",
        "operate_successfully":"操作成功",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "stock_list":{
        "product_name":"商品名称",
        "specification_name":"规格名称",
        "seek":"搜索",
        "serial_number":"编号",
        "specification_goods":"商品规格",
        "price":"价格",
        "quantity_stock":"库存数量",
        "condition":"状态",
        "regular":"正常",
        "early_warning":"预警",
        "stock_list":"库存列表",
        "stock_log":"库存日志",
        "incorrect_quantity":"库存数量不正确",
        "amend_succeed":"修改成功!",
        "fail_modify":"修改失败,错误原因:",
    },
    "spec":{
        "add_specification_value":"需要平台在对应的分类下添加绑定了规格名, 卖家这里才可以添加规格值.",
        "specification_items":"规格项",
        "please_enter_specifications":"请输入规格",
        "operate":"操作",
        "remove":"删除",
        "add_specification":"添加规格",
        "present":"提交",
        "fail_delete":"'删除失败。失败原因：",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
    },
    "brand_manage":{
        "brand_name":"品牌名",
        "seek":"搜索",
        "brand_names":"品牌名称",
        "brand_classific":"品牌分类",
        "status":"状态",
        "operate":"操作",
        "redact":"编辑",
        "remove":"删除",
        "brand_manage":"品牌列表",
        "brand_add":"添加品牌",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "brand":{
        "brand_name":"品牌名称",
        "brand_website":"品牌网址",
        "category_parent":"上级分类",
        "brand_logo":"品牌logo",
        "sort":"排序",
        "brand_describe":"品牌描述",
        "submit":"提 交",
        "brand_manage":"品牌列表",
        "brand_add":"添加品牌",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "successfully_added":"添加成功",
    },
    "stock_log":{
        "number":"编号",
        "goods_name":"商品名称",
        "goods_specification":"商品规格",
        "order_number":"订单号",
        "repertory":"库存",
        "stock_type":"库存类型",
        "goods_inventory":"货品库存",
        "commodity_stocks":"商品库存",
        "operator":"操作人",
        "timeof_log":"日志时间",
        "brand_add":"添加品牌",
        "stock_list":"库存列表",
        "stock_log":"库存日志",
    },
    "goods_violation":{
        "rule_one":"商品下架以后，重新上架之前需要平台审核.",
        "rule_two":"排序数字由大到小,如商品分类页面，默认的手机端首页.",
        "rule_three":"pc端店铺首页显示推荐商品与新品，手机端店铺详情显示热卖",
        "trade_name":"商品名",
        "seek":"搜索",
        "product_name":"商品名称",
        "bans_reason":"违规禁售原因",
        "noremark":"无备注",
        "price":"价格",
        "repertory":"库存",
        "operate":"操作",
        "apply_again":"重新申请审核",
        "compile":"编辑",
        "remove":"删除",
        "goods_offline":"仓库中的商品",
        "goods_violation":"违规的商品",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
        "operate_successfully":"操作成功",
    },
    "goods_excel":{
        "operation_tip":"TPshop商城操作提示：",
        "handle_one":"请将商品excel文件上传,商品excel文件请按照下方提供的excel模板来录入数据.(可点击下方按钮下载excel模板)",
        "handle_two":"修改excel文件时请勿修改其表格结构、行列名称，修改完成后务必不要修改文件编码格式及文件后缀名.",
        "handle_three":"excel数据录入时商品货号可自定义填写但不能重复.",
        "handle_four":"若想为商品导入图片,可将图片名修改为商品的货号,如商品货号为TP001,为此商品导入图片时可将图片名称修改为TP001(图片格式不限于jpg).",
        "handle_five":"上传时请注意商品分类为必选项.",
        "handle_six":"只需点复制按钮.",
        "handle_seven":"若需完善商品信息,可在上传完成后在商品列表点击编辑操作.",
        "ppt":"模板下载",
        "step_one":"步骤 1:上传文件",
        "step_two":"步骤 2:上传图片",
        "step_three":"步骤 3:提交",
        "goods_category":"商品分类",
        "copy":"复制",
        "store_classification":"本店分类",
        "excelfile_amount":"Excel文件内容数量",
        "tolead":"导入",
        "upload_files":"上传文件",
        "picture_file":"图片文件",
        "upload_pictures":"上传图片",
        "submit":"提交",
        "next_step":"下一步",
        "limited_choice":"当前限制选择 1 个文件，本次选择了",
        "total_choosing":"个文件，共选择了",
        "afile":"个文件",
        "content_copied":"内容已复制到剪切板！请粘贴到excel商品分类",
        "replication_failed":"抱歉，复制失败！",
        "classification":"内容已复制到剪切板！请粘贴到excel本店分类",
        "import_success_next":"导入成功!请点击下一步",
        "noselected_file":"没有选择文件",
        "more_photos":"照片比excle表格的内容多",
        "import_success":"导入成功!",
    }
}
  