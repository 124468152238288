export const en = {
  "title": "Business center",
  "note": "Please enter the name of the business you applied for when you register the store. The login password is the general password of the front desk member of the mall.",
  "username": "username",
  "password": "password",
  "verification": "verification code",
  "login": "Sign in",
  "TopNav":{
    "email":"邮件",
    "home_page":"首页",
    "go_store":"前往店铺",
    "change_password":"修改密码",
    "logout_safely":"安全退出",
    "excel_lead":"excel导入",
    "quit":"退出",
    "commodity":"商品",
    "order_logistics":"订单物流",
    "promotion":"促销",
    "shop":"店铺",
	"dynamic_management":"动态",
    "after_sales":"售后服务",
    "statistics_settlement":"统计结算",
    "news_service":"客服消息",
    "account":"账号",
    "distribution":"分销",
    "goods_released":"商品发布",
    "goods_warehouse":"仓库中的商品",
    "stock_control":"库存管理",
    "specification_goods":"商品规格",
    "brand_management":"品牌管理",
    "trial_goods":"试用商品",
    "trial_goods_list":"试用商品列表",
    "order_list":"订单列表",
    "text_order":"试用订单",
    "spell_group_list":"拼团列表",
    "spell_group_order":"拼团订单",
    "delivery_goods":"发货商品",
    "template_freight":"运费模板",
    "comments":"商品评论",
    "invoice_list":"发票列表",
    "express_company":"快递公司",
    "rush_manage":"抢购管理",
    "group_manage":"团购管理",
    "sales_promotion":"商品促销",
    "order_sale":"订单促销",
    "assemble_manage":"拼团管理",
    "advance_manage":"预售管理",
    "coupon_manage":"代金券管理",
    "combination_manage":"搭配购",
    "store_set":"店铺设置",
    "store_nav_list":"店铺导航",
    "bind_class_list":"经营类目",
    "store_info":"店铺信息",
    "store_reopen":"申请续签",
    "store_class_list":"店铺分类",
    "store_collect":"店铺关注",
    "guarantee_info":"消费者保障服务",
    "address_administration":"地址管理",
    "journalism_list":"新闻列表",
	"seller_trend":"动态发布",
	"seller_manage":"动态管理",
    "consultation_manage":"咨询管理",
    "return":"退换货",
    "complaint_manage":"投诉管理",
    "withdrawal_apply":"提现申请",
    "remittance_list":"汇款列表",
    "account_history":"店铺结算记录",
    "unsettled_order":"未结算订单",
    "store_overview":"店铺概况",
    "operation_report":"运营报告",
    "sales_rank":"销售排行",
    "customer_service_set":"客服设置",
    "store_msg_list":"系统消息",
    "account_list":"账号列表",
    "account_log":"账号日志",
    "store_member":"店铺会员",
    "member_store":"会员店铺",
    "distribut_goods_list":"分销商品",
    "distribut_set":"分销设置",
    "rebate_log":"分成记录",
    "original_password":"原密码",
    "new_password":"新密码",
    "confirm_password":"确认密码",
    "cancel":"取 消",
    "confirm":"确 定",
    "new_wizard":"新手向导",
    "system_setup":"系统设置",
    "fill_set":"填写店铺基础设置",
    "set_one":"填写店铺名称、店铺logo、二级域名等信息",
    "now_fill":"现在填写",
    "slideshow":"PC店铺首页轮播图",
    "slideshow_one":"店铺轮播图可以让商家更充分展示爆款、新品或重要活动",
    "now_upload":"现在上传",
    "set_two":"系统内置多套店铺主题，供商家选择适配自身产品的风格",
    "set_now":"现在设置",
    "phone_slideshow":"手机店铺轮播图设置",
    "phone_slideshow_one":"手机店铺轮播图现在是商家营销活动和商品的重要展示入口",
    "time_longer":"下次不再显示此向导",
    "next_step":"下一步",
    "last_step":"上一步",
    "goods_data":"商品数据",
    "goods_order":"商品发布-商品分类/详情",
    "issue_one":"商品发布时商家根据实际情况选择商品分类和填写商品详情",
    "now_released":"现在发布",
    "goods_sale":"出售商品列表",
    "know_one":"商家通过查看出售中的商品列表，快速了解店铺商品情况",
    "now_add":"现在添加",
    "specifications_set":"商品规格设置",
    "specification_one":"对不同类型的商品进行规格设置，是商城运营的重要一项",
    "marketing_promotion":"营销推广",
    "view_order_list":"查看订单列表",
    "order_one":"订单列表是当前商城交易活动情况操作的一个重要功能",
    "now_viewing":"现在查看",
    "freight_template":"配置运费模板",
    "template_one":"根据配送方式配置计价方式和运费模板等",
    "configure_now":"现在配置",
    "operation_control":"业务管理",
    "seckill_activity":"抢购（秒杀）活动",
    "manner_one":"抢购活动是商城促销最常见的一种营销方式之一",
    "orders_promotional":"商品/订单促销",
    "promotion_one":"这两种是针对商品/订单指定优惠的一种促销方式",
    "group_activities":"拼团活动",
    "booking_one":"当前最火热的引流拉新和提升销量的促销方式",
    "complete":"完成",
    "complete_shop":"完成开店",
    "view_settings":"点击右上角“店铺”,可前往查看设置效果",
    "verify_one":"请输入旧密码",
    "verify_two":"请输入新密码",
    "verify_therr":"请再次输入新密码",
    "verify_four":"两次输入密码不一致!",
    "fail_modify":"修改失败,失败原因:",
  },
  "UpImg":{
    "web_upload":"本地上传",
    "photo_picture":"相册图片",
    "file_search":"文件搜索",
    "drag_file_hereor":"将文件拖到此处，或",
    "click_upload":"点击上传",
    "can_upload":"只能上传jpg/png文件，且不超过500kb",
    "cancel":"取消",
    "confirm":"确定",
    "delete":"删除",
    "drag_file_here":"将文件拖到此处",
    "upload_pictures":"上传图片",
  },
  "RightTop":{
    "shop_administration":"商家管理中心",
    "help":"帮助",
  },
  "IndexLeft":{
    "home_page":"首页",
  },
  "Foot":{
    "magpie_xi_mall":"鹊喜商城",
  },
  "CurrencyInput":{
    "numerical_value":"数值",
    "currency_identifier":"货币标识符",
    "decimal_place":"小数位",
    "size":"大小",
  }
}

export const zh = {
  "title": "商家中心",
  "note": "请输入您注册店铺时申请的商家名称，登录密码为商城前台会员通用密码。",
  "username": "用户名",
  "password": "密码",
  "verification": "验证码",
  "login": "登 录",
  "TopNav":{
    "email":"邮件",
    "home_page":"首页",
    "go_store":"前往店铺",
    "change_password":"修改密码",
    "logout_safely":"安全退出",
    "excel_lead":"excel导入",
    "quit":"退出",
    "commodity":"商品",
    "order_logistics":"订单物流",
    "promotion":"促销",
    "shop":"店铺",
    "after_sales":"售后服务",
	"dynamic_management":"动态",
    "statistics_settlement":"统计结算",
    "news_service":"客服消息",
    "account":"账号",
    "distribution":"分销",
    "goods_released":"商品发布",
    "goods_warehouse":"仓库中的商品",
    "stock_control":"库存管理",
    "specification_goods":"商品规格",
    "brand_management":"品牌管理",
    "trial_goods":"试用商品",
    "trial_goods_list":"试用商品列表",
    "order_list":"订单列表",
    "text_order":"试用订单",
    "spell_group_list":"拼团列表",
    "spell_group_order":"拼团订单",
    "delivery_goods":"发货商品",
    "template_freight":"运费模板",
    "comments":"商品评论",
    "invoice_list":"发票列表",
    "express_company":"快递公司",
    "rush_manage":"抢购管理",
    "group_manage":"团购管理",
    "sales_promotion":"商品促销",
    "order_sale":"订单促销",
    "assemble_manage":"拼团管理",
    "advance_manage":"预售管理",
    "coupon_manage":"代金券管理",
    "combination_manage":"搭配购",
    "store_nav_list":"店铺导航",
    "bind_class_list":"经营类目",
    "store_info":"店铺信息",
    "store_reopen":"申请续签",
    "store_class_list":"店铺分类",
    "store_collect":"店铺关注",
    "guarantee_info":"消费者保障服务",
    "address_administration":"地址管理",
    "journalism_list":"新闻列表",
	"seller_trend":"动态发布",
	"seller_manage":"动态管理",
    "consultation_manage":"咨询管理",
    "return":"退换货",
    "complaint_manage":"投诉管理",
    "withdrawal_apply":"提现申请",
    "remittance_list":"汇款列表",
    "account_history":"店铺结算记录",
    "unsettled_order":"未结算订单",
    "store_overview":"店铺概况",
    "operation_report":"运营报告",
    "sales_rank":"销售排行",
    "customer_service_set":"客服设置",
    "store_msg_list":"系统消息",
    "account_list":"账号列表",
    "account_log":"账号日志",
    "store_member":"店铺会员",
    "member_store":"会员店铺",
    "distribut_goods_list":"分销商品",
    "distribut_set":"分销设置",
    "rebate_log":"分成记录",
    "original_password":"原密码",
    "new_password":"新密码",
    "confirm_password":"确认密码",
    "cancel":"取 消",
    "confirm":"确 定",
    "new_wizard":"新手向导",
    "system_setup":"系统设置",
    "fill_set":"填写店铺基础设置",
    "set_one":"填写店铺名称、店铺logo、二级域名等信息",
    "now_fill":"现在填写",
    "slideshow":"PC店铺首页轮播图",
    "slideshow_one":"店铺轮播图可以让商家更充分展示爆款、新品或重要活动",
    "now_upload":"现在上传",
    "store_set":"店铺主题设置",
    "set_two":"系统内置多套店铺主题，供商家选择适配自身产品的风格",
    "set_now":"现在设置",
    "phone_slideshow":"手机店铺轮播图设置",
    "phone_slideshow_one":"手机店铺轮播图现在是商家营销活动和商品的重要展示入口",
    "time_longer":"下次不再显示此向导",
    "next_step":"下一步",
    "last_step":"上一步",
    "goods_data":"商品数据",
    "goods_order":"商品发布-商品分类/详情",
    "issue_one":"商品发布时商家根据实际情况选择商品分类和填写商品详情",
    "now_released":"现在发布",
    "goods_sale":"出售商品列表",
    "know_one":"商家通过查看出售中的商品列表，快速了解店铺商品情况",
    "now_add":"现在添加",
    "specifications_set":"商品规格设置",
    "specification_one":"对不同类型的商品进行规格设置，是商城运营的重要一项",
    "marketing_promotion":"营销推广",
    "view_order_list":"查看订单列表",
    "order_one":"订单列表是当前商城交易活动情况操作的一个重要功能",
    "now_viewing":"现在查看",
    "freight_template":"配置运费模板",
    "template_one":"根据配送方式配置计价方式和运费模板等",
    "configure_now":"现在配置",
    "operation_control":"业务管理",
    "seckill_activity":"抢购（秒杀）活动",
    "manner_one":"抢购活动是商城促销最常见的一种营销方式之一",
    "orders_promotional":"商品/订单促销",
    "promotion_one":"这两种是针对商品/订单指定优惠的一种促销方式",
    "group_activities":"拼团活动",
    "booking_one":"当前最火热的引流拉新和提升销量的促销方式",
    "complete":"完成",
    "complete_shop":"完成开店",
    "view_settings":"点击右上角“店铺”,可前往查看设置效果",
    "verify_one":"请输入旧密码",
    "verify_two":"请输入新密码",
    "verify_therr":"请再次输入新密码",
    "verify_four":"两次输入密码不一致!",
    "fail_modify":"修改失败,失败原因:",
  },
  "UpImg":{
    "web_upload":"本地上传",
    "photo_picture":"相册图片",
    "file_search":"文件搜索",
    "drag_file_hereor":"将文件拖到此处，或",
    "click_upload":"点击上传",
    "can_upload":"只能上传jpg/png文件，且不超过500kb",
    "cancel":"取消",
    "confirm":"确定",
    "delete":"删除",
    "drag_file_here":"将文件拖到此处",
    "upload_pictures":"上传图片",
  },
  "RightTop":{
    "shop_administration":"商家管理中心",
    "help":"帮助",
  },
  "IndexLeft":{
    "home_page":"首页",
  },
  "Foot":{
    "magpie_xi_mall":"鹊喜商城",
  },
  "CurrencyInput":{
    "numerical_value":"数值",
    "currency_identifier":"货币标识符",
    "decimal_place":"小数位",
    "size":"大小",
  }
}
