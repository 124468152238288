/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/router.js'
import store from '@/store/store.js'
import request from "@/utils/request.js"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Print from "@/plugs/print.js";
import currencyInput from "./components/CurrencyInput";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.use(currencyInput)
Vue.use(Print);
Vue.use(ElementUI);
Vue.prototype.request = request //把request绑定在vue实例上
Vue.prototype.$store = store
import '@/static/css/base.css' //原先项目样式
import '@/static/css/seller_center.css' //原先项目样式
import '@/static/css/perfect-scrollbar.min.css' //原先项目样式
import '@/static/font/font-awesome/css/font-awesome.min.css' //原先项目样式
import '@/static/css/newstyle.css' //vue项目新样式

import '@/static/ue/ueditor.config.js'
import '@/static/ue/ueditor.all.js'
import '@/static/ue/lang/zh-cn/zh-cn.js'
import '@/static/ue/ueditor.parse.js'


import i18n from './i18n'
Vue.prototype._i18n = i18n
router.afterEach((to, from) => { //全局路由后置守卫
	//刷新rightTop
	store.commit('$_breakTop', false)
	setTimeout(function() {
		store.commit('$_breakTop', true)
	}, 0)
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
})

router.beforeEach((to, from, next) => {
	if (!localStorage.getItem("language")) {
		let lang = navigator.language
		if (lang.includes('zh') || lang.includes('zh-CN')) {
			localStorage.setItem("language", "zh_CN")
		} else {
			localStorage.setItem("language", "en_US")
		}
	}
	next()
})

//js浮点数加法精确计算
Vue.prototype.accAdd = function(arg1, arg2) {
	var r1, r2, m;
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	return (arg1 * m + arg2 * m) / m;
}

// 定义一个全局过滤器实现日期格式化
Vue.filter('datefmt', function(val) {
	if (val > 0) {
		let date = new Date(parseInt(val) * 1000);
		let Y = date.getFullYear() + '-';
		let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
		let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
		let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
		let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
		let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
		return Y + M + D + h + m + s;
	} else {
		return "长期";
	}
})
Vue.filter('datefmtYMD', function(val) {
	let date = new Date(parseInt(val) * 1000);
	let Y = date.getFullYear() + '-';
	let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
	let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	return Y + M + D;
})
Vue.filter('datefmtYMDHM', function(val) {
	let date = new Date(parseInt(val) * 1000);
	let Y = date.getFullYear() + '-';
	let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
	let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
	let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	return Y + M + D + h + m;
})
Vue.filter('datefmtAll', function(val) {
	let date = new Date(parseInt(val) * 1000);
	let Y = date.getFullYear() + '-';
	let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
	let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
	let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
	let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
})
Vue.filter('numFilter', function(value) {
	let realVal = ''
	if (value) {
		// 截取当前数据到小数点后两位
		realVal = parseFloat(value).toFixed(2)
	} else {
		realVal = '--'
	}
	return realVal
})
new Vue({
	store,
	router,
	i18n,
    render: h => h(App),
}).$mount('#app')
