export const en = {
    "index":{
        "consultation_manage":"Consultation management",
        "return":"Return and exchange",
        "complaint_manage":"Complaint management",
    },
    "consultation_manage":{
        "comment_content":"Comment content",
        "user":"User",
        "seek":"Search",
        "batch_display":"Batch display",
        "batch_hidden":"Batch hiding",
        "batch_batches":"Batch delete",
        "goods_id":"Goods id",
        "commodity":"Commodity",
        "show":"Display",
        "consulting_type":"Consulting type",
        "add_time":"Consultation time",
        "operate":"Operation",
        "compile":"Edit",
        "consulting_list":"Commodity consultation list",
        "amendant_record":"请选择需要修改的记录!",
        "operate_successfully":"操作成功!",
        "delete_record":"请选择需要删除的记录!",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "return":{
        "rule_one":"If the goods related to the return application are not shipped, the seller will be deemed to have agreed to the refund after the seller has approved it. After the platform confirms, the platform will process the refund to the user account balance or return according to the original payment method",
        "rule_two": "If the goods related to the return application have been shipped and the seller agrees to return the goods, the seller needs to confirm receipt of the goods returned by the buyer before entering the platform refund process",
        "rule_three": "If the goods related to the return application have been shipped, and the seller agrees to return the goods, if there is a shipping fee, the shipping issue needs to be negotiated between the seller and the user",
        "until":"Until",
        "data_begin":"Startdate",
        "data_over": "Deadline",
        "please_select_status":"Please select status",
        "order_number":"Order number",
        "seek":"Search",
        "product_name":"Product name*Quantity:",
        "buyer_name":"Buyer member name:",
        "filing_date":"Application date:",
        "service_type":"Service type",
        "refund_amount":"Amount",
        "refund_advance_deposit":"Advance deposit",
        "state":"State",
        "platform_confirm":"Platform confirmation",
        "operate":"Operation",
        "dispose":"Dispose",
        "examine":"View",
        "receiving":"Receiving",
        "latest_week":"Last week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
        "product":"Return goods refund",
        "rechange":"Replacement and repair",
        "cancellation_service_order":"Service order cancellation",
        "audit_failure":"Audit failed",
        "check_pending":"Pending review",
        "verified":"Verified",
        "shipped":"Shipped",
        "received":"Received",
        "replacement_complete": "Repair/replacement completed",
        "refund_complete":"Refund completed",
        "in_arbitration":"In arbitration",
        "operate_succeed":"操作成功",
        "operate_lose":"操作失败,错误原因:",
    },
    "complaint_manage":{
        "until":"Until",
        "data_begin":"Startdate",
        "data_over": "Deadline",
        "please_select_status":"Please select status",
        "seek":"Search",
        "complaint_no":"Complaint number",
        "complaints_goods":"Complaints goods",
        "complaints_theme":"Complaints subject",
        "complaints_data":"Complaints time",
        "complaints_status":"Complaints status",
        "operate":"Operation",
        "check_processing":"Check pocessing",
        "latest_week":"Last week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
        "complaint_management":"Complaint management",
        "pending":"Pending",
        "in_dialogue":"In conversation",
        "for_arbitration":"Pending arbitration",
        "completed":"Completed",
        "complainant":"Complainant",
    },
    "refund_info":{
        "service_process":"售后服务处理",
        "return_request":"买家申请退货",
        "pr_applications":"商家处理退货申请",
        "buyer_returns":"买家退货给商家",
        "platform_audit":"确认收货，平台审核",
        "ba_refund":"买家申请退款",
        "mp_refund_application":"商家处理退款申请",
        "refund_complete":"平台审核，退款完成",
        "ba_replacement":"买家申请换货",
        "review_application":"卖家审核申请",
        "gb_seller":"商品寄回给卖家",
        "shipped_again":"确认收货，重新发货",
        "se_application":"卖家审核换货申请",
        "barter_achieve":"换货完成",
        "refund_application":"卖家退款申请",
        "refund_number":"退款编号：",
        "proposer":"申请人（买家）：",
        "refund_reason":"退款原因：",
        "without":"无",
        "returns_number":"退货数量：",
        "refund_money":"退款金额：",
        "please_enter_money":"请输入金额",
        "rule_one":"第三方支付部分原路退回",
        "advance_deposit":"退还预存款：",
        "rule_two":"余额支付部分退到用户余额",
        "refund_credits":"退还积分：",
        "please_enter_integral":"请输入积分",
        "rule_three":"积分抵扣部分退还积分",
        "refund":"退款说明：",
        "certificate_upload":"凭证上传：",
        "buyer_apply":"买家换货申请",
        "barter_number":"换货编号：",
        "barter_cause":"换货原因：",
        "barter_state":"换货说明：",
        "mh_advice":"商家处理意见",
        "postscript":"备注信息：",
        "please_enter_concent":"请输入内容",
        "handling_time":"处理时间：",
        "agree_not":"是否同意：",
        "consent":"同意",
        "refuse":"拒绝",
        "buyer_cancelled":"买家已取消",
        "disagree":"不同意",
        "receiving_affirm":"收货确认：",
        "affirm_receiving":"确认收货",
        "uesr_si":"用户发货信息",
        "express_name":"快递公司：",
        "express_money":"快递费用：",
        "express_sn":"快递单号",
        "express_time":"发货时间：",
        "reshipment_information":"重新发货信息",
        "please_choose":"请选择",
        "operation_time":"操作时间：",
        "affirm_shipments":"确定发货",
        "refund_mall_platform":"商城平台退款审核",
        "platform_affirm":"平台确认：",
        "inhand":"处理中",
        "refund_complete":"退款完成",
        "platform_note":"平台备注：",
        "deals":"相关商品交易信息",
        "amount":"数量",
        "carry":"运",
        "charge":"费",
        "free_shipping":"免运费",
        "unit_purchased_cost":"购买单价：",
        "total_order_amount":"订单总额：",
        "order_number":"订单编号：",
        "transaction_id":"付款单号：",
        "pay_name":"支付方式：",
        "online_payment":"在线支付",
        "add_time":"下单时间：",
        "pay_time":"付款时间：",
        "more":"更多",
        "money":"货",
        "man":"人",
        "delivery_address":"收货地址：",
        "contact_number":"联系电话：",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败,错误原因:",
        "shipments_succeed":"发货成功",
    },
    "rechange":{
        "rule_one":"换货/维修，无需平台审核，卖家与买家协商自行协商处理，不能达成一致时，买家可申请仲裁，请卖家填写好拒绝备注",
        "rule_two":"若退货申请相关商品已经发货，快递费用双方沟通协商，卖家同意换货时，卖家确认收到买家发回的货物，再做处理",
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "please_select_status":"请选择状态",
        "order_number":"订单编号",
        "seek":"搜索",
        "product_name":"商品名称*数量:",
        "buyer_name":"买家会员名:",
        "filing_date":"申请日期:",
        "type_service":"服务类型",
        "state":"状态",
        "platform_confirm":"平台确认",
        "operate":"操作",
        "manage":"处理",
        "examine":"查看",
        "receiving":"收货",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "return":"退货退款",
        "rechange":"换货维修",
        "cservice_order":"服务单取消",
        "audit_failure":"审核失败",
        "check_pending":"待审核",
        "verified":"审核通过",
        "shipped":"已发货",
        "received":"已收货",
        "replacement_complete":"维修/换货完成",
        "refund_complete":"退款完成",
        "in_arbitration":"仲裁中",
        "function_notdone":"功能未做",
    },
    "consultation_detail":{
        "user_consultation":"用户咨询",
        "administrator":"管理员",
        "please_enter_content":"请输入内容",
        "reply":"回复",
        "consultation_manage":"商品咨询列表",
        "consultation_detail":"回复咨询",
        "added_succeed":"添加成功!",
        "added_fail":"添加失败,错误原因:",
    },
    "complaint_detail":{
        "online_exchange":"交易投诉申请",
        "new_complaint":"新投诉",
        "be_complaint":"待申诉",
        "dialogue":"对话中",
        "arbitration":"待仲裁",
        "completed":"已完成",
        "complaint_information":"投诉信息",
        "cast":"投",
        "inform":"诉",
        "man":"人",
        "complain_theme":"投诉主题",
        "complain_time":"投诉时间",
        "complain_content":"投诉内容",
        "complain_evidence":"投诉证据",
        "no_photo":"暂无图片",
        "appeal_information":"申诉信息",
        "complaint_content":"申诉内容",
        "upload_evidence":"上传申诉证据",
        "present":"提交",
        "return_list":"返回列表",
        "appeal_time":"申诉时间",
        "appeal_evidence":"申诉证据",
        "dialogue_details":"对话详情",
        "session_log":"对话记录",
        "release_dialogue":"发布对话",
        "refresh_dialogue":"刷新对话",
        "result_handling":"处理结果",
        "result_opinion":"处理意见",
        "result_time":"处理时间",
        "trade_information":"相关商品交易信息",
        "amount":"数量",
        "carry":"运",
        "cost":"费",
        "free_shipping":"免运费",
        "total_order":"订单总额",
        "order_number":"订单编号",
        "more":"更多",
        "payment_odd":"付款单号",
        "pattern_payment":"支付方式",
        "order_time":"下单时间",
        "time_payment":"付款时间",
        "collect":"收",
        "goods":"货",
        "shipping_address":"收货地址",
        "phone":"联系电话",
        "submit_succeed":"提交成功!",
        "submit_fail":"提交失败,错误原因:",
        "issue_succeed":"发布成功!",
        "issue_fail":"发布失败,错误原因:!",
        "picture_format":"上传图片必须是JPG/GIF/PNG 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    }
}
  
export const zh = {
    "index":{
        "consultation_manage":"咨询管理",
        "return":"退货换货",
        "complaint_manage":"投诉管理",
    },
    "consultation_manage":{
        "comment_content":"评论内容",
        "user":"用户",
        "seek":"搜索",
        "batch_display":"批量显示",
        "batch_hidden":"批量隐藏",
        "batch_batches":"批量删除",
        "goods_id":"商品id",
        "commodity":"商品",
        "show":"显示",
        "consulting_type":"咨询类型",
        "add_time":"咨询时间",
        "batch_batches":"批量删除",
        "operate":"操作",
        "compile":"编辑",
        "consulting_list":"商品咨询列表",
        "amendant_record":"请选择需要修改的记录!",
        "operate_successfully":"操作成功!",
        "delete_record":"请选择需要删除的记录!",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "return":{
        "rule_one":"若退货申请相关商品未发货，卖家审核通过后，视为同意退款，平台确认后，由平台处理退款到用户账号余额或按支付原路返回",
        "rule_two":"若退货申请相关商品已经发货，卖家同意退货时，卖家需要确认收到买家发回的货物之后，再进入平台退款流程",
        "rule_three":"若退货申请相关商品已经发货，卖家同意退货时，如果有运费，运费问题需要卖家与用户协商",
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "please_select_status":"请选择状态",
        "order_number":"订单编号",
        "seek":"搜索",
        "product_name":"商品名称*数量:",
        "buyer_name":"买家会员名:",
        "filing_date":"申请日期:",
        "service_type":"服务类型",
        "refund_amount":"金额",
        "refund_advance_deposit":"预存款",
        "state":"状态",
        "platform_confirm":"平台确认",
        "operate":"操作",
        "dispose":"处理",
        "examine":"查看",
        "receiving":"收货",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "product":"退货退款",
        "rechange":"换货维修",
        "cancellation_service_order":"服务单取消",
        "audit_failure":"审核失败",
        "check_pending":"待审核",
        "verified":"审核通过",
        "shipped":"已发货",
        "received":"已收货",
        "replacement_complete":"维修/换货完成",
        "refund_complete":"退款完成",
        "in_arbitration":"仲裁中",
        "operate_succeed":"操作成功",
        "operate_lose":"操作失败,错误原因:",
    },
    "complaint_manage":{
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "please_select_status":"请选择状态",
        "seek":"搜索",
        "complaint_no":"投诉号",
        "complaints_goods":"投诉商品",
        "complaints_theme":"投诉主题",
        "complaints_data":"投诉时间",
        "complaints_status":"投诉状态",
        "operate":"操作",
        "check_processing":"查看处理",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "complaint_management":"投诉管理",
        "pending":"待处理",
        "in_dialogue":"对话中",
        "for_arbitration":"待仲裁",
        "completed":"已完成",
        "complainant":"投诉人",
    },
    "refund_info":{
        "service_process":"售后服务处理",
        "return_request":"买家申请退货",
        "pr_applications":"商家处理退货申请",
        "buyer_returns":"买家退货给商家",
        "platform_audit":"确认收货，平台审核",
        "ba_refund":"买家申请退款",
        "mp_refund_application":"商家处理退款申请",
        "refund_complete":"平台审核，退款完成",
        "ba_replacement":"买家申请换货",
        "review_application":"卖家审核申请",
        "gb_seller":"商品寄回给卖家",
        "shipped_again":"确认收货，重新发货",
        "se_application":"卖家审核换货申请",
        "barter_achieve":"换货完成",
        "refund_application":"卖家退款申请",
        "refund_number":"退款编号：",
        "proposer":"申请人（买家）：",
        "refund_reason":"退款原因：",
        "without":"无",
        "returns_number":"退货数量：",
        "refund_money":"退款金额：",
        "please_enter_money":"请输入金额",
        "rule_one":"第三方支付部分原路退回",
        "advance_deposit":"退还预存款：",
        "rule_two":"余额支付部分退到用户余额",
        "refund_credits":"退还积分：",
        "please_enter_integral":"请输入积分",
        "rule_three":"积分抵扣部分退还积分",
        "refund":"退款说明：",
        "certificate_upload":"凭证上传：",
        "buyer_apply":"买家换货申请",
        "barter_number":"换货编号：",
        "barter_cause":"换货原因：",
        "barter_state":"换货说明：",
        "mh_advice":"商家处理意见",
        "postscript":"备注信息：",
        "please_enter_concent":"请输入内容",
        "handling_time":"处理时间：",
        "agree_not":"是否同意：",
        "consent":"同意",
        "refuse":"拒绝",
        "buyer_cancelled":"买家已取消",
        "disagree":"不同意",
        "receiving_affirm":"收货确认：",
        "affirm_receiving":"确认收货",
        "uesr_si":"用户发货信息",
        "express_name":"快递公司：",
        "express_money":"快递费用：",
        "express_sn":"快递单号",
        "express_time":"发货时间：",
        "reshipment_information":"重新发货信息",
        "please_choose":"请选择",
        "operation_time":"操作时间：",
        "affirm_shipments":"确定发货",
        "refund_mall_platform":"商城平台退款审核",
        "platform_affirm":"平台确认：",
        "inhand":"处理中",
        "refund_complete":"退款完成",
        "platform_note":"平台备注：",
        "deals":"相关商品交易信息",
        "amount":"数量",
        "carry":"运",
        "charge":"费",
        "free_shipping":"免运费",
        "unit_purchased_cost":"购买单价：",
        "total_order_amount":"订单总额：",
        "order_number":"订单编号：",
        "transaction_id":"付款单号：",
        "pay_name":"支付方式：",
        "online_payment":"在线支付",
        "add_time":"下单时间：",
        "pay_time":"付款时间：",
        "more":"更多",
        "money":"货",
        "man":"人",
        "delivery_address":"收货地址：",
        "contact_number":"联系电话：",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败,错误原因:",
        "shipments_succeed":"发货成功",
    },
    "rechange":{
        "rule_one":"换货/维修，无需平台审核，卖家与买家协商自行协商处理，不能达成一致时，买家可申请仲裁，请卖家填写好拒绝备注",
        "rule_two":"若退货申请相关商品已经发货，快递费用双方沟通协商，卖家同意换货时，卖家确认收到买家发回的货物，再做处理",
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "please_select_status":"请选择状态",
        "order_number":"订单编号",
        "seek":"搜索",
        "product_name":"商品名称*数量:",
        "buyer_name":"买家会员名:",
        "filing_date":"申请日期:",
        "type_service":"服务类型",
        "state":"状态",
        "platform_confirm":"平台确认",
        "operate":"操作",
        "manage":"处理",
        "examine":"查看",
        "receiving":"收货",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "return":"退货退款",
        "rechange":"换货维修",
        "cservice_order":"服务单取消",
        "audit_failure":"审核失败",
        "check_pending":"待审核",
        "verified":"审核通过",
        "shipped":"已发货",
        "received":"已收货",
        "replacement_complete":"维修/换货完成",
        "refund_complete":"退款完成",
        "in_arbitration":"仲裁中",
        "function_notdone":"功能未做",
    },
    "consultation_detail":{
        "user_consultation":"用户咨询",
        "administrator":"管理员",
        "please_enter_content":"请输入内容",
        "reply":"回复",
        "consultation_manage":"商品咨询列表",
        "consultation_detail":"回复咨询",
        "added_succeed":"添加成功!",
        "added_fail":"添加失败,错误原因:",
    },
    "complaint_detail":{
        "online_exchange":"交易投诉申请",
        "new_complaint":"新投诉",
        "be_complaint":"待申诉",
        "dialogue":"对话中",
        "arbitration":"待仲裁",
        "completed":"已完成",
        "complaint_information":"投诉信息",
        "cast":"投",
        "inform":"诉",
        "man":"人",
        "complain_theme":"投诉主题",
        "complain_time":"投诉时间",
        "complain_content":"投诉内容",
        "complain_evidence":"投诉证据",
        "no_photo":"暂无图片",
        "appeal_information":"申诉信息",
        "complaint_content":"申诉内容",
        "upload_evidence":"上传申诉证据",
        "present":"提交",
        "return_list":"返回列表",
        "appeal_time":"申诉时间",
        "appeal_evidence":"申诉证据",
        "dialogue_details":"对话详情",
        "session_log":"对话记录",
        "release_dialogue":"发布对话",
        "refresh_dialogue":"刷新对话",
        "result_handling":"处理结果",
        "result_opinion":"处理意见",
        "result_time":"处理时间",
        "trade_information":"相关商品交易信息",
        "amount":"数量",
        "carry":"运",
        "cost":"费",
        "free_shipping":"免运费",
        "total_order":"订单总额",
        "order_number":"订单编号",
        "more":"更多",
        "payment_odd":"付款单号",
        "pattern_payment":"支付方式",
        "order_time":"下单时间",
        "time_payment":"付款时间",
        "collect":"收",
        "goods":"货",
        "shipping_address":"收货地址",
        "phone":"联系电话",
        "submit_succeed":"提交成功!",
        "submit_fail":"提交失败,错误原因:",
        "issue_succeed":"发布成功!",
        "issue_fail":"发布失败,错误原因:!",
        "picture_format":"上传图片必须是JPG/GIF/PNG 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    }
}
  