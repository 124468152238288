export const en = {
    "index":{
        "withdrawal_apply":"Withdrawal application",
        "remittance_list":"Remittance list",
        "account_history":"Store settlement record",
        "unsettled_order":"Unsettled order",
        "store_overview":"Store overview",
        "operation_report":"operation report",
        "sales_rank":"Sales rank",
    },
    "withdrawal_apply":{
        "please_select_status":"Please select status",
        "until":"Until",
        "data_begin":"Startdate",
        "data_over": "Deadline",
        "bank_account":"Bank account number",
        "account_name":"Account opening name",
        "seek":"Search",
        "applied_amount":"Applied amount",
        "bank_name":"Bank name",
        "state":"State",
        "remark":"Remarks",
        "without":"No",
        "withtime_applicationout":"Application time",
        "operate":"Operation",
        "compile":"Edit",
        "remove":"Remove",
        "remove_invalid":"Remove invalid",
        "audit_failure":"Audit failed",
        "applying":"Applying",
        "verified":"Verified",
        "transfer_completed":"Transfer has been completed",
        "latest_week":"Last week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
        "withdrawal_apply":"Withdrawal application list",
        "cash_available":"可提现资金",
        "isdelete":"是否删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "remittance_list":{
        "until":"Until",
        "data_begin":"Startdate",
        "data_over": "Deadline",
        "bank_account":"Bank account number",
        "seek":"Search",
        "applied_amount":"Applied amount",
        "account_name":"Account opening name",
        "bank_name":"Bank name",
        "state":"State",
        "remark":"Remarks",
        "without":"No",
        "operating_time":"Operating time",
        "time_application":"Application time",
        "remittance_list":"Remittance record list",
        "latest_week":"Last week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
    },
    "account_history":{
        "until":"Until",
        "data_begin":"Startdate",
        "data_over": "Deadline",
        "seek":"Search",
        "settlement_period":"Settlement period",
        "settlement_date":"Generate settlement date",
        "start_time":"Settlement start time",
        "cut_time":"Settlement deadline",
        "indent":"Order",
        "order_amount":"Order item amount",
        "tip":"Tip",
        "stores_tip":"Store tip",
        "platform_tip":"Platform tip",
        "discounts_total":"Discounts total",
        "preferential_price":"Preferential price",
        "coupon_deduction":"Coupon deduction",
        "freight":"Freight",
        "real_money":"Amount actually paid",
        "adjust_money":"Adjustment amount",
        "integral_money":"Send points amount",
        "distribution_money":"Distribution amount",
        "platformas":"Platformas",
        "taxes":"Taxes",
        "should_be":"This issue should end",
        "account_history":"Settlement record",
        "latest_week":"Last week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
        "total":"合计",
    },
    "unsettled_order":{
        "until":"Until",
        "data_begin":"Startdate",
        "data_over": "Deadline",
        "seek":"Search",
        "indent_sn":"Order SN",
        "indent_id":"Order ID",
        "delivery_time":"Delivery time",
        "order_time":"Order time",
        "order_status":"Order status",
        "shipping_status":"Shipping status",
        "order_price":"Total order price",
        "payment_amount":"Payment amount",
        "integral_amount":"Integral amount",
        "coupon":"Coupon amount",
        "balance_deduction":"Balance deduction",
        "tip":"Tip",
        "taxes_dues":"Taxes",
        "total":"Total price of goods",
        "unsettled_order":"Settlement record",
        "latest_week":"Last week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
    },
    "store_overview":{
        "today_total_sales":"Total sales today",
        "unit_price":"Unit price per customer",
        "today_order_quantity":"Today's order quantity",
        "until":"Until",
        "data_begin":"Date start",
        "data_over":"Date end",
        "date":"Date",
        "check_order":"Check order list",
        "order_quantity":"Order quantity",
        "total_sales":"Total sales",
        "guest_price":"Guest price",
        "store_profile":"Store profile",
        "transaction_amount":"交易金额",
        "number_orders":"订单数",
        "money":"金额",
    },
    "operation_report":{
        "until":"Until",
        "data_begin":"Date start",
        "data_over":"Date end",
        "date":"Date",
        "check_order":"Check order list",
        "gross_order":"Total amount of order items",
        "total_order":"Total amount of order discount",
        "total_cost":"Total cost",
        "total_logistics":"Total logistics",
        "run_reports":"Operation reports",
        "total":"合计",
        "goods_total":"商品总额",
        "coupon":"优惠金额",
        "merchandise_cost":"商品成本",
        "logistics_fees":"物流费用",
    },
    "sales_rank":{
        "ranking":"Ranking",
        "shop_name":"Product name",
        "itemno":"item number",
        "sales_volume":"Sales volume",
        "saleroom":"Sales",
        "average_price":"Average price",
        "sales_rank":"Sales rank",
    },
    "withdrawals_details":{
        "application_withdrawal":"提现申请",
        "cash_withdrawal":"提现金额：",
        "minimum_withdrawal":"最少提现额度100",
        "bank_name":"银行名称：",
        "seem":"如:支付宝,农业银行,工商银行等...",
        "bank_account":"银行账号：",
        "account_number":`"如:支付宝账号,建设银行账号`,
        "account_name":"开户名：",
        "account_holder_name":"开户人姓名",
        "confirm":"确 定",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
    },
    "store_overview_orders":{
        "order_sn":"订单SN",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "no_evaluation":"未评价",
        "have_evaluation":"已评价",
        "shipping_status":"发货状态",
        "unshipped":"未发货",
        "shipped":"已发货",
        "goods_total":"商品总价",
        "shipping_price":"物流价格",
        "total_amount":"订单总价",
        "coupon_price":"优惠券抵扣",
        "order_prom_amount":"订单优惠",
        "store_profile":"店铺概况",
        "total":"合计",
    },
    "orders":{
        "order_sn":"订单SN",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "no_evaluation":"未评价",
        "have_evaluation":"已评价",
        "shipping_status":"发货状态",
        "unshipped":"未发货",
        "shipped":"已发货",
        "total_amount":"订单总价",
        "order_amount":"付款金额",
        "integral_money":"积分金额",
        "coupon_price":"优惠金额",
        "user_money":"余额抵扣",
        "goods_price":"商品总价",
        "order_list":"订单列表",
        "account_history":"结算记录",
        "total":"合计",
    },
    "operation_report_orders":{
        "order_sn":"订单SN",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "no_evaluation":"未评价",
        "have_evaluation":"已评价",
        "shipping_status":"发货状态",
        "unshipped":"未发货",
        "shipped":"已发货",
        "goods_price":"商品总价",
        "shipping_price":"物流价格",
        "total_amount":"订单总价",
        "coupon_price":"优惠券抵扣",
        "order_prom_amount":"订单优惠",
        "store_profile":"店铺概况",
        "total":"合计",
    }
}
  
export const zh = {
    "index":{
        "withdrawal_apply":"提现申请",
        "remittance_list":"汇款列表",
        "account_history":"店铺结算记录",
        "unsettled_order":"未结算订单",
        "store_overview":"店铺概况",
        "operation_report":"运营报告",
        "sales_rank":"销售排行",
    },
    "withdrawal_apply":{
        "please_select_status":"请选择状态",
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "bank_account":"银行账号",
        "account_name":"开户名",
        "seek":"搜索",
        "applied_amount":"申请金额",
        "bank_name":"银行名称",
        "state":"状态",
        "remark":"备注",
        "without":"无",
        "time_application":"申请时间",
        "operate":"操作",
        "compile":"编辑",
        "remove":"删除",
        "remove_invalid":"删除作废",
        "audit_failure":"审核失败",
        "applying":"申请中",
        "verified":"审核通过",
        "transfer_completed":"已转款完成",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "withdrawal_apply":"提现申请列表",
        "cash_available":"可提现资金",
        "isdelete":"是否删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "remittance_list":{
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "bank_account":"银行账号",
        "seek":"搜索",
        "applied_amount":"申请金额",
        "account_name":"开户名",
        "bank_name":"银行名称",
        "state":"状态",
        "remark":"备注",
        "without":"无",
        "operating_time":"操作时间",
        "time_application":"申请时间",
        "remittance_list":"汇款记录列表",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
    },
    "account_history":{
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "seek":"搜索",
        "settlement_period":"结算时间段",
        "settlement_date":"生成结算时间",
        "start_time":"结算开始时间",
        "cut_time":"结算截至时间",
        "indent":"订单",
        "order_amount":"订单商品金额",
        "tip":"小费",
        "stores_tip":"店铺小费",
        "platform_tip":"平台小费",
        "discounts_total":"优惠总额",
        "preferential_price":"优惠价",
        "coupon_deduction":"优惠券抵扣",
        "freight":"运费",
        "real_money":"实付金额",
        "adjust_money":"调整金额",
        "integral_money":"送出积分金额",
        "distribution_money":"分销金额",
        "platformas":"平台抽成",
        "taxes":"税费",
        "should_be":"本期应结",
        "account_history":"结算记录",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "total":"合计",
    },
    "unsettled_order":{
        "until":"至",
        "data_begin":"申请日期起始",
        "data_over":"申请日期截止",
        "seek":"搜索",
        "indent_sn":"订单SN",
        "indent_id":"订单ID",
        "delivery_time":"发货时间",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "shipping_status":"发货状态",
        "order_price":"订单总价",
        "payment_amount":"付款金额",
        "integral_amount":"积分金额",
        "coupon":"优惠金额",
        "balance_deduction":"余额抵扣",
        "tip":"小费",
        "taxes_dues":"税费",
        "total":"商品总价",
        "unsettled_order":"结算记录",
        "latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
    },
    "store_overview":{
        "today_total_sales":"今日销售总额",
        "unit_price":"人均客单价",
        "today_order_quantity":"今日订单数",
        "until":"至",
        "data_begin":"日期起始",
        "data_over":"日期截止",
        "date":"日期",
        "check_order":"查看订单列表",
        "order_quantity":"订单数(笔)",
        "total_sales":"销售总额",
        "guest_price":"客单价",
        "store_profile":"店铺概况",
        "transaction_amount":"交易金额",
        "number_orders":"订单数",
        "money":"金额",
    },
    "operation_report":{
        "until":"至",
        "data_begin":"日期起始",
        "data_over":"日期截止",
        "date":"日期",
        "check_order":"查看订单列表",
        "gross_order":"订单商品总额",
        "total_order":"订单优惠总额",
        "total_cost":"成本总额",
        "total_logistics":"物流总额",
        "run_reports":"运营报告",
        "total":"合计",
        "goods_total":"商品总额",
        "coupon":"优惠金额",
        "merchandise_cost":"商品成本",
        "logistics_fees":"物流费用",
    },
    "sales_rank":{
        "ranking":"排行",
        "shop_name":"商品名称",
        "itemno":"货号",
        "sales_volume":"销售量",
        "saleroom":"销售额",
        "average_price":"均价",
        "sales_rank":"销售排行",
    },
    "withdrawals_details":{
        "application_withdrawal":"提现申请",
        "cash_withdrawal":"提现金额：",
        "minimum_withdrawal":"最少提现额度100",
        "bank_name":"银行名称：",
        "seem":"如:支付宝,农业银行,工商银行等...",
        "bank_account":"银行账号：",
        "account_number":`"如:支付宝账号,建设银行账号`,
        "account_name":"开户名：",
        "account_holder_name":"开户人姓名",
        "confirm":"确 定",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
    },
    "store_overview_orders":{
        "order_sn":"订单SN",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "no_evaluation":"未评价",
        "have_evaluation":"已评价",
        "shipping_status":"发货状态",
        "unshipped":"未发货",
        "shipped":"已发货",
        "goods_total":"商品总价",
        "shipping_price":"物流价格",
        "total_amount":"订单总价",
        "coupon_price":"优惠券抵扣",
        "order_prom_amount":"订单优惠",
        "store_profile":"店铺概况",
        "total":"合计",
    },
    "orders":{
        "order_sn":"订单SN",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "no_evaluation":"未评价",
        "have_evaluation":"已评价",
        "shipping_status":"发货状态",
        "unshipped":"未发货",
        "shipped":"已发货",
        "total_amount":"订单总价",
        "order_amount":"付款金额",
        "integral_money":"积分金额",
        "coupon_price":"优惠金额",
        "user_money":"余额抵扣",
        "goods_price":"商品总价",
        "order_list":"订单列表",
        "account_history":"结算记录",
        "total":"合计",
    },
    "operation_report_orders":{
        "order_sn":"订单SN",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "no_evaluation":"未评价",
        "have_evaluation":"已评价",
        "shipping_status":"发货状态",
        "unshipped":"未发货",
        "shipped":"已发货",
        "goods_price":"商品总价",
        "shipping_price":"物流价格",
        "total_amount":"订单总价",
        "coupon_price":"优惠券抵扣",
        "order_prom_amount":"订单优惠",
        "store_profile":"店铺概况",
        "total":"合计",
    }
}
  