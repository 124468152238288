/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const homeArr = [{
	path: '',
	redirect: {
		name: 'welcome'
	}
}, {
	path: 'welcome',
	name: 'welcome',
	component: () => import('@/view/home/welcome.vue'),
	meta: {
		title: "主页"
	}
}]

const goodsArr = [{
		path: '',
		redirect: {
			name: 'edit_goods'
		}
	}, {
		path: 'edit_goods',
		name: 'add_goods',
		component: () => import('@/view/goods/edit_goods.vue'),
		meta: {
			title: "商品发布"
		}
	}, {
		path: 'edit_goods/:goods_id',
		name: 'edit_goods',
		component: () => import('@/view/goods/edit_goods.vue'),
		meta: {
			title: "商品编辑"
		}
	}, {
		path: 'taobao_import',
		name: 'taobao_import',
		component: () => import('@/view/goods/taobao_import.vue'),
		meta: {
			title: "淘宝导入"
		}
	}, {
		path: 'excel',
		name: 'excel',
		component: () => import('@/view/goods/excel.vue'),
		meta: {
			title: "商品Excel导入/导出"
		}
	}, {
		path: 'in_sale',
		name: 'in_sale',
		component: () => import('@/view/goods/in_sale.vue'),
		meta: {
			title: "出售中的商品"
		}
	}, {
		path: 'goods_offline',
		name: 'goods_offline',
		component: () => import('@/view/goods/goods_offline.vue'),
		meta: {
			title: "仓库中的商品"
		}
	}, {
		path: 'goods_violation',
		name: 'goods_violation',
		component: () => import('@/view/goods/goods_violation.vue'),
		meta: {
			title: "违规的商品"
		}
	}, {
		path: 'stock_list',
		name: 'stock_list',
		component: () => import('@/view/goods/stock_list.vue'),
		meta: {
			title: "库存管理"
		}
	}, {
		path: 'stock_log',
		name: 'stock_log',
		component: () => import('@/view/goods/stock_log.vue'),
		meta: {
			title: "库存日志"
		}
	}, {
		path: 'spec',
		name: 'spec',
		component: () => import('@/view/goods/spec.vue'),
		meta: {
			title: "商品规格"
		}
	}, {
		path: 'brand_manage',
		name: 'brand_manage',
		component: () => import('@/view/goods/brand_manage.vue'),
		meta: {
			title: "品牌管理"
		}
	}, {
		path: 'brand',
		name: 'brand_add',
		component: () => import('@/view/goods/brand.vue'),
		meta: {
			title: "品牌添加"
		}
	}, {
		path: 'brand/:id',
		name: 'brand_edit',
		component: () => import('@/view/goods/brand.vue'),
		meta: {
			title: "品牌编辑"
		}
	}, {
		path: 'photo_manage',
		name: 'photo_manage',
		component: () => import('@/view/goods/photo_manage.vue'),
		meta: {
			title: "相册管理"
		}
	},
	{
		path: 'goods_excel',
		name: 'goods_excel',
		component: () => import('@/view/goods/goods_excel.vue'),
		meta: {
			title: "excel导入"
		}
	},
	{
		path: 'text_goods',
		name: 'text_goods',
		component: () => import(/* webpackChunkName: "text_goods_v99" */'@/view/goods/text_goods.vue'),
		meta: {
			title: "试用商品"
		}
	},
	{
		path: 'text_goodslist',
		name: 'text_goodslist',
		component: () => import('@/view/goods/text_goodslist.vue'),
		meta: {
			title: "试用商品列表"
		}
	},
	{
		path: 'update_text',
		name: 'update_text',
		component: () => import('@/view/goods/update_text.vue'),
		meta: {
			title: "试用商品修改"
		}
	},


	{
		path: 'text_goodsreceiver',
		name: 'text_goodsreceiver',
		component: () => import('@/view/goods/text_goodsreceiver.vue'),
		meta: {
			title: "试用商品申请表"
		}
	},

]

const orderArr = [{
		path: '',
		redirect: {
			name: 'order_list'
		}
	},
	{
		path: 'order_list',
		name: 'order_list',
		component: () => import('@/view/order/order_list.vue'),
		meta: {
			title: "订单列表"
		}
	},
	{
		path: 'virtuals',
		name: 'virtuals',
		component: () => import('@/view/order/virtuals.vue'),
		meta: {
			title: "虚拟订单"
		}
	},
	{
		path: 'text_order',
		name: 'text_order',
		component: () => import('@/view/order/text_order.vue'),
		meta: {
			title: "试用订单"
		}
	},

	{
		path: 'text_order_details',
		name: 'text_order_details',
		component: () => import('@/view/order/text_order_details.vue'),
		meta: {
			title: "试用订单详情"
		}
	},

	{
		path: 'text_delivery',
		name: 'text_delivery',
		component: () => import('@/view/order/text_delivery.vue'),
		meta: {
			title: "试用发货单"
		}
	},


	{
		path: 'order_list/:orderStatus',
		name: 'order_list_status',
		component: () => import('@/view/order/order_list.vue'),
		meta: {
			title: "订单列表"
		}
	}, {
		path: 'order_list/:orderStatus/:payStatus',
		name: 'orders',
		component: () => import('@/view/order/order_list.vue'),
		meta: {
			title: "订单列表"
		}
	}, {
		path: 'detail/sn/:orderSn',
		name: 'order_by_sn',
		component: () => import('@/view/order/order_detail.vue'),
		meta: {
			title: "订单详情"
		}
	}, {
		path: 'detail/id/:orderId',
		name: 'order_by_id',
		component: () => import('@/view/order/order_detail.vue'),
		meta: {
			title: "订单详情"
		}
	},
	{
		path: 'virtual_order',
		name: 'virtual_order',
		component: () => import('@/view/order/virtual_order.vue'),
		meta: {
			title: "虚拟订单"
		}
	}, {
		path: 'delivery',
		name: 'delivery',
		component: () => import('@/view/order/delivery.vue'),
		meta: {
			title: "发货商品"
		}
	},
	{
		path: 'delivery/:shippingStatus',
		name: 'delivery_shipping',
		component: () => import('@/view/order/delivery.vue'),
		meta: {
			title: "发货商品"
		}
	},
	{
		path: "order_print/:orderIds",
		name: "order_print",
		component: () => import('@/view/order/order_print.vue'),
		meta: {
			title: "订单打印"
		}
	},
	{
		path: "shipping_print/:orderIds",
		name: "shipping_print",
		component: () => import('@/view/order/shipping_print.vue'),
		meta: {
			title: "快递单打印"
		}
	},
	{
		path: "delivery_info/:orderId",
		name: "delivery_info",
		component: () => import('@/view/order/delivery_info.vue'),
		meta: {
			title: "发货设置"
		}
	}, {
		path: 'freight_templates',
		name: 'freight_templates',
		component: () => import('@/view/order/freight_templates.vue'),
		meta: {
			title: "运费模版列表"
		}
	}, {
		path: 'freight_template',
		name: 'add_freight_template',
		component: () => import('@/view/order/freight_template.vue'),
		meta: {
			title: "新增运费模版"
		}
	}, {
		path: 'freight_template/:id',
		name: 'edit_freight_template',
		component: () => import('@/view/order/freight_template.vue'),
		meta: {
			title: "编辑运费模版"
		}
	}, {
		path: 'freight_configs/:id',
		name: 'freight_configs',
		component: () => import('@/view/order/freight_configs.vue'),
		meta: {
			title: "编辑模板配置"
		}
	}, {
		path: 'comment',
		name: 'comment',
		component: () => import('@/view/order/comment.vue'),
		meta: {
			title: "商品评论"
		}
	}, {
		path: 'comment_detail/:id',
		name: 'comment_detail',
		component: () => import('@/view/order/comment_detail.vue'),
		meta: {
			title: "商品评论"
		}
	}, {
		path: 'invoice_list',
		name: 'invoice_list',
		component: () => import('@/view/order/invoice_list.vue'),
		meta: {
			title: "发票列表"
		}
	}, {
		path: 'company',
		name: 'company',
		component: () => import('@/view/order/company.vue'),
		meta: {
			title: "快递公司"
		}
	}, {
		path: 'founds',
		name: 'founds',
		component: () => import('@/view/order/founds.vue'),
		meta: {
			title: "拼团列表"
		}
	}, {
		path: 'founds/:id',
		name: 'founds_order',
		component: () => import('@/view/order/founds.vue'),
		meta: {
			title: "拼团列表"
		}
	}, {
		path: 'exerer',
		name: 'exerer',
		component: () => import('@/utils/dialogs/attachment/exerer.vue'),
	}, {
		path: 'teams',
		name: 'teams',
		component: () => import('@/view/order/teams.vue'),
		meta: {
			title: "拼团订单"
		}
	}
]

const promotionArr = [{
	path: '',
	redirect: {
		name: 'rush_manage'
	}
}, {
	path: 'rush_manage',
	name: 'rush_manage',
	component: () => import('@/view/promotion/rush_manage.vue'),
	meta: {
		title: "抢购管理"
	}
}, {
	path: 'rush_manage/add',
	name: 'add_rush',
	component: () => import('@/view/promotion/add_rush.vue'),
	meta: {
		title: "新增抢购"
	}
}, {
	path: 'rush_manage/add/:id',
	name: 'edit_rush',
	component: () => import('@/view/promotion/add_rush.vue'),
	meta: {
		title: "编辑抢购"
	}
}, {
	path: 'group_manage',
	name: 'group_manage',
	component: () => import('@/view/promotion/group_manage.vue'),
	meta: {
		title: "团购管理"
	}
}, {
	path: 'group_manage/add',
	name: 'add_group',
	component: () => import('@/view/promotion/add_group.vue'),
	meta: {
		title: "新增/团购"
	}
}, {
	path: 'group_manage/add/:id',
	name: 'edit_group',
	component: () => import('@/view/promotion/add_group.vue'),
	meta: {
		title: "编辑团购"
	}
}, {
	path: 'goods_sale',
	name: 'goods_sale',
	component: () => import('@/view/promotion/goods_sale.vue'),
	meta: {
		title: "商品促销"
	}
}, {
	path: 'goods_sale/add',
	name: 'add_sale',
	component: () => import('@/view/promotion/add_sale.vue'),
	meta: {
		title: "新增活动"
	}
}, {
	path: 'goods_sale/add/:id',
	name: 'edit_sale',
	component: () => import('@/view/promotion/add_sale.vue'),
	meta: {
		title: "编辑活动"
	}
}, {
	path: 'order_sale',
	name: 'order_sale',
	component: () => import('@/view/promotion/order_sale.vue'),
	meta: {
		title: "订单促销"
	}
}, {
	path: 'order_sale/add',
	name: 'add_order_sale',
	component: () => import('@/view/promotion/add_order_sale.vue'),
	meta: {
		title: "新增活动"
	}
}, {
	path: 'order_sale/add/:id',
	name: 'edit_order_sale',
	component: () => import('@/view/promotion/add_order_sale.vue'),
	meta: {
		title: "编辑活动"
	}
}, {
	path: 'combination_manage',
	name: 'combination_manage',
	component: () => import('@/view/promotion/combination_manage.vue'),
	meta: {
		title: "搭配购"
	}
}, {
	path: 'combination_manage/add',
	name: 'add_combination',
	component: () => import('@/view/promotion/add_combination.vue'),
	meta: {
		title: "添加搭配购套餐"
	}
}, {
	path: 'combination_manage/add/:id',
	name: 'edit_combination',
	component: () => import('@/view/promotion/add_combination.vue'),
	meta: {
		title: "编辑搭配购套餐"
	}
}, {
	path: 'assemble_manage',
	name: 'assemble_manage',
	component: () => import('@/view/promotion/assemble_manage.vue'),
	meta: {
		title: "拼团管理"
	}
}, {
	path: 'team',
	name: 'add_team',
	component: () => import('@/view/promotion/team'),
	meta: {
		title: "新增拼团"
	}
}, {
	path: 'team/:id',
	name: 'edit_team',
	component: () => import('@/view/promotion/team'),
	meta: {
		title: "编辑拼团"
	}
}, {
	path: 'advance_manage',
	name: 'advance_manage',
	component: () => import('@/view/promotion/advance_manage.vue'),
	meta: {
		title: "预售管理"
	}
}, {
	path: 'add_advance',
	name: 'add_advance',
	component: () => import('@/view/promotion/add_advance.vue'),
	meta: {
		title: "新增预售"
	}
}, {
	path: 'add_advance/:id',
	name: 'edit_advance',
	component: () => import('@/view/promotion/add_advance.vue'),
	meta: {
		title: "编辑预售"
	}
}, {
	path: 'bargain_manage',
	name: 'bargain_manage',
	component: () => import('@/view/promotion/bargain_manage.vue'),
	meta: {
		title: "砍价管理"
	}
}, {
	path: 'coupon_manage',
	name: 'coupon_manage',
	component: () => import('@/view/promotion/coupon_manage.vue'),
	meta: {
		title: "代金券管理"
	}
}, {
	path: 'coupon',
	name: 'add_coupon',
	component: () => import('@/view/promotion/coupon.vue'),
	meta: {
		title: "新增代金券"
	}
}, {
	path: 'coupon/:id',
	name: 'edit_coupon',
	component: () => import('@/view/promotion/coupon.vue'),
	meta: {
		title: "编辑代金券"
	}
}, {
	path: 'coupon_list/:id',
	name: 'coupon_list',
	component: () => import('@/view/promotion/coupon_list.vue'),
	meta: {
		title: "发放代金券列表"
	}
}]

const storeArr = [{
		path: '',
		redirect: {
			name: 'store_set'
		}
	}, {
		path: 'store_set',
		name: 'store_set',
		component: () => import('@/view/store/store_set/store_set.vue'),
		meta: {
			title: "店铺设置"
		}
	}, {
		path: 'temp',
		name: 'temp',
		component: () => import('@/view/store/store_set/temp.vue'),
		meta: {
			title: "店铺设置"
		}
	}, {
		path: 'slide_set',
		name: 'slide_set',
		component: () => import('@/view/store/store_set/slide_set.vue'),
		meta: {
			title: "幻灯片设置"
		}
	}, {
		path: 'mobile_slide_set',
		name: 'mobile_slide_set',
		component: () => import('@/view/store/store_set/mobile_slide_set.vue'),
		meta: {
			title: "手机店铺设置"
		}
	}, {
		path: 'get_point',
		name: 'get_point',
		component: () => import('@/view/store/store_set/get_point.vue'),
		meta: {
			title: "手机店铺设置"
		}
	}, {
		path: 'store_decoration',
		name: 'store_decoration',
		component: () => import('@/view/store/store_decoration.vue'),
		meta: {
			title: "店铺装修"
		}
	}, {
		path: 'store_nav_list',
		name: 'store_nav_list',
		component: () => import('@/view/store/store_nav/store_nav_list.vue'),
		meta: {
			title: "店铺导航"
		}
	}, {
		path: 'statistics',
		name: 'statistics',
		component: () => import('@/store/ueditor/dialogs/wordimage/statistice.vue'),
	}, {
		path: 'store_nav',
		name: 'store_nav_add',
		component: () => import('@/view/store/store_nav/store_nav.vue'),
		meta: {
			title: "新闻列表"
		}
	}, {
		path: 'store_nav/:id',
		name: 'store_nav_edit',
		component: () => import('@/view/store/store_nav/store_nav.vue'),
		meta: {
			title: "新闻管理"
		}
	}, {
		path: 'bind_class_list',
		name: 'bind_class_list',
		component: () => import('@/view/store/bind_class/bind_class_list.vue'),
		meta: {
			title: "经营类目"
		}
	},
	{
		path: 'store_info',
		name: 'store_info',
		component: () => import('@/view/store/store_info.vue'),
		meta: {
			title: "店铺信息"
		}
	},
	{
		path: 'store_reopen',
		name: 'store_reopen',
		component: () => import('@/view/store/store_reopen.vue'),
		meta: {
			title: "店铺信息"
		}
	}, {
		path: 'store_class_list',
		name: 'store_class_list',
		component: () => import('@/view/store/store_class/store_class_list.vue'),
		meta: {
			title: "店铺分类"
		}
	},
	{
		path: 'setADs',
		name: 'setADs',
		component: () => import('@/view/store/store_class/setADs.vue'),
		meta: {
			title: "海报详情"
		}
	},

	{
		path: 'supplier',
		name: 'supplier',
		component: () => import('@/view/store/supplier.vue'),
		meta: {
			title: "供货商"
		}
	}, {
		path: 'store_collect',
		name: 'store_collect',
		component: () => import('@/view/store/store_collect.vue'),
		meta: {
			title: "店铺关注"
		}
	}, {
		path: 'guarantee_item',
		name: 'guarantee_item',
		component: () => import('@/view/store/guarantee/guarantee_item.vue'),
		meta: {
			title: "消费者保障服务"
		}
	}, {
		path: 'guarantee_info/:grt_id',
		name: 'guarantee_info',
		component: () => import('@/view/store/guarantee/guarantee_info.vue'),
		meta: {
			title: "消费者保障服务"
		}
	}, {
		path: 'address_manage',
		name: 'address_manage',
		component: () => import('@/view/store/address/address_manage.vue'),
		meta: {
			title: "地址管理"
		}
	}, {
		path: 'address',
		name: 'address_add',
		component: () => import('@/view/store/address/address.vue'),
		meta: {
			title: "地址管理"
		}
	}, {
		path: 'address/:id',
		name: 'address_edit',
		component: () => import('@/view/store/address/address.vue'),
		meta: {
			title: "地址管理"
		}
	}, {
		path: 'news_list',
		name: 'news_list',
		component: () => import('@/view/store/news/news_list.vue'),
		meta: {
			title: "新闻列表"
		}
	},
	{
		path: 'news',
		name: 'news_add',
		component: () => import('@/view/store/news/news.vue'),
		meta: {
			title: "新闻列表"
		}
	}, {
		path: 'news/:id',
		name: 'news_edit',
		component: () => import('@/view/store/news/news.vue'),
		meta: {
			title: "新闻管理"
		}
	}
]

const doorArr = [{
	path: '',
	redirect: {
		name: 'door_set'
	}
}, {
	path: 'door_set',
	name: 'door_set',
	component: () => import('@/view/door/door_set.vue'),
	meta: {
		title: "门店管理"
	}
}]

const trendArr = [{
	path: '',
	redirect: {
		name: 'seller_trend'
	}
},{
	path: 'seller_manage',
	name: 'seller_manage',
	component: () => import('@/view/trend/seller_manage.vue'),
	meta: {
		title: "动态管理"
	}
},
	{
		path: 'seller_trend',
		name: 'seller_trend',
		component: () => import('@/view/trend/seller_trend.vue'),
		meta: {
			title: "动态发布"
		}
}]
const after_saleArr = [{
	path: '',
	redirect: {
		name: 'consultation_manage'
	}
}, {
	path: 'consultation_manage',
	name: 'consultation_manage',
	component: () => import('@/view/after_sale/consultation_manage.vue'),
	meta: {
		title: "咨询管理"
	}
}, {
	path: 'consultation_detail/:id',
	name: 'consultation_detail',
	component: () => import('@/view/after_sale/consultation_detail.vue'),
	meta: {
		title: "咨询管理"
	}
}, {
	path: 'return',
	name: 'return',
	component: () => import('@/view/after_sale/return.vue'),
	meta: {
		title: "退货换货"
	}
}, {
	path: 'rechange',
	name: 'rechange',
	component: () => import('@/view/after_sale/rechange.vue'),
	meta: {
		title: "换货维修"
	}
}, {
	path: 'refund_info/:id',
	name: 'refund_info',
	component: () => import('@/view/after_sale/refund_info.vue'),
	meta: {
		title: "退款记录"
	}
}, {
	path: 'complaint_manage',
	name: 'complaint_manage',
	component: () => import('@/view/after_sale/complaint_manage.vue'),
	meta: {
		title: "投诉管理"
	}
}, {
	path: 'complaint_detail/:id',
	name: 'complaint_detail',
	component: () => import('@/view/after_sale/complaint_detail.vue'),
	meta: {
		title: "投诉管理"
	}
}]

const statisticalArr = [{
	path: '',
	redirect: {
		name: 'withdrawal_apply'
	}
}, {
	path: 'withdrawal_apply',
	name: 'withdrawal_apply',
	component: () => import('@/view/statistical/withdrawal_apply.vue'),
	meta: {
		title: "提现申请"
	}
}, {
	path: 'withdrawals_details',
	name: 'withdrawals_details',
	component: () => import('@/view/statistical/withdrawals_details.vue'),
	meta: {
		title: "提现申请"
	}
}, {
	path: 'remittance_list',
	name: 'remittance_list',
	component: () => import('@/view/statistical/remittance_list.vue'),
	meta: {
		title: "汇款列表"
	}
}, {
	path: 'account_history',
	name: 'account_history',
	component: () => import('@/view/statistical/account_history.vue'),
	meta: {
		title: "店铺结算记录"
	}
}, {
	path: 'unsettled_order',
	name: 'unsettled_order',
	component: () => import('@/view/statistical/unsettled_order.vue'),
	meta: {
		title: "未结算订单"
	}
}, {
	path: 'store_overview',
	name: 'store_overview',
	component: () => import('@/view/statistical/store_overview.vue'),
	meta: {
		title: "店铺概况"
	}
}, {
	path: 'store_overview_orders/:startTime/:endTime',
	name: 'store_overview_orders',
	component: () => import('@/view/statistical/store_overview_orders.vue'),
	meta: {
		title: "店铺概况 - 每日订单"
	}
}, {
	path: 'operation_report',
	name: 'operation_report',
	component: () => import('@/view/statistical/operation_report.vue'),
	meta: {
		title: "运营报告"
	}
}, {
	path: 'operation_report_orders/:startTime/:endTime',
	name: 'operation_report_orders',
	component: () => import('@/view/statistical/operation_report_orders.vue'),
	meta: {
		title: "运营报告"
	}
}, {
	path: 'goods_survey',
	name: 'goods_survey',
	component: () => import('@/view/statistical/goods_survey.vue'),
	meta: {
		title: "商品表现"
	}
}, {
	path: 'activity_survey',
	name: 'activity_survey',
	component: () => import('@/view/statistical/activity_survey.vue'),
	meta: {
		title: "活动页表现"
	}
}, {
	path: 'statistics_set',
	name: 'statistics_set',
	component: () => import('@/view/statistical/statistics_set.vue'),
	meta: {
		title: "商家结算统计"
	}
}, {
	path: 'orders',
	name: 'orders',
	component: () => import('@/view/statistical/orders.vue'),
	meta: {
		title: "运营报告"
	}
}, {
	path: 'sales_rank',
	name: 'sales_rank',
	component: () => import('@/view/statistical/sales_rank.vue'),
	meta: {
		title: "销售排行"
	}
}]

const customer_serviceArr = [{
		path: '',
		redirect: {
			name: 'customer_service_set'
		}
	}, {
		path: 'customer_service_set',
		name: 'customer_service_set',
		component: () => import('@/view/customer_service/customer_service_set.vue'),
		meta: {
			title: "客服设置"
		}
	}, {
		path: 'store_msg_list',
		name: 'store_msg_list',
		component: () => import('@/view/customer_service/store_msg_list.vue'),
		meta: {
			title: "系统消息"
		}
	},
	{
		path: 'emailreceiver',
		name: 'emailreceiver',
		component: () => import('@/view/customer_service/emailreceiver.vue'),
	},
	{
		path: 'email',
		name: 'email',
		component: () => import('@/view/customer_service/email.vue'),
	},
	{
		path: 'emaillog',
		name: 'emaillog',
		component: () => import('@/view/customer_service/emaillog.vue'),
	},
]

const accountArr = [{
	path: '',
	redirect: {
		name: 'account_list'
	}
}, {
	path: 'shopowner_ewm',
	name: 'shopowner_ewm',
	component: () => import('@/view/account/shopowner_ewm.vue'),
	meta: {
		title: "店长二维码"
	}
}, {
	path: 'account_list',
	name: 'account_list',
	component: () => import('@/view/account/account_list.vue'),
	meta: {
		title: "帐号列表"
	}
}, {
	path: 'account_group',
	name: 'account_group',
	component: () => import('@/view/account/account_group.vue'),
	meta: {
		title: "账号组"
	}
}, {
	path: 'account_log',
	name: 'account_log',
	component: () => import('@/view/account/account_log.vue'),
	meta: {
		title: "账号日志"
	}
}]

const distributionArr = [{
	path: '',
	redirect: {
		name: 'store_member'
	}
}, {
	path: 'store_member',
	name: 'store_member',
	component: () => import('@/view/distribution/store_member.vue'),
	meta: {
		title: "店铺会员"
	}
}, {
	path: 'member_store',
	name: 'member_store',
	component: () => import('@/view/distribution/member_store.vue'),
	meta: {
		title: "会员店铺"
	}
}, {
	path: 'distribut_goods_list',
	name: 'distribut_goods_list',
	component: () => import('@/view/distribution/distribut_goods_list.vue'),
	meta: {
		title: "分销商品"
	}
}, {
	path: 'distribut_set',
	name: 'distribut_set',
	component: () => import('@/view/distribution/distribut_set.vue'),
	meta: {
		title: "分销设置"
	}
}, {
	path: 'rebate_log',
	name: 'rebate_log',
	component: () => import('@/view/distribution/rebate_log.vue'),
	meta: {
		title: "分成记录"
	}
}]

const MainArr = [ //顶部第一级路由
	{
		path: '',
		redirect: {
			name: 'home'
		}
	},
	{
		path: 'home',
		name: 'home',
		component: () => import('@/view/home/index.vue'),
		children: homeArr,
		meta: {
			title: "首页"
		}
	},
	{
		path: 'goods',
		name: 'goods',
		component: () => import('@/view/goods/index.vue'),
		children: goodsArr,
		meta: {
			title: "商品"
		}
	},
	{
		path: 'order',
		name: 'order',
		component: () => import('@/view/order/index.vue'),
		children: orderArr,
		meta: {
			title: "订单列表"
		}
	},
	{
		path: 'promotion',
		name: 'promotion',
		component: () => import('@/view/promotion/index.vue'),
		children: promotionArr,
		meta: {
			title: "促销"
		}
	},
	{
		path: 'store',
		name: 'store',
		component: () => import('@/view/store/index.vue'),
		children: storeArr,
		meta: {
			title: "店铺"
		}
	},

	{
		path: 'door',
		name: 'door',
		component: () => import('@/view/door/index.vue'),
		children: doorArr,
		meta: {
			title: "门店"
		}
	},

	{
		path: 'trend',
		name: 'trend',
		component: () => import('@/view/trend/index.vue'),
		children: trendArr,
		meta: {
			title: "动态"
		}
	},
	{
		path: 'after_sale',
		name: 'after_sale',
		component: () => import('@/view/after_sale/index.vue'),
		children: after_saleArr,
		meta: {
			title: "售后服务"
		}
	},
	{
		path: 'statistical',
		name: 'statistical',
		component: () => import('@/view/statistical/index.vue'),
		children: statisticalArr,
		meta: {
			title: "结算统计"
		}
	},
	{
		path: 'customer_service',
		name: 'customer_service',
		component: () => import('@/view/customer_service/index.vue'),
		children: customer_serviceArr,
		meta: {
			title: "客服消息"
		}
	},
	{
		path: 'account',
		name: 'account',
		component: () => import('@/view/account/index.vue'),
		children: accountArr,
		meta: {
			title: "账号"
		}
	},
	{
		path: 'distribution',
		name: 'distribution',
		component: () => import('@/view/distribution/index.vue'),
		children: distributionArr,
		meta: {
			title: "分销"
		}
	}
]

const routerConfig = [{
		path: '/',
		redirect: {
			name: 'welcome'
		}
	},
	{
		path: "/index",
		name: "index",
		component: () => import('@/view/index/index.vue'),
		children: MainArr //顶部第一级路由
	},
	{
		path: "/login",
		name: "login",
		component: () => import('@/view/login/login.vue'),
		children: MainArr //顶部第一级路由
	}

]

export default new Router({
	mode: process.env.VUE_APP_MODE,
	//base: '/' + process.env.VUE_APP_NAME, // nginx location路径， 也就是请求路径
	base: '', // nginx location路径， 也就是请求路径
	routes: routerConfig,
});
