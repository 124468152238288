export const en = {
    "index":{
        "order_list":"Order list",
        "founds":"Join group list",
        "teams":"Join group orders",
        "delivery":"Delivery goods",
        "freight_templates":"Freight templates",
        "comment":"Product comment",
        "invoice_list":"Invoice list",
        "company":"Express company",
    },
    "order_list":{
        "consignee":"Consignee",
        "order_number":"Order number",
        "until":"Until",
        "date_initial":"Date start",
        "the_date_as_of":"The date is due",
        "state_payment":"Payment status",
        "shipping_status":"Shipping status",
        "pattern_payment":"Payment method",
        "seek":"Search",
        "derive": "The selected row data is exported to an excel file. If the row is not selected, all data in the list will be exported",
        "batch_export":"Batch export",
        "export_distribution_list":"Export distribution list",
        "check_all":"Select all",
        "commodity":"Commodity",
        "unit_cost":"Unit price",
        "amount":"Amount",
        "buyer":"Buyer",
        "order_amount":"Order amount",
        "trading_status":"Trading status",
        "abusive_trading_practice":"Trading operation",
        "order_time":"Order time",
        "order_status":"Order status",
        "order_type":"Order type",
        "print_delivery_order":"Print delivery order",
        "contact_information":"Contact information",
        "name":"Name",
        "phone":"Phone",
        "location":"Address",
        "free_shipping":"Free shipping",
        "postage":"Postage",
        "pattern_payment":"Payment method:",
        "details":"Details",
        "delete":"Delete",
        "all_orders":"All orders",
        "to_confirmed":"To be confirmed",
        "confirmed":"Confirmed",
        "received":"Received",
        "canceled":"Canceled",
        "completed":"Completed",
        "closed":"Closed",
        "complete":"All",
        "non_payment":"Not paid",
        "have_paid":"Have paid",
        "unshipped":"Unshipped",
        "shipped":"Shipped",
        "part_shipment":"Partial shipment",
        "use_alipay_pay":"Alipay pay",
        "wechat_pay":"WeChat pay",
        "please_select":"请选择",
        "there_export":"当前条件没有可导出的数据",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
        "unselected_order":"未选择订单",
    },
    "founds":{
        "until":"Until",
        "group_date_begins":"Group start date",
        "date_tou_closed":"Group close date",
        "group_booking_id":"Group ID",
        "spell_main_order_number":"Spell main order number",
        "seek":"Search",
        "member":"Member",
        "purchase_quantity":"Purchase quantity",
        "order_amount":"Order amount",
        "consignee":"Consignee",
        "order_number":"Order number",
        "trading_status":"Trading status",
        "operate":"Operation",
        "commodity":"Commodity",
        "specification":"Specification",
        "conference_time":"Conference time",
        "spelling_single_state":"Spelling single state",
        "spell_group_details":"Spell group details",
        "short":"Not bad",
        "person":"Person",
        "canceled ":"Canceled",
        "confirmed":"Confirmed",
        "refund":"Refund",
        "has_lottery":"The prize has been drawn",
        "wait_lucky_draw":"Waiting for the draw",
        "spell_main":"Spell main",
        "contact_information":"Contact information",
        "name":"Name",
        "phone":"Phone",
        "site":"Address",
        "line_item":"Order details",
        "member":"Member",
        "all_single":"All singles",
        "stay_open_group":"Stay open group",
        "to_stay_together":"To be a group",
        "have_make":"Have a group",
        "no_clouds":"Not a group",
        "in__latest_week":"Recent week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
    },
    "teams":{
        "consignee":"Consignee",
        "order_reference":"Order number",
        "until":"Until",
        "start_order_date":"Start order date",
        "order_deadline":"Order deadline due",
        "state_payment":"Payment status",
        "shipping_status":"Shipping status",
        "seek":"Search",
        "export_data": "Export the selected row data to an excel file, if the row is not selected, all data in the list will be exported",
        "batch_export":"Batch export",
        "commodity":"Commodity",
        "order_number":"Order number",
        "spell_group_price":"Spell group price",
        "quantity":"Quantity",
        "spell_main":"Spell main",
        "member":"Member",
        "shipping_address":"Shipping address",
        "status":"Status",
        "order_time":"Order time",
        "operation":"Operation",
        "line_item":"Order details",
        "spell_group_details":"Spell group details",
        "in__latest_week":"Recent week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
        "unshipped":"Unshipped",
        "delivered":"Delivered",
        "part_shipment":"Partial shipment",
        "non_payment":"Not paid",
        "have_paid":"Have paid",
        "all_single":"All singles",
        "to_confirmed":"To be confirmed",
        "confirmed":"Confirmed",
        "received":"Received",
        "canceled":"Canceled",
        "completed":"Completed",
        "closed":"Closed",
        "there_export":"The current condition has no data that can be exported",
    },
    "delivery":{
        "three_state": `The shipping list contains three states "to be shipped", "partially shipped", and "shipped".`,
        "print_invoice":"Pending orders can be shipped, some shipped orders can continue to be shipped, and shipped orders can be printed with invoices",
        "tally_order": "The order has been shipped, if the buyer does not confirm receipt, the system will automatically settle the order according to the set settlement cycle",
        "consignee":"Consignee",
        "order_reference":"Order number",
        "until":"Until",
        "start_order_date":"Start order date",
        "order_deadline":"Order deadline due",
        "seek":"Search",
        "check_all":"Select all",
        "batch_delivery":"Batch delivery",
        "print_express_order":"Print express orders in batches",
        "print_delivery_order":"Print delivery orders in batches",
        "to_lead":"Import",
        "there_no_records":"There are currently no data records that meet the conditions",
        "order_time":"Order time",
        "order_blank":"Print the delivery order",
        "piece":"Piece",
        "buyer":"Buyer",
        "phone":"Phone",
        "shipping_address":"Shipping address",
        "freight":"Freight",
        "free_shipping":"Free shipping",
        "postage":"Postage",
        "go_delivery": "Go delivery",
        "the_delivery_details":"Delivery details",
        "express_waybill":"Print express waybill",
        "mode_distribution":"Distribution method",
        "ship_address":"Shipping address",
        "single_number_assigned":"Assigned single number",
        "order_reference":"Order number",
        "quantity_commodity":"Quantity of commodity",
        "quantity":"Quantity",
        "shipping_order":"Shipping order number",
        "confirm_delivery":"Confirm delivery",
        "in__latest_week":"Recent week",
        "last_month":"Last month",
        "last_three_months":"Last three months",
        "delivered":"Delivered",
        "part_shipment":"Partial shipment",
        "to_send_goods":"To be shipped",
        "has_return":"Returned goods",
        "limited_choice":"当前限制选择 1 个文件，本次选择了",
        "total_choosing":"个文件，共选择了",
        "afile":"个文件",
        "unselected_order":"未选择订单",
        "starting_number":"起始单号不能为空",
        "distribution":"请选择配送方式",
        "please_ship_address":"请选择发货地址",
        "istracking":"快递单号不能为空",
        "successful_delivery":"批量发货成功",
        "delivery_failure":"批量发货失败",
    },
    "freight_templates":{
        "click_add_freight_template":"Click the Add Freight Template button to add a freight template",
        "distribution_area":"Distribution area",
        "first":"First",
        "freight":"Freight",
        "continue":"Continue",
        "template_name":"Template name",
        "filetype":"Template type",
        "operation":"Operation",
        "products":"Associated products",
        "redact":"Edit",
        "edit_region":"Edit Region",
        "remove":"Remove",
        "choose_products":"Choose products",
        "check_goods":"Click to check the product to bind the product to the shipping template, uncheck it to cancel the binding.",
        "determine_configuration":"When you close the pop-up window, click OK to save the configuration.",
        "please_choose":"Please choose",
        "unassociated_goods":"Unassociated goods",
        "associated_goods":"Associated goods",
        "commodity_classification":"Commodity classification",
        "brand":"Brand",
        "product_name":"Product name",
        "seek":"Search",
        "goods_id":"Goods ID",
        "product_code":"Product code",
        "price":"Price",
        "market_price":"Market price",
        "serepertoryk":"Inventory",
        "template_list":"Template list",
		"bind_check_goods":"绑定勾选商品",
        "add_freight_template":"Add freight template",
        "number_units":"件数",
        "shop_weight":"商品重量",
        "volume_goods":"商品体积",
        "noremark":"无备注",
        "letter":"件数(件)",
        "weight":"重量(克)",
        "gram":"体积(立方米)",
        "stere":"立方米",
        "China":"全地区",
        "without":"无",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
        "save_deploy":"确认不保存离开?",
        "operate_successfully":"操作成功",
    },
    "comment":{
        "comment_content":"Comment content",
        "user":"User",
        "seek":"Search",
        "commodity":"Commodity",
        "add_time":"Comment time",
        "ip_address":"IP address",
        "operation":"Operation",
        "reply":"Reply",
        "product_review_list":"Product review list",
    },
    "invoice_list":{
        "until":"Until",
        "start_date":"Start date",
        "date_closed":"Close date",
        "order_reference":"Order number",
        "seek":"Search",
        "invoice_type":"Type",
        "invoice_amount":"Amount",
        "invoice_title":"Title",
        "invoice_content":"Content",
        "tax_invoice":"Tax rate",
        "taxpayer_registration_number":"Taxpayer identification number",
        "user":"User",
        "invoice_time":"Invoice time",
        "is_open":"Is it open",
        "creation_time":"creation time",
        "pattern_payment":"Payment method:",
        "all_invoices":"All invoices",
        "to_opened":"To be opened",
        "has_been_open":"Has been opened",
        "operate_successfully":"操作成功！",
    },
    "company":{
        "check_express_company":"Check the express company you want to use, and then save.",
        "contact_general_platform":"If there is no courier company you want to use, please contact the general platform.",
        "check_all":"Select all",
        "preserve":"Save",
        "list_express_companies":"List of express companies",
        "save_successfully":"保存成功",
    },
    "shipping_print":{
        "print_express_order":"打印快递单",
        "print_rule":"选择喷墨或激光打印机，根据下列纸张描述进行，设置并打印发货单据",
        "print":"打印",
    },
    "order_print":{
        "print_express_order":"打印配货单",
        "print_rule":"选择喷墨或激光打印机，根据下列纸张描述进行，设置并打印发货单据",
        "print":"打印",
        "invoice":"送货单",
        "consignee":"收货人",
        "phone":"电话",
        "site":"地址",
        "shop_message":"店铺信息",
        "shop_name":"店铺名称",
        "shop_address":"店铺地址",
        "postcode":"邮编",
        "url":"网 址",
        "line_item":"订单详情",
        "order_date":"下单日期",
        "order_number":"订单编号",
        "pattern_payment":"支付方式",
        "mode_distribution":"配送方式",
        "numerical_order":"序号",
        "product_name":"商品名称",
        "specification_attributes":"规格属性",
        "unit_cost":"单价",
        "actual_unit_price":"实际购买单价",
        "amount":"数量",
        "subtotal":"小计",
        "have_return":"已退货",
        "total":"合计",
    },
    "order_detail":{
        "order_information":"订单信息",
        "platform_orders":"平台订单",
        "consignee":"收货人",
        "phone":"联系方式",
        "address":"收货地址",
        "zipcode":"邮编",
        "pattern_payment":"支付方式",
        "online_payment":"在线支付",
        "invoice_title":"发票抬头",
        "identify_number":"纳税人识别号",
        "buyer_leave_note":"买家留言",
        "order_number":"订单编号",
        "mode_distribution":"配送方式",
        "pick_store":"自提",
        "order_status":"订单状态",
        "submit_order":"提交订单",
        "pay_order":"支付订单",
        "merchant_shipping":"商家发货",
        "confirm_receipt":"确认收货",
        "evaluate":"评价",
        "tuxedo_personnel":"参团人员",
        "colonel":"团长",
        "member":"团员",
        "check_order":"查看订单",
        "basis_order":"本订单",
        "commodity":"商品",
        "unit_price":"单价",
        "quantity":"数量",
        "specification_attributes":"规格属性",
        "actual_unit_price":"实际购买单价",
        "subtotal":"小计",
        "subtract":"减",
        "cost_data":"费用信息",
        "undata_cost_data":"修改费用信息",
        "freight":"运费",
        "integral":"积分",
        "balance_deduction":"余额抵扣",
        "coupon_deduction":"优惠券抵扣",
        "taxes_dues":"税费",
        "tip":"小费",
        "order_preferential":"订单优惠",
        "price_adjustment":"价格调整",
        "handle":"应付",
        "operate":"操作",
        "operation_note":"操作备注",
        "import_operation_note":"请输入操作备注",
        "remark":"备注字不能超过140个汉字",
        "operability":"可执行操作",
        "invalid":"无效",
        "order_refund":"订单退款",
        "affirm":"确认",
        "cancel_confirmation":"取消确认",
        "go_delivery":"去发货",
        "operating_record":"操作记录",
        "handlers":"操作者",
        "operation_time":"操作时间",
        "payment_status":"付款状态",
        "shipping_status":"发货状态",
        "pay_delivery":"货到付款",
        "describe":"描述",
        "remarks":"备注",
        "has_been":"已自提",
        "stay":"待自提",
        "modify_order_price":"修改订单价格",
        "goods_total":"商品总价：",
        "logistics_freight":"物流运费：",
        "logistics_cost":"请直接输入运费金额，原物流费用为：",
        "price_adjustment:":"订单价格微调：",
        "amount_adjustment":"请直接输入要调整的金额, 如果是正数价格上调, 负数价格下调",
        "aggregate":"订单总金额：",
        "usage_balance":"使用余额：",
        "using_integral":"使用积分：",
        "amount_payable":"应付金额：",
        "save":"保 存",
        "refund_operation":"退款操作",
        "refund_way":"退款方式",
        "refund_user_balance":"退款到用户余额",
        "return_original":"原路退回",
        "payment_original_retuen":"支付原路退回",
        "no_payment_original_retuen":"支付原路退回(不支持)",
        "other_ways_refund":"已通过其他方式退款",
        "no_processing":"不处理，误操作时选择此项",
        "refund_amount":"退款金额",
        "refund":"退款说明",
        "cancel":"取 消",
        "ensure":"确 定",
        "operate_successfully":"操作成功",
        "operation_failures":"操作失败,失败原因：",
        "operation_failure":"操作失败",
        "parameter_error":"参数错误",
    },
    "freight_template":{
        "template_name":"模板名称",
        "pricing_manner":"计价方式",
        "number_cases":"件数",
        "weight":"重量",
        "volume":"体积",
        "delivery_deploy":"是否启用默认配送配置",
        "deny":"否",
        "am":"是",
        "shipping_region":"配送地区",
        "china":"全地区",
        "first":"首",
        "freight":"运费",
        "extend":"续",
        "operate":"操作",
        "remove":"删除",
        "add_delivery_deploy":"新增配送配置",
        "submit":"提交",
        "freight_templates":"模板列表",
        "add_freight_template":"新增/编辑运费模板",
        "instance_one":"地区选择冲突，例如:选择勾选北京，同时勾选海淀区",
        "no_empty_template_name":"模板名称不能为空",
        "freight_config_list":"配送区域不能为空",
        "is_enable_default":"配送地区不能为空",
        "region_ids":"地区选择冲突，例如:选择勾选北京，同时勾选海淀区",
        "successfully_added":"添加成功!",
        "fail_add":"添加失败,错误原因:",
        "modify_successfully":"修改成功!",
        "fail_modify":"修改失败,错误原因:",
        "letter":"件(件数)",
        "weight":"重(克)",
        "gram":"体积(立方米/寸)",
    },
    "delivery_info":{
        "delivery_details":"发货详情",
        "print_delivery_order":"打印配货单",
        "order_information":"订单信息",
        "order_number":"订单编号",
        "order_time":"下单时间",
        "delivery_method":"发货方式",
        "fulfillment_expenses":"配送费用",
        "pinkage":"包邮",
        "shipping_order":"配送单号",
        "invoice_title":"发票抬头",
        "delivery_goods_data":"收货信息",
        "consignee":"收货人",
        "electronic_mail":"电子邮件",
        "choose_logistics":"选择物流",
        "location":"地址",
        "phone":"电话",
        "identify_number":"纳税识别号",
        "ship_address":"发货地址",
        "no_address":"没有可选的发货地址，请前去",
        "addition":"添加",
        "goods_data":"商品信息",
        "commodity":"商品",
        "unit_price":"单价",
        "quantity":"数量",
        "specification_attributes":"规格属性",
        "membership_discount":"会员折扣价",
        "select_delivery":"选择发货",
        "shipped":"已发货",
        "applied":"已申请退货",
        "subtotal":"小计",
        "operate":"操作",
        "invoice_note":"发货单备注",
        "please_comments":"请输入操作备注",
        "remark_one":"备注字不能超过140个汉字",
        "executable_operation":"可执行操作",
        "confirm_delivery":"确认发货",
        "amend":"修改",
        "get_back":"返回",
        "delivery_record":"发货记录",
        "handlers":"操作者(商家)",
        "delivery_time":"发货时间",
        "delivery_number":"发货单号",
        "consignee":"收货人",
        "courier_company":"快递公司",
        "remarks":"备注",
        "check_logistics":"查看物流",
        "sheet_number":"手填单号",
        "appointment_shop":"预约到店",
        "electronic_single":"电子面单",
        "logistic":"自己配送",
        "please_delivery_method":"请选择发货方式",
        "please_distribution_logistics":"请选择配送物流",
        "please_ship_address":"请选择发货地址",
        "please_shipping_order":"请输入配送单号",
        "unselected":"未选择发货商品，或订单商品已申请退货",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败",
    },
    "comment_detail":{
        "user_comment":"用户评论",
        "administrator":"管理员",
        "replier":"回复人:被回复人",
        "comment_content":"评论内容",
        "recovery_time":"回复时间",
        "operate":"操作",
        "remove":"删除",
        "response":"请输入回复内容",
        "reply":"回复",
        "comment":"商品评论列表",
        "comment_detail":"回复评论",
        "reply_success":"回复成功!",
    }
}
  
export const zh = {
    "index":{
        "order_list":"订单列表",
        "founds":"拼团列表",
        "teams":"拼团订单",
        "delivery":"发货商品",
        "freight_templates":"运费模版",
        "comment":"商品评论",
        "invoice_list":"发票列表",
        "company":"快递公司",
    },
    "order_list":{
        "consignee":"收货人",
        "order_number":"订单编号",
        "until":"至",
        "date_initial":"日期起始",
        "the_date_as_of":"日期截止",
        "state_payment":"支付状态",
        "shipping_status":"发货状态",
        "pattern_payment":"支付方式",
        "seek":"搜索",
        "derive":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
        "batch_export":"批量导出",
        "export_distribution_list":"导出配货单",
        "check_all":"全选",
        "commodity":"商品",
        "unit_cost":"单价",
        "amount":"数量",
        "buyer":"买家",
        "order_amount":"订单金额",
        "trading_status":"交易状态",
        "abusive_trading_practice":"交易操作",
        "order_time":"下单时间",
        "order_status":"订单状态",
        "order_type":"订单类型",
        "print_delivery_order":"打印配货单",
        "contact_information":"联系信息",
        "name":"姓名",
        "phone":"电话",
        "location":"地址",
        "free_shipping":"免运费",
        "postage":"邮费",
        "pattern_payment":"支付方式",
        "details":"详情",
        "delete":"删除",
        "all_orders":"所有订单",
        "to_confirmed":"待确认",
        "confirmed":"已确认",
        "received":"已收货",
        "canceled":"已取消",
        "completed":"已完成",
        "closed":"已关闭",
        "complete":"全部",
        "non_payment":"未支付",
        "have_paid":"已支付",
        "unshipped":"未发货",
        "shipped":"已发货",
        "part_shipment":"部分发货",
        "use_alipay_pay":"支付宝支付",
        "wechat_pay":"微信支付",
        "please_select":"请选择",
        "there_export":"当前条件没有可导出的数据",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
        "unselected_order":"未选择订单",
    },
    "founds":{
        "until":"至",
        "group_date_begins":"开团日期起始",
        "date_tou_closed":"开团日期截止",
        "group_booking_id":"拼团ID",
        "spell_main_order_number":"拼主订单编号",
        "seek":"搜索",
        "member":"团员",
        "purchase_quantity":"购买数量",
        "order_amount":"订单金额",
        "consignee":"收货人",
        "order_number":"订单编号",
        "trading_status":"交易状态",
        "operate":"操作",
        "commodity":"商品",
        "specification":"规格",
        "conference_time":"开团时间",
        "spelling_single_state":"拼单状态",
        "spell_group_details":"拼团详情",
        "short":"还差",
        "person":"人",
        "canceled ":"已取消",
        "confirmed":"已确认",
        "refund":"退款",
        "has_lottery":"已开奖",
        "wait_lucky_draw":"等待抽奖",
        "spell_main":"拼主",
        "contact_information":"联系信息",
        "name":"姓名",
        "phone":"电话",
        "site":"地址",
        "line_item":"订单详情",
        "member":"团员",
        "all_single":"所有拼单",
        "stay_open_group":"待开团",
        "to_stay_together":"待成团",
        "have_make":"已成团",
        "no_clouds":"未成团",
        "in__latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
    },
    "teams":{
        "consignee":"收货人",
        "order_reference":"订单编号",
        "until":"至",
        "start_order_date":"下单日期起始",
        "order_deadline":"下单日期截止",
        "state_payment":"支付状态",
        "shipping_status":"发货状态",
        "seek":"搜索",
        "export_data":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
        "batch_export":"批量导出",
        "commodity":"商品",
        "order_number":"订单号",
        "spell_group_price":"拼团价",
        "quantity":"数量",
        "spell_main":"拼主",
        "member":"团员",
        "shipping_address":"收货地址",
        "status":"状态",
        "order_time":"下单时间",
        "operation":"操作",
        "line_item":"订单详情",
        "spell_group_details":"拼团详情",
        "in__latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "unshipped":"未发货",
        "delivered":"已发货",
        "part_shipment":"部分发货",
        "non_payment":"未支付",
        "have_paid":"已支付",
        "all_single":"所有拼单",
        "to_confirmed":"待确认",
        "confirmed":"已确认",
        "received":"已收货",
        "canceled":"已取消",
        "completed":"已完成",
        "closed":"已关闭",
        "there_export":"当前条件没有可导出的数据",
    },
    "delivery":{
        "three_state":`发货列表包含"待发货"、"部分发货"、"已发货"三个状态`,
        "print_invoice":"待发货订单可发货, 部分发货订单可继续发货, 已发货订单可打印发货单",
        "tally_order":"已经发货订单, 如果买家没有确认收货, 系统会根据设置结算周期自动结算订单",
        "consignee":"收货人",
        "order_reference":"订单编号",
        "until":"至",
        "start_order_date":"下单日期起始",
        "order_deadline":"下单日期截止",
        "seek":"搜索",
        "check_all":"全选",
        "batch_delivery":"批量发货",
        "print_express_order":"批量打印快递单",
        "print_delivery_order":"批量打印配货单",
        "to_lead":"导入",
        "there_no_records":"暂无符合条件的数据记录",
        "order_reference":"订单编号",
        "order_time":"下单时间",
        "order_blank":"打印配货单",
        "piece":"件",
        "buyer":"买家",
        "phone":"电话",
        "shipping_address":"收货地址",
        "freight":"运费",
        "free_shipping":"免运费",
        "postage":"邮费",
        "go_delivery":"去发货",
        "the_delivery_details":"发货详情",
        "express_waybill":"打印快递单",
        "mode_distribution":"配送方式",
        "ship_address":"发货地址",
        "single_number_assigned":"分配单号",
        "order_reference":"订单编号",
        "quantity_commodity":"商品数量",
        "quantity":"数量",
        "shipping_order":"配送单号",
        "confirm_delivery":"确认发货",
        "in__latest_week":"最近一周",
        "last_month":"最近一个月",
        "last_three_months":"最近三个月",
        "delivered":"已发货",
        "part_shipment":"部分发货",
        "to_send_goods":"待发货",
        "has_return":"已退换货",
        "limited_choice":"当前限制选择 1 个文件，本次选择了",
        "total_choosing":"个文件，共选择了",
        "afile":"个文件",
        "unselected_order":"未选择订单",
        "starting_number":"起始单号不能为空",
        "distribution":"请选择配送方式",
        "please_ship_address":"请选择发货地址",
        "istracking":"快递单号不能为空",
        "successful_delivery":"批量发货成功",
        "delivery_failure":"批量发货失败",
    },
    "freight_templates":{
        "add_freight_template":"点击新增运费模板按钮可以添加运费模板",
        "distribution_area":"配送区域",
        "first":"首",
        "freight":"运费",
        "continue":"续",
        "template_name":"模板名称",
        "filetype":"模板类型",
        "operation":"操作",
        "products":"关联商品",
        "redact":"编辑",
        "edit_region":"新增/编辑地区",
        "remove":"删除",
        "choose_products":"选择商品",
        "check_goods":"点击勾选商品将商品绑定到运费模板中，取消勾选则取消绑定。",
        "determine_configuration":"关闭弹窗时点击确定保存配置。",
        "please_choose":"请选择",
        "unassociated_goods":"未关联商品",
        "associated_goods":"已关联商品",
        "commodity_classification":"商品分类",
        "brand":"品牌",
        "product_name":"商品名称",
        "seek":"搜索",
        "goods_id":"商品ID",
        "product_code":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "serepertoryk":"库存",
        "template_list":"模板列表",
		"bind_check_goods":"绑定勾选商品",
        "add_freight_template":"新增运费模板",
        "number_units":"件数",
        "shop_weight":"商品重量",
        "volume_goods":"商品体积",
        "noremark":"无备注",
        "letter":"件数(件)",
        "weight":"重量(克)",
        "gram":"体积(立方米)",
        "stere":"立方米",
        "China":"全地区",
        "without":"无",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
        "save_deploy":"确认不保存离开?",
        "operate_successfully":"操作成功",
    },
    "comment":{
        "comment_content":"评论内容",
        "user":"用户",
        "seek":"搜索",
        "commodity":"商品",
        "add_time":"评论时间",
        "ip_address":"ip地址",
        "operation":"操作",
        "reply":"回复",
        "product_review_list":"商品评论列表",
    },
    "invoice_list":{
        "until":"至",
        "start_date":"开始日期",
        "date_closed":"结束日期",
        "order_reference":"订单编号",
        "seek":"搜索",
        "invoice_type":"类型",
        "invoice_amount":"金额",
        "invoice_title":"抬头",
        "invoice_content":"内容",
        "tax_invoice":"税率",
        "taxpayer_registration_number":"纳税人识别号",
        "user":"用户",
        "invoice_time":"开票时间",
        "is_open":"是否已开",
        "creation_time":"创建时间",
        "pattern_payment":"支付方式",
        "all_invoices":"所有发票",
        "to_opened":"待开",
        "has_been_open":"已开",
        "operate_successfully":"操作成功！",
    },
    "company":{
        "check_express_company":"勾选要使用的快递公司，然后保存.",
        "contact_general_platform":"没有欲使用的快递公司请联系总平台.",
        "check_all":"全选",
        "preserve":"保存",
        "list_express_companies":"快递公司列表",
        "save_successfully":"保存成功",
    },
    "shipping_print":{
        "print_express_order":"打印快递单",
        "print_rule":"选择喷墨或激光打印机，根据下列纸张描述进行，设置并打印发货单据",
        "print":"打印",
    },
    "order_print":{
        "print_express_order":"打印配货单",
        "print_rule":"选择喷墨或激光打印机，根据下列纸张描述进行，设置并打印发货单据",
        "print":"打印",
        "invoice":"送货单",
        "consignee":"收货人",
        "phone":"电话",
        "site":"地址",
        "shop_message":"店铺信息",
        "shop_name":"店铺名称",
        "shop_address":"店铺地址",
        "postcode":"邮编",
        "url":"网 址",
        "line_item":"订单详情",
        "order_date":"下单日期",
        "order_number":"订单编号",
        "pattern_payment":"支付方式",
        "mode_distribution":"配送方式",
        "numerical_order":"序号",
        "product_name":"商品名称",
        "specification_attributes":"规格属性",
        "unit_cost":"单价",
        "actual_unit_price":"实际购买单价",
        "amount":"数量",
        "subtotal":"小计",
        "have_return":"已退货",
        "total":"合计",
    },
    "order_detail":{
        "order_information":"订单信息",
        "platform_orders":"平台订单",
        "consignee":"收货人",
        "phone":"联系方式",
        "address":"收货地址",
        "zipcode":"邮编",
        "pattern_payment":"支付方式",
        "online_payment":"在线支付",
        "invoice_title":"发票抬头",
        "identify_number":"纳税人识别号",
        "buyer_leave_note":"买家留言",
        "order_number":"订单编号",
        "mode_distribution":"配送方式",
        "pick_store":"自提",
        "order_status":"订单状态",
        "submit_order":"提交订单",
        "pay_order":"支付订单",
        "merchant_shipping":"商家发货",
        "confirm_receipt":"确认收货",
        "evaluate":"评价",
        "tuxedo_personnel":"参团人员",
        "colonel":"团长",
        "member":"团员",
        "check_order":"查看订单",
        "basis_order":"本订单",
        "commodity":"商品",
        "unit_price":"单价",
        "quantity":"数量",
        "specification_attributes":"规格属性",
        "actual_unit_price":"实际购买单价",
        "subtotal":"小计",
        "subtract":"减",
        "cost_data":"费用信息",
        "undata_cost_data":"修改费用信息",
        "freight":"运费",
        "integral":"积分",
        "balance_deduction":"余额抵扣",
        "coupon_deduction":"优惠券抵扣",
        "taxes_dues":"税费",
        "tip":"小费",
        "order_preferential":"订单优惠",
        "price_adjustment":"价格调整",
        "handle":"应付",
        "operate":"操作",
        "operation_note":"操作备注",
        "import_operation_note":"请输入操作备注",
        "remark":"备注字不能超过140个汉字",
        "operability":"可执行操作",
        "invalid":"无效",
        "order_refund":"订单退款",
        "affirm":"确认",
        "cancel_confirmation":"取消确认",
        "go_delivery":"去发货",
        "operating_record":"操作记录",
        "handlers":"操作者",
        "operation_time":"操作时间",
        "payment_status":"付款状态",
        "shipping_status":"发货状态",
        "pay_delivery":"货到付款",
        "describe":"描述",
        "remarks":"备注",
        "has_been":"已自提",
        "stay":"待自提",
        "modify_order_price":"修改订单价格",
        "goods_total":"商品总价：",
        "logistics_freight":"物流运费：",
        "logistics_cost":"请直接输入运费金额，原物流费用为：",
        "price_adjustment:":"订单价格微调：",
        "amount_adjustment":"请直接输入要调整的金额, 如果是正数价格上调, 负数价格下调",
        "aggregate":"订单总金额：",
        "usage_balance":"使用余额：",
        "using_integral":"使用积分：",
        "amount_payable":"应付金额：",
        "save":"保 存",
        "refund_operation":"退款操作",
        "refund_way":"退款方式",
        "refund_user_balance":"退款到用户余额",
        "return_original":"原路退回",
        "payment_original_retuen":"支付原路退回",
        "no_payment_original_retuen":"支付原路退回(不支持)",
        "other_ways_refund":"已通过其他方式退款",
        "no_processing":"不处理，误操作时选择此项",
        "refund_amount":"退款金额",
        "refund":"退款说明",
        "cancel":"取 消",
        "ensure":"确 定",
        "operate_successfully":"操作成功",
        "operation_failures":"操作失败,失败原因：",
        "operation_failure":"操作失败",
        "parameter_error":"参数错误",
    },
    "freight_template":{
        "template_name":"模板名称",
        "pricing_manner":"计价方式",
        "number_cases":"件数",
        "weight":"重量",
        "volume":"体积",
        "delivery_deploy":"是否启用默认配送配置",
        "deny":"否",
        "am":"是",
        "shipping_region":"配送地区",
        "china":"全地区",
        "first":"首",
        "freight":"运费",
        "extend":"续",
        "operate":"操作",
        "remove":"删除",
        "add_delivery_deploy":"新增配送配置",
        "submit":"提交",
        "freight_templates":"模板列表",
        "add_freight_template":"新增/编辑运费模板",
        "instance_one":"地区选择冲突，例如:选择勾选北京，同时勾选海淀区",
        "no_empty_template_name":"模板名称不能为空",
        "freight_config_list":"配送区域不能为空",
        "is_enable_default":"配送地区不能为空",
        "region_ids":"地区选择冲突，例如:选择勾选北京，同时勾选海淀区",
        "successfully_added":"添加成功!",
        "fail_add":"添加失败,错误原因:",
        "modify_successfully":"修改成功!",
        "fail_modify":"修改失败,错误原因:",
        "letter":"件(件数)",
        "weight":"重(克)",
        "gram":"体积(立方米/寸)",
    },
    "delivery_info":{
        "delivery_details":"发货详情",
        "print_delivery_order":"打印配货单",
        "order_information":"订单信息",
        "order_number":"订单编号",
        "order_time":"下单时间",
        "delivery_method":"发货方式",
        "fulfillment_expenses":"配送费用",
        "pinkage":"包邮",
        "shipping_order":"配送单号",
        "invoice_title":"发票抬头",
        "delivery_goods_data":"收货信息",
        "consignee":"收货人",
        "electronic_mail":"电子邮件",
        "choose_logistics":"选择物流",
        "location":"地址",
        "phone":"电话",
        "identify_number":"纳税识别号",
        "ship_address":"发货地址",
        "no_address":"没有可选的发货地址，请前去",
        "addition":"添加",
        "goods_data":"商品信息",
        "commodity":"商品",
        "unit_price":"单价",
        "quantity":"数量",
        "specification_attributes":"规格属性",
        "membership_discount":"会员折扣价",
        "select_delivery":"选择发货",
        "shipped":"已发货",
        "applied":"已申请退货",
        "subtotal":"小计",
        "operate":"操作",
        "invoice_note":"发货单备注",
        "please_comments":"请输入操作备注",
        "remark_one":"备注字不能超过140个汉字",
        "executable_operation":"可执行操作",
        "confirm_delivery":"确认发货",
        "amend":"修改",
        "get_back":"返回",
        "delivery_record":"发货记录",
        "handlers":"操作者(商家)",
        "delivery_time":"发货时间",
        "delivery_number":"发货单号",
        "consignee":"收货人",
        "courier_company":"快递公司",
        "remarks":"备注",
        "check_logistics":"查看物流",
        "sheet_number":"手填单号",
        "appointment_shop":"预约到店",
        "electronic_single":"电子面单",
        "logistic":"自己配送",
        "please_delivery_method":"请选择发货方式",
        "please_distribution_logistics":"请选择配送物流",
        "please_ship_address":"请选择发货地址",
        "please_shipping_order":"请输入配送单号",
        "unselected":"未选择发货商品，或订单商品已申请退货",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败",
    },
    "comment_detail":{
        "user_comment":"用户评论",
        "administrator":"管理员",
        "replier":"回复人:被回复人",
        "comment_content":"评论内容",
        "recovery_time":"回复时间",
        "operate":"操作",
        "remove":"删除",
        "response":"请输入回复内容",
        "reply":"回复",
        "comment":"商品评论列表",
        "comment_detail":"回复评论",
        "reply_success":"回复成功!",
    }
}
  