export const en = {
    "index":{
        "account_list":"帐号列表",
        "account_log":"帐号日志",
    },
    "account_list":{
        "add_admin":"添加管理员",
        "account_name":"账户名",
        "seek":"搜索",
        "account":"账号名",
        "addtime":"加入时间",
        "state":"状态",
        "operate":"操作",
        "compile":"编辑",
        "remove":"删除",
        "addedit_admin":"添加/编辑管理员",
        "user_name":"前台用户名",
        "user_password":"用户密码",
        "login_id":"登录账号",
        "auth_code":"是否激活",
        "are":"是",
        "deny":"否",
        "original_password":"原密码",
        "new_password":"新密码",
        "determine_password":"确定新密码",
        "save":"保存",
        "users_name":"请输入用户名",
        "please_pws":"请输入密码",
        "please_account":"请输入登陆账号",
        "please_new_password":"请输入新密码",
        "again_password":"请再次输入新密码",
        "inconsistent_passwords":"两次输入密码不一致！",
        "save_successfully":"保存成功",
        "fail_modify":"修改失败,错误原因:",
        "fail_add":"添加失败,错误原因:",
        "parameter_error":"参数错误",
    },
    "account_log":{
        "role_name":"角色名称",
        "describe":"描述",
        "operation_time":"操作时间",
        "log_list":"日志列表",
    }
}
  
export const zh = {
    "index":{
        "account_list":"帐号列表",
        "account_log":"帐号日志",
    },
    "account_list":{
        "add_admin":"添加管理员",
        "account_name":"账户名",
        "seek":"搜索",
        "account":"账号名",
        "addtime":"加入时间",
        "state":"状态",
        "operate":"操作",
        "compile":"编辑",
        "remove":"删除",
        "addedit_admin":"添加/编辑管理员",
        "user_name":"前台用户名",
        "user_password":"用户密码",
        "login_id":"登录账号",
        "auth_code":"是否激活",
        "are":"是",
        "deny":"否",
        "original_password":"原密码",
        "new_password":"新密码",
        "determine_password":"确定新密码",
        "save":"保存",
        "users_name":"请输入用户名",
        "please_pws":"请输入密码",
        "please_account":"请输入登陆账号",
        "please_new_password":"请输入新密码",
        "again_password":"请再次输入新密码",
        "inconsistent_passwords":"两次输入密码不一致！",
        "save_successfully":"保存成功",
        "fail_modify":"修改失败,错误原因:",
        "fail_add":"添加失败,错误原因:",
        "parameter_error":"参数错误",
    },
    "account_log":{
        "role_name":"角色名称",
        "describe":"描述",
        "operation_time":"操作时间",
        "log_list":"日志列表",
    }
}
  