export const en = {
    "index":{
        "rush_manage":"Rush management",
        "group_manage":"Group purchase management",
        "goods_sale":"Goods promotion",
        "order_sale":"Order promotion",
        "assemble_manage":"Assemble management",
        "advance_manage":"Advance management",
        "coupon_manage":"Coupon management",
        "combination_manage":"Combination purchase",
    },
    "rush_manage":{
        "add_panic_buying":"Click the add panic buying button to add panic buying activities",
        "according_activity": "Only when recommendations are set on the general platform, the corresponding activities will be displayed in the foreground",
        "according_hour":"The activity time must be an even-numbered hour on the same day (for example: 8:00-10:00), otherwise the activity will not be displayed normally",
        "integral_inactivity": "Each product can only participate in one activity, but integral products cannot participate in activities.",
        "activity_title":"Activity title",
        "seek":"Search",
        "selling":"Activity goods",
        "commodity":"Commodity",
        "headline":"Headline",
        "introduce":"Introduction",
        "not_have":"No",
        "gross":"Total",
        "have_bought":"Bought",
        "for_price":"Buy price",
        "start_time":"Start time",
        "end_time":"End time",
        "snap_state":"Snap state",
        "operation":"Operation",
        "check":"Check",
        "compile":"Edit",
        "remove":"Remove",
        "for_list":"Buying list",
        "new_snap_up":"New snap up",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "group_manage":{
        "add_coupon":"Click on the new group purchase button to add group purchase activities",
        "corresponding_display": "Only when recommendations are set on the general platform, the corresponding activities will be displayed in the foreground",
        "non_participation": "Each product can only participate in one activity, but points products cannot participate in the activity.",
        "name_activity":"Activity name",
        "seek":"Search",
        "group_buying_title":"Group buying title",
        "start_time":"Start time",
        "end_time":"End time",
        "status":"Status",
        "finished":"Finished",
        "operation":"Operation",
        "examine":"View",
        "compile":"Edit",
        "remove":"Remove",
        "group_purchase_list":"Group purchase list",
        "new_group_buying":"New group buying",
        "unable_display":"暂时没有PC商城，无法展示",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "goods_sale":{
        "add_activities":"Click the Add Activity button to add activities",
        "activities_show": "Only when recommendations are set on the general platform, the corresponding activities will be displayed in the foreground",
        "non_participation": "Each product can only participate in one activity, but points products cannot participate in the activity.",
        "name_activity":"Activity name",
        "activity_type":"Activity type",
        "start_time":"Start time",
        "end_time":"End time",
        "state":"State",
        "operation":"Operation",
        "compile":"Edit",
        "remove":"Remove",
        "product_promotion_list":"Product promotion list",
        "post_activity":"New activity",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "order_sale":{
        "add_activities":"Click the Add Activity button to add promotions",
        "name_activity":"Activity name",
        "activity_type":"Activity type",
        "start_time":"Start time",
        "end_time":"End time",
        "state":"State",
        "operation":"Operation",
        "compile":"Edit",
        "remove":"Remove",
        "order_promotion_list":"Order promotion list",
        "post_activity":"New activity",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "assemble_manage":{
        "add_activities":"Click the Add Join Group button to add join group activities",
        "activities_show": "Only when recommendations are set on the general platform, the corresponding activities will be displayed in the foreground",
        "non_participation": "Each product can only participate in one activity, but points products cannot participate in the activity.",
        "attention": "Attention: For group joins that have already been ordered, the design mode was originally not allowed to be modified. If you need to modify it, please carefully modify sensitive information",
        "name_activity":"Activity name",
        "seek":"Search",
        "spell_group_title":"Title",
        "spell_mass_goods":"Goods",
        "spell_group_type":"Type",
        "period_validity":"Period of group validity",
        "the_number_clusters":"The number of clusters",
        "purchase_limit":"Purchase limit number",
        "state":"State",
        "operation":"Operation",
        "lucky_draw":"Lucky draw",
        "has_lottery":"The prize has been drawn",
        "spel_group_list":"Spel group list",
        "add_team":"Add team",
        "share_regiment":"分享团",
        "commission_delegation":"佣金团",
        "lottery_group":"抽奖团",
        "typelessness":"无类型",
        "hour":"小时",
        "under_review":"审核中",
        "underway":"进行中",
        "audit_failure":"审核失败",
        "administrator_shutdown":"管理员关闭",
        "stateless":"无状态",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败,失败原因",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "advance_manage":{
        "add_activities":"Click the add pre-sale button to add pre-sale activities",
        "activity_end": "If the activity has reached the end time and has not ended (the expired and end activity buttons exist at the same time), you need to click the product link to trigger the foreground, or click the end button to end the activity",
        "non_participation": "Each product can only participate in one activity, but points products cannot participate in the activity.",
        "sale":"Pre-sale goods",
        "open_booking_title":"Presale title",
        "open_booking_inventory":"Pre-sale inventory",
        "subscription":"Deposit",
        "present_price":"Present price",
        "number_goods_ordered":"Number of goods ordered",
        "order_quantity":"Order quantity",
        "state":"State",
        "operation":"Operation",
        "activity_time":"Activity time",
        "until":"Until",
        "final_payment_time_payment":"Final payment time",
        "have_started":"Have not started",
        "underway":"In progress",
        "finish":"End",
        "pending":"Pending",
        "successful_end":"Successful_end",
        "failure_end":"Failed to end",
        "activity_success":"Activity success",
        "activity_failure":"Activity failed",
        "ending_activity":"Ending activity",
        "compile":"Edit",
        "remove":"Remove",
        "open_booking_list":"Pre-sale list",
        "add_advance":"Add pre-sale",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "activity_will_end":"结束活动将（修改活动结束时间为当前时间）。该操作不可逆，确定要执行吗?",
        "operate_successfully":"操作成功!",
        "canceled":"已取消",
    },
    "coupon_manage":{
        "vouchers_expire": "After the manual setting of the voucher expires, the user will not be able to receive the voucher, and the voucher already received will not be used",
        "automatic_avoidance": "The voucher template and the issued voucher will automatically expire after expiration",
        "take_out":"Relevant expenses will be deducted from the shop’s billing period.",
        "voucher_name":"Name",
        "agio_type":"Type",
        "order_given":"Order and gift",
        "lssue_user":"Issued by user",
        "balrog":"Free collection",
        "offline_issue":"Offline issue",
        "denomination":"Denomination",
        "full_amount_required":"Full amount required for use",
        "distribution_total":"Distribution total",
        "number_issued":"Number issued",
        "have_been_used":"Used",
        "expiry_date":"Use expiry date",
        "operation":"Operation",
        "grant":"Grant",
        "particular":"Detailed",
        "compile":"Edit",
        "remove":"Remove",
        "choose_products":"Choose products",
        "please_choose":"Please choose",
        "member_nickname":"Member nickname",
        "cellphone_number":"Cellphone Number",
        "seek":"Search",
        "member_id":"Member ID",
        "level_membership":"Membership level",
        "members_nickname":"Member nickname",
        "mobile_phone":"Mobile",
        "mailbox":"Mailbox",
        "sure_send_coupons":"Sure to send coupons",
        "coupon_manage":"Coupon list",
        "add_coupon":"Add coupon",
        "all_members":"All members",
        "shop_focus_member":"Shop focus member",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "selectusers_one":"该优惠券已过了发放时间",
        "selectusers_two":"该优惠券未到发放时间",
        "send_successfully":"发送成功",
    },
    "combination_manage":{
        "add_activities":"Click the Add Activity button to add promotions",
        "package_purchase":"ID",
        "package_name":"Name",
        "quantity_commodity":"Quantity of commodities",
        "start_time":"Start time",
        "end_time":"End time",
        "state":"State",
        "grounding":"On the shelf",
        "operation":"Operation",
        "edit_package":"Edit package",
        "remove":"Remove",
        "package_list":"Package list with purchase",
        "add_combination":"Add matching purchase package",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "need_audit":"该套餐需通过审核才能上架",
        "stale":"已过期，请重新申请",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败:",
    },
    "team":{
        "spell_group_title":"拼团标题",
        "activity_type":"活动类型",
        "share_regiment":"分享团",
        "commission_delegation":"佣金团",
        "lottery_group":"抽奖团",
        "head_commission":"团长佣金",
        "draw_limited":"抽奖限量",
        "period_validity":"成团有效期(小时)",
        "need_group_people":"需要成团人数",
        "purchase_limit":"购买限制数",
        "virtual_sales_base":"虚拟销售基数",
        "share_title":"分享标题",
        "share_desc":"分享描述",
        "spell_mass_goods":"拼团商品",
        "choose_products":"选择商品",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "spell_group_price":"拼团价格",
        "operate":"操作",
        "remove":"删除",
        "submit":"提交",
        "modification":"审核通过后不可变更信息",
        "closed":"已关闭",
        "label":"标签",
        "integral_goods":"积分商品",
        "has_been_involved":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "activity_rules_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_category":"商品分类",
        "brand":"品牌",
        "product_name":"商品名称",
        "seek":"搜索",
        "goods_id":"商品id",
        "numbers":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "assemble_manage":"拼团列表",
        "addedit_templatesfirm":"新增/编辑运费模板",
        "please_template_name":"请输入模板名称",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
        "hour":"小时",
    },
    "coupon":{
        "coupon_name":"优惠券名称",
        "coupon_face_value":"优惠券面额",
        "please_select":"请选择",
        "sum_consumption":"消费金额",
        "czlx":"发放类型",
        "order_given":"下单赠送",
        "specify_issue":"指定发放",
        "balrog":"免费领取",
        "offline_issue":"线下发放",
        "total_number_issued":"可发放总数",
        "date_issue":"发放起始日期",
        "select_date_time":"选择日期时间",
        "ytd":"yyyy 年 MM 月 dd 日",
        "end_date_issue":"发放结束日期",
        "start_date_use":"使用起始日期",
        "end_use_date":"使用结束日期",
        "voucher_description":"代金券描述",
        "serviceable_goods":"可使用商品",
        "entire_store_general":"全店通用",
        "specify_commodity":"指定商品",
        "specify_classification":"指定分类",
        "limit_classification":"限制商品分类使用",
        "specified_list_goods":"指定商品列表",
        "shop_name":"商品名称",
        "price":"价格",
        "repertory":"库存",
        "operate":"操作",
        "remove":"删除",
        "select_goods":"选择商品",
        "commodity_classification":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_id":"商品ID",
        "state":"状态",
        "valid":"有效",
        "lose_efficacy":"失效",
        "affirm_submit":"确认提交",
        "coupon_manage":"返回优惠券列表",
        "coupon_edit":"新增/编辑",
        "onsubmit_one":"使用起始日期不能大于结束日期",
        "onsubmit_tow":"发放起始日期不能大于结束日期",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "onsubmit_three":"指定商品不能为空",
        "onsubmit_four":"指定分类不能为空",
        "onsubmit_five":"请选择使用起始日期",
        "onsubmit_six":"请选择使用结束日期",
        "onsubmit_seven":"请选择发放起始日期",
        "onsubmit_eight":"请选择发放结束日期",
    },
     "coupon_list":{
        "rule_one":"手工设置代金券失效后,用户将不能领取该代金券,已经领取的代金券也将不能使用",
        "rule_two":"代金券模版和已发放的代金券过期后自动失效",
        "rule_three":"相关费用会在店铺的账期结算中扣除。",
        "voucher_name":"代金券名称",
        "czlx":"发放类型",
        "order_given":"下单赠送",
        "issue_user":"按用户发放",
        "balrog":"免费领取",
        "offline_issue":"线下发放",
        "order_number":"订单号",
        "using_membership":"使用会员",
        "hours_use":"使用时间",
        "coupon_details":"发放代金券列表",
    },
    "add_sale":{
        "promotion_activity_name":"促销活动名称",
        "type_promotion":"促销活动类型",
        "please_choose":"请选择",
        "discount":"折扣",
        "break":"折",
        "knock_amount":"立减金额",
        "sale_amount":"出售金额",
        "voucher":"代金券",
        "amount_purchasing":"限购数量",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "publicity_picture":"宣传图片",
        "select_promotional_items":"选择促销商品",
        "select_goods":"选择商品",
        "product_name":"商品名称",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "operate":"操作",
        "remove":"删除",
        "exited_activity":"商品已退出该促销活动",
        "activity_description":"活动描述",
        "whether_notify_user":"是否通知用户",
        "immutable_information":"活动【进行中/已结束/管理员关闭】不可变更信息",
        "present":"提交",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_category":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_id":"商品ID",
        "gooods_code":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "activity_information":"活动信息",
        "inventory_shortage":"库存不足",
        "commodity":"商品",
        "choose_specifications":"选择规格",
        "specification_name":"规格名称",
        "direct_sale":"直接打折",
        "discounts":"减价优惠",
        "fixed_amount_sale":"固定金额出售",
        "get_coupon":"买就赠优惠券",
        "goods_sale":"商品促销列表",
        "add_sale":"新增/编辑活动",
        "please_start_date":"请选择开始日期",
        "please_end_date":"请选择结束日期",
        "less_end_date":"开始日期不能大于结束日期",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    },
    "add_rush":{
        "rush_title":"抢购标题",
        "rush_goods":"抢购商品",
        "set_rushgoods":"设置抢购商品",
        "choose_products":"选择商品",
        "flash_sale_price":"限时抢购价格",
        "number_participants":"参加抢购数量",
        "amount_purchasing":"限购数量",
        "start_time":"开始时间",
        "option_date":"选择日期",
        "selection_time":"选择时间",
        "emphasis":" 重要:抢购时间是从凌晨0:00:00秒开始, 每隔2小时一个节点, 所以活动时间必须是两小时之内才有效,(例如今天是2020年1月1日下午2点场, 那活动开始和结束时间必须是:2020-01-01 14:00:01 ~ 2020-01-01 15:59:59 时间范围内), 否则前端无法显示活动信息",
        "for_introducing":"抢购介绍",
        "submit":"提交",
        "immutable_information":"审核通过后不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "goods_name":"商品名称",
        "seek":"搜索",
        "goods_id":"商品id",
        "goods_sn":"商品货号",
        "shop_price":"价格",
        "market_price":"市场价",
        "store_count":"库存",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "brand":"品牌",
        "rush_manage":"抢购列表",
        "add_rush":"新增/修改抢购",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    },
    "add_order_sale":{
        "promotion_activity_name":"促销活动名称",
        "promotion_activity_type":"促销活动类型",
        "please_choose":"请选择",
        "discount":"折扣",
        "break":"折",
        "knock_amount":"立减金额",
        "voucher":"代金券",
        "amount_satisfied":"需要满足的金额",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "publicity_picture":"宣传图片",
        "activity_description":"活动描述",
        "whether_notify_user":"是否通知用户",
        "submit":"提交",
        "immutable_information":"活动进行中不可变更信息",
        "full_discount":"满额打折",
        "quota_preferential":"满额优惠金额",
        "send_coupons":"满额送优惠券",
        "order_sale":"订单促销列表",
        "add_order_sale":"新增/编辑活动",
        "less_end_time":"活动开始时间不能大于结束时间",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    },
    "add_group":{
        "group_buying_title":"团购标题",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "group_buying_referral":"团购介绍",
        "import_group_buying":"请输入团购介绍",
        "setgroup_buying_goods":"设置团购商品",
        "select_goods":"选择商品",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "purchase_price":"团购价格",
        "total_tuxedo":"参团总量",
        "virtual_count":"虚拟购买数",
        "operate":"操作",
        "remove":"删除",
        "submit":"提交",
        "same_information":"审核通过后不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "brand":"品牌",
        "goods_name":"商品名称",
        "seek":"搜索",
        "goods_id":"商品id",
        "goods_sn":"商品货号",
        "shop_price":"价格",
        "market_price":"市场价",
        "store_count":"库存",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "group_manage":"团购列表",
        "add_group":"新增/修改团购",
        "less_end_time":"活动开始时间不能大于结束时间",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    },
    "add_combination":{
        "package_name":"套餐名称",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "package_description":"套餐描述",
        "combo_shop":"套餐商品",
        "select_goods":"选择商品",
        "goods_id":"商品ID",
        "goods_name":"商品名称",
        "goods_picture":"商品图片",
        "goods_type":"商品类型",
        "host_goods":"主商品",
        "deputy_goods":"副商品",
        "specification":"规格",
        "store_count":"库存",
        "cost_price":"成本价格",
        "shop_price":"商城价格",
        "discounts_price":"优惠价格",
        "operate":"操作",
        "set_main_commodity":"设为主商品",
        "remove":"删除",
        "present":"提交",
        "same_data":"活动已上架不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_sn":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "commodity":"商品",
        "choose_specifications":"选择规格",
        "specification_name":"规格名称",
        "combination_manage":"搭配购套餐列表",
        "add_combination":"添加/编辑搭配购套餐",
        "less_end_time":"起始时间不能大于结束时间",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    },
    "add_advance":{
        "rule_one":"需添加订金后才能对尾款支付的开始和结束时间进行编辑",
        "presell_title":"预售标题",
        "set_presell_goods":"设置预售商品",
        "select_goods":"选择商品",
        "start_time":"活动开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"活动结束时间",
        "subscription":"订金",
        "unit":"单位：",
        "annotation_one":"定金为0时，表示用户直接支付当前的预售价格不需要支付尾款，此时尾款支付时间不可设置;",
        "annotation_two":"预售活动结束后，系统会根据最新的预售价格返还用户已支付的差额;",
        "annotation_three":"商品开始预售后，不能更改订金金额",
        "presell_store":"预售库存",
        "begin_pay":"尾款支付开始时间",
        "end_pay":"尾款支付结束时间",
        "shipment_description":"开始发货时间描述",
        "please_shipment_description":"请输入开始发货时间描述",
        "price_ladder":"价格阶梯",
        "destined_arrive":"预定个数达到",
        "price":"价格",
        "event_description":"活动说明",
        "please_event_description":"请输入活动说明",
        "present":"提交",
        "invariability_data":"审核通过后不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_tow":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_name":"商品名称",
        "goods_id":"商品id",
        "goods_sn":"商品货号",
        "shop_price":"价格",
        "market_price":"市场价",
        "store_count":"库存",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "advance_manage":"预售列表",
        "add_advance":"新增/修改预售",
        "please_template_name":"请输入模板名称",
        "less_end_time":"活动开始时间不能大于结束时间",
        "targeted_number":"预定人数不能为空",
        "tiered_pricing":"阶梯价格必须",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    }
}
  
export const zh = {
    "index":{
        "rush_manage":"抢购管理",
        "group_manage":"团购管理",
        "goods_sale":"商品促销",
        "order_sale":"订单促销",
        "assemble_manage":"拼团管理",
        "advance_manage":"预售管理",
        "coupon_manage":"代金券管理",
        "combination_manage":"搭配购",
    },
    "rush_manage":{
        "add_panic_buying":"点击新增抢购按钮可以添加抢购活动",
        "according_activity":"只有在总平台设置了推荐，对应的活动才会显示在前台",
        "according_hour":"活动时间必须是同一天的双数整点（如：8:00-10:00)，否则活动将不能正常显示",
        "integral_inactivity":"每个商品，只能参与一种活动，但积分商品不能参与活动。",
        "activity_title":"活动标题",
        "seek":"搜索",
        "selling":"活动商品",
        "commodity":"商品",
        "headline":"标题",
        "introduce":"介绍",
        "not_have":"无",
        "gross":"总量",
        "have_bought":"已购买",
        "for_price":"抢购价格",
        "start_time":"开始时间",
        "end_time":"结束时间",
        "snap_state":"抢购状态",
        "operation":"操作",
        "check":"查看",
        "compile":"编辑",
        "remove":"删除",
        "for_list":"抢购列表",
        "new_snap_up":"新增抢购",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "group_manage":{
        "add_coupon":"点击新增团购按钮可以添加团购活动",
        "corresponding_display":"只有在总平台设置了推荐，对应的活动才会显示在前台",
        "non_participation":"每个商品，只能参与一种活动，但积分商品不能参与活动。",
        "name_activity":"活动名称",
        "seek":"搜索",
        "group_buying_title":"团购标题",
        "start_time":"开始时间",
        "end_time":"结束时间",
        "status":"状态",
        "finished":"已结束",
        "operation":"操作",
        "examine":"查看",
        "compile":"编辑",
        "remove":"删除",
        "group_purchase_list":"团购列表",
        "new_group_buying":"新增团购",
        "unable_display":"暂时没有PC商城，无法展示",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "goods_sale":{
        "add_activities":"点击新增活动按钮可以添加活动",
        "activities_show":"只有在总平台设置了推荐，对应的活动才会显示在前台",
        "non_participation":"每个商品，只能参与一种活动，但积分商品不能参与活动。",
        "name_activity":"活动名称",
        "activity_type":"活动类型",
        "start_time":"开始时间",
        "end_time":"结束时间",
        "state":"状态",
        "operation":"操作",
        "compile":"编辑",
        "remove":"删除",
        "product_promotion_list":"商品促销列表",
        "post_activity":"新增活动",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "order_sale":{
        "add_activities":"点击新增活动按钮可以添加促销活动",
        "name_activity":"活动名称",
        "activity_type":"活动类型",
        "start_time":"开始时间",
        "end_time":"结束时间",
        "state":"状态",
        "operation":"操作",
        "compile":"编辑",
        "remove":"删除",
        "order_promotion_list":"订单促销列表",
        "post_activity":"新增活动",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功",
        "undeleted":"已取消删除",
    },
    "assemble_manage":{
        "add_activities":"点击新增拼团按钮可以添加拼团活动",
        "activities_show":"只有在总平台设置了推荐，对应的活动才会显示在前台",
        "non_participation":"每个商品，只能参与一种活动，但积分商品不能参与活动。",
        "attention":"注意：对已经下单的拼团，设计模式原本是不允许修改的，如需修改，请慎重修改敏感信息",
        "name_activity":"活动名称",
        "seek":"搜索",
        "spell_group_title":"拼团标题",
        "spell_mass_goods":"拼团商品",
        "spell_group_type":"拼团类型",
        "period_validity":"成团有效期",
        "the_number_clusters":"成团人数",
        "purchase_limit":"购买限制数",
        "state":"状态",
        "operation":"操作",
        "lucky_draw":"抽奖",
        "has_lottery":"已开奖",
        "spel_group_list":"拼团列表",
        "add_team":"新增拼团",
        "share_regiment":"分享团",
        "commission_delegation":"佣金团",
        "lottery_group":"抽奖团",
        "typelessness":"无类型",
        "hour":"小时",
        "under_review":"审核中",
        "underway":"进行中",
        "audit_failure":"审核失败",
        "administrator_shutdown":"管理员关闭",
        "stateless":"无状态",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败,失败原因",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "advance_manage":{
        "add_activities":"点击新增预售按钮可以添加预售活动",
        "activity_end":"如果活动到了结束时间还未结束(已过期和结束活动按钮同时存在)，需要点击商品链接去前台触发，或者点击结束按钮,将活动结束",
        "non_participation":"每个商品，只能参与一种活动，但积分商品不能参与活动。",
        "sale":"预售商品",
        "open_booking_title":"预售标题",
        "open_booking_inventory":"预售库存",
        "subscription":"订金",
        "present_price":"当前价格",
        "number_goods_ordered":"订购商品数",
        "order_quantity":"订单数",
        "state":"状态",
        "operation":"操作",
        "activity_time":"活动时间",
        "until":"至",
        "final_payment_time_payment":"尾款支付时间",
        "have_started":"未开始",
        "underway":"进行中",
        "finish":"结束",
        "pending":"待处理",
        "successful_end":"成功结束",
        "failure_end":"失败结束",
        "activity_success":"活动成功",
        "activity_failure":"活动失败",
        "ending_activity":"结束活动",
        "compile":"编辑",
        "remove":"删除",
        "open_booking_list":"预售列表",
        "add_advance":"新增预售",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "activity_will_end":"结束活动将（修改活动结束时间为当前时间）。该操作不可逆，确定要执行吗?",
        "operate_successfully":"操作成功!",
        "canceled":"已取消",
    },
    "coupon_manage":{
        "vouchers_expire":"手工设置代金券失效后,用户将不能领取该代金券,已经领取的代金券也将不能使用",
        "automatic_avoidance":"代金券模版和已发放的代金券过期后自动失效",
        "take_out":"相关费用会在店铺的账期结算中扣除。",
        "voucher_name":"名称",
        "agio_type":"类型",
        "order_given":"下单赠送",
        "lssue_user":"按用户发放",
        "balrog":"免费领取",
        "offline_issue":"线下发放",
        "denomination":"面额",
        "full_amount_required":"使用需满金额",
        "distribution_total":"发放总量",
        "number_issued":"已发放数",
        "have_been_used":"已使用",
        "expiry_date":"使用截止日期",
        "operation":"操作",
        "grant":"发放",
        "particular":"详细",
        "compile":"编辑",
        "remove":"删除",
        "choose_products":"选择商品",
        "please_choose":"请选择",
        "member_nickname":"会员昵称",
        "cellphone_number":"手机号码",
        "seek":"搜索",
        "member_id":"会员ID",
        "level_membership":"会员等级",
        "members_nickname":"会员昵称",
        "mobile_phone":"手机",
        "mailbox":"邮箱",
        "sure_send_coupons":"确定发送优惠券",
        "coupon_manage":"代金券列表",
        "add_coupon":"添加代金券",
        "all_members":"所有会员",
        "shop_focus_member":"店铺关注会员",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "selectusers_one":"该优惠券已过了发放时间",
        "selectusers_two":"该优惠券未到发放时间",
        "send_successfully":"发送成功",
    },
    "combination_manage":{
        "add_activities":"点击新增活动按钮可以添加促销活动",
        "package_purchase":"ID",
        "package_name":"名称",
        "quantity_commodity":"商品数量",
        "start_time":"开始时间",
        "end_time":"结束时间",
        "state":"状态",
        "grounding":"上架",
        "operation":"操作",
        "edit_package":"编辑套餐",
        "remove":"删除",
        "package_list":"搭配购套餐列表",
        "add_combination":"添加搭配购套餐",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "need_audit":"该套餐需通过审核才能上架",
        "stale":"已过期，请重新申请",
        "operate_successfully":"操作成功",
        "operation_failure":"操作失败:",
    },
    "team":{
        "spell_group_title":"拼团标题",
        "activity_type":"活动类型",
        "share_regiment":"分享团",
        "commission_delegation":"佣金团",
        "lottery_group":"抽奖团",
        "head_commission":"团长佣金",
        "draw_limited":"抽奖限量",
        "period_validity":"成团有效期(小时)",
        "need_group_people":"需要成团人数",
        "purchase_limit":"购买限制数",
        "virtual_sales_base":"虚拟销售基数",
        "share_title":"分享标题",
        "share_desc":"分享描述",
        "spell_mass_goods":"拼团商品",
        "choose_products":"选择商品",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "spell_group_price":"拼团价格",
        "operate":"操作",
        "remove":"删除",
        "submit":"提交",
        "modification":"审核通过后不可变更信息",
        "closed":"已关闭",
        "label":"标签",
        "integral_goods":"积分商品",
        "has_been_involved":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "activity_rules_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_category":"商品分类",
        "brand":"品牌",
        "product_name":"商品名称",
        "seek":"搜索",
        "goods_id":"商品id",
        "numbers":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "assemble_manage":"拼团列表",
        "addedit_templatesfirm":"新增/编辑运费模板",
        "please_template_name":"请输入模板名称",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
        "hour":"小时",
    },
    "coupon":{
        "coupon_name":"优惠券名称",
        "coupon_face_value":"优惠券面额",
        "please_select":"请选择",
        "sum_consumption":"消费金额",
        "czlx":"发放类型",
        "order_given":"下单赠送",
        "specify_issue":"指定发放",
        "balrog":"免费领取",
        "offline_issue":"线下发放",
        "total_number_issued":"可发放总数",
        "date_issue":"发放起始日期",
        "select_date_time":"选择日期时间",
        "ytd":"yyyy 年 MM 月 dd 日",
        "end_date_issue":"发放结束日期",
        "start_date_use":"使用起始日期",
        "end_use_date":"使用结束日期",
        "voucher_description":"代金券描述",
        "serviceable_goods":"可使用商品",
        "entire_store_general":"全店通用",
        "specify_commodity":"指定商品",
        "specify_classification":"指定分类",
        "limit_classification":"限制商品分类使用",
        "specified_list_goods":"指定商品列表",
        "shop_name":"商品名称",
        "price":"价格",
        "repertory":"库存",
        "operate":"操作",
        "remove":"删除",
        "select_goods":"选择商品",
        "commodity_classification":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_id":"商品ID",
        "state":"状态",
        "valid":"有效",
        "lose_efficacy":"失效",
        "affirm_submit":"确认提交",
        "coupon_manage":"返回优惠券列表",
        "coupon_edit":"新增/编辑",
        "onsubmit_one":"使用起始日期不能大于结束日期",
        "onsubmit_tow":"发放起始日期不能大于结束日期",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "onsubmit_three":"指定商品不能为空",
        "onsubmit_four":"指定分类不能为空",
        "onsubmit_five":"请选择使用起始日期",
        "onsubmit_six":"请选择使用结束日期",
        "onsubmit_seven":"请选择发放起始日期",
        "onsubmit_eight":"请选择发放结束日期",
    },
    "coupon_list":{
        "rule_one":"手工设置代金券失效后,用户将不能领取该代金券,已经领取的代金券也将不能使用",
        "rule_two":"代金券模版和已发放的代金券过期后自动失效",
        "rule_three":"相关费用会在店铺的账期结算中扣除。",
        "voucher_name":"代金券名称",
        "czlx":"发放类型",
        "order_given":"下单赠送",
        "issue_user":"按用户发放",
        "balrog":"免费领取",
        "offline_issue":"线下发放",
        "order_number":"订单号",
        "using_membership":"使用会员",
        "hours_use":"使用时间",
        "coupon_details":"发放代金券列表",
    },
    "add_sale":{
        "promotion_activity_name":"促销活动名称",
        "type_promotion":"促销活动类型",
        "please_choose":"请选择",
        "discount":"折扣",
        "break":"折",
        "knock_amount":"立减金额",
        "sale_amount":"出售金额",
        "voucher":"代金券",
        "amount_purchasing":"限购数量",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "publicity_picture":"宣传图片",
        "select_promotional_items":"选择促销商品",
        "select_goods":"选择商品",
        "product_name":"商品名称",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "operate":"操作",
        "remove":"删除",
        "exited_activity":"商品已退出该促销活动",
        "activity_description":"活动描述",
        "whether_notify_user":"是否通知用户",
        "immutable_information":"活动【进行中/已结束/管理员关闭】不可变更信息",
        "present":"提交",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_category":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_id":"商品ID",
        "gooods_code":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "activity_information":"活动信息",
        "inventory_shortage":"库存不足",
        "commodity":"商品",
        "choose_specifications":"选择规格",
        "specification_name":"规格名称",
        "direct_sale":"直接打折",
        "discounts":"减价优惠",
        "fixed_amount_sale":"固定金额出售",
        "get_coupon":"买就赠优惠券",
        "goods_sale":"商品促销列表",
        "add_sale":"新增/编辑活动",
        "please_start_date":"请选择开始日期",
        "please_end_date":"请选择结束日期",
        "less_end_date":"开始日期不能大于结束日期",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    },
    "add_rush":{
        "rush_title":"抢购标题",
        "rush_goods":"抢购商品",
        "set_rushgoods":"设置抢购商品",
        "choose_products":"选择商品",
        "flash_sale_price":"限时抢购价格",
        "number_participants":"参加抢购数量",
        "amount_purchasing":"限购数量",
        "start_time":"开始时间",
        "option_date":"选择日期",
        "selection_time":"选择时间",
        "emphasis":" 重要:抢购时间是从凌晨0:00:00秒开始, 每隔2小时一个节点, 所以活动时间必须是两小时之内才有效,(例如今天是2020年1月1日下午2点场, 那活动开始和结束时间必须是:2020-01-01 14:00:01 ~ 2020-01-01 15:59:59 时间范围内), 否则前端无法显示活动信息",
        "for_introducing":"抢购介绍",
        "submit":"提交",
        "immutable_information":"审核通过后不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "goods_name":"商品名称",
        "seek":"搜索",
        "goods_id":"商品id",
        "goods_sn":"商品货号",
        "shop_price":"价格",
        "market_price":"市场价",
        "store_count":"库存",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "brand":"品牌",
        "rush_manage":"抢购列表",
        "add_rush":"新增/修改抢购",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    },
    "add_order_sale":{
        "promotion_activity_name":"促销活动名称",
        "promotion_activity_type":"促销活动类型",
        "please_choose":"请选择",
        "discount":"折扣",
        "break":"折",
        "knock_amount":"立减金额",
        "voucher":"代金券",
        "amount_satisfied":"需要满足的金额",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "publicity_picture":"宣传图片",
        "activity_description":"活动描述",
        "whether_notify_user":"是否通知用户",
        "submit":"提交",
        "immutable_information":"活动进行中不可变更信息",
        "full_discount":"满额打折",
        "quota_preferential":"满额优惠金额",
        "send_coupons":"满额送优惠券",
        "order_sale":"订单促销列表",
        "add_order_sale":"新增/编辑活动",
        "less_end_time":"活动开始时间不能大于结束时间",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    },
    "add_group":{
        "group_buying_title":"团购标题",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "group_buying_referral":"团购介绍",
        "import_group_buying":"请输入团购介绍",
        "setgroup_buying_goods":"设置团购商品",
        "select_goods":"选择商品",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "purchase_price":"团购价格",
        "total_tuxedo":"参团总量",
        "virtual_count":"虚拟购买数",
        "operate":"操作",
        "remove":"删除",
        "submit":"提交",
        "same_information":"审核通过后不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "brand":"品牌",
        "goods_name":"商品名称",
        "seek":"搜索",
        "goods_id":"商品id",
        "goods_sn":"商品货号",
        "shop_price":"价格",
        "market_price":"市场价",
        "store_count":"库存",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "group_manage":"团购列表",
        "add_group":"新增/修改团购",
        "less_end_time":"活动开始时间不能大于结束时间",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    },
    "add_combination":{
        "package_name":"套餐名称",
        "start_time":"开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"结束时间",
        "package_description":"套餐描述",
        "combo_shop":"套餐商品",
        "select_goods":"选择商品",
        "goods_id":"商品ID",
        "goods_name":"商品名称",
        "goods_picture":"商品图片",
        "goods_type":"商品类型",
        "host_goods":"主商品",
        "deputy_goods":"副商品",
        "specification":"规格",
        "store_count":"库存",
        "cost_price":"成本价格",
        "shop_price":"商城价格",
        "discounts_price":"优惠价格",
        "operate":"操作",
        "set_main_commodity":"设为主商品",
        "remove":"删除",
        "present":"提交",
        "same_data":"活动已上架不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_one":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_sn":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "commodity":"商品",
        "choose_specifications":"选择规格",
        "specification_name":"规格名称",
        "combination_manage":"搭配购套餐列表",
        "add_combination":"添加/编辑搭配购套餐",
        "less_end_time":"起始时间不能大于结束时间",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    },
    "add_advance":{
        "rule_one":"需添加订金后才能对尾款支付的开始和结束时间进行编辑",
        "presell_title":"预售标题",
        "set_presell_goods":"设置预售商品",
        "select_goods":"选择商品",
        "start_time":"活动开始时间",
        "select_date_time":"选择日期时间",
        "end_time":"活动结束时间",
        "subscription":"订金",
        "unit":"单位：",
        "annotation_one":"定金为0时，表示用户直接支付当前的预售价格不需要支付尾款，此时尾款支付时间不可设置;",
        "annotation_two":"预售活动结束后，系统会根据最新的预售价格返还用户已支付的差额;",
        "annotation_three":"商品开始预售后，不能更改订金金额",
        "presell_store":"预售库存",
        "begin_pay":"尾款支付开始时间",
        "end_pay":"尾款支付结束时间",
        "shipment_description":"开始发货时间描述",
        "please_shipment_description":"请输入开始发货时间描述",
        "price_ladder":"价格阶梯",
        "destined_arrive":"预定个数达到",
        "price":"价格",
        "event_description":"活动说明",
        "please_event_description":"请输入活动说明",
        "present":"提交",
        "invariability_data":"审核通过后不可变更信息",
        "label":"标签",
        "integral_goods":"积分商品",
        "participating_goods":"已参与活动的商品",
        "shop_understock":"商品库存不足",
        "rule_tow":"积分商品、已参与活动商品、商品库存不足时不能参与活动。",
        "goods_classify":"商品分类",
        "brand":"品牌",
        "seek":"搜索",
        "goods_name":"商品名称",
        "goods_id":"商品id",
        "goods_sn":"商品货号",
        "shop_price":"价格",
        "market_price":"市场价",
        "store_count":"库存",
        "activity_information":"活动信息",
        "understock":"库存不足",
        "choose_specifications":"选择规格",
        "confirm":"确 定",
        "advance_manage":"预售列表",
        "add_advance":"新增/修改预售",
        "please_template_name":"请输入模板名称",
        "less_end_time":"活动开始时间不能大于结束时间",
        "targeted_number":"预定人数不能为空",
        "tiered_pricing":"阶梯价格必须",
        "successfully_added":"添加成功!",
        "parameter_error":"参数错误",
        "modify_successfully":"修改成功!",
    }
}
  