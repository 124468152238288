import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {zh as commonZh, en as commonEn} from './common'
import {zh as loginZh, en as loginEn} from './login'
import {zh as componentsZh, en as componentsEn} from './components'
import {zh as homeZh, en as homeEn} from './home'
import {zh as goodsZh, en as goodsEn} from './goods'
import {zh as orderZh, en as orderEn} from './order'
import {zh as promotionZh, en as promotionEn} from './promotion'
import {zh as storeZh, en as storeEn} from './store'
import {zh as after_saleZh, en as after_saleEn} from './after_sale'
import {zh as statisticalZh, en as statisticalEn} from './statistical'
import {zh as customer_serviceZh, en as customer_serviceEn} from './customer_service'
import {zh as accountZh, en as accountEn} from './account'
import {zh as distributionZh, en as distributionEn} from './distribution'
import {zh as doorZh, en as doorEn} from './door'
import {zh as trendZh, en as trendEn} from './trend'
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)

let EN = {
  lang: 'en',
  common: commonEn,
  login: loginEn,
  components: componentsEn,
  home:homeEn,
  goods:goodsEn,
  order:orderEn,
  promotion:promotionEn,
  store:storeEn,
  after_sale:after_saleEn,
  statistical:statisticalEn,
  customer_service:customer_serviceEn,
  account:accountEn,
  distribution:distributionEn,
  door:doorEn,
  ...enLocale,
  trend:trendEn,
}
let ZH = {
  lang: 'zh',
  common: commonZh,
  login: loginZh,
  components: componentsZh,
  home:homeZh,
  goods:goodsZh,
  order:orderZh,
  promotion:promotionZh,
  store:storeZh,
  after_sale:after_saleZh,
  statistical:statisticalZh,
  customer_service:customer_serviceZh,
  account:accountZh,
  distribution:distributionZh,
  door:doorZh,
  ...zhLocale,
  trend:trendZh,
}
const i18n = new VueI18n({
  locale: localStorage.getItem('language')||'zh_CN', 
  messages: {
    en_US: EN,
    zh_CN: ZH
  }
})
ElementLocale.i18n((key,value)=>i18n.t(key,value))

export default i18n