export const en = {
    "index":{
        "store_member":"店铺会员",
        "member_store":"会员店铺",
        "distribut_goods_list":"分销商品",
        "distribut_set":"分销设置",
        "rebate_log":"分成记录",
    },
    "store_member":{
        "members_nickname":"会员昵称",
        "registration_time":"注册时间",
        "store_member":"店铺会员列表",
        "user_id":"会员ID",
        "nickname":"会员昵称",
        "mobile":"手机号",
    },
    "member_store":{
        "nickname":"会员昵称",
        "shop_name":"店铺名称",
        "mobile":"手机号",
        "shop_members":"店铺会员数",
        "turnover":"营业额",
        "registration_time":"注册时间",
        "member_store":"店铺会员列表",
        "user_id":"会员ID",
    },
    "distribut_goods_list":{
        "release_goods":"发布新商品",
        "all_product":"所有分类",
        "all_brand":"所有品牌",
        "goods_name":"商品名称",
        "seek":"搜索",
        "merchant_itemno":"商家货号",
        "store_recommended":"店铺推荐",
        "straw":"荐",
        "new_shop":"店铺新品",
        "nov":"新",
        "store_hotsale":"店铺热卖",
        "hot":"热",
        "price":"价格",
        "inventory":"库存",
        "date_issued":"上架时间",
        "operate":"操作",
        "compile":"编辑",
        "remove":"删除",
        "distribution_goods":"分销商品",
        "isdelete":"确定删除该商品？",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "distribut_set":{
        "distribution_switch":"分销开关",
        "won_distribution":"一级分销比例",
        "distribution_won":"购买者直接推荐人返佣占商品分销金比例,单位：%",
        "tow_distribution":"二级分销比例",
        "distribution_tow":"购买者推荐人的上级返佣占商品分销金比例,单位：%",
        "three_distribution":"三级分销比例",
        "distribution_three":"购买者推荐人的上上级占商品分销金比例,单位：%",
        "commission_series":"返佣级数",
        "returned_level":"返一级",
        "back_two_levels":"返两级",
        "back_three_levels":"返三级",
        "rules_commission":"最多返佣三级，例如设为两级，即消费会员的推荐人，以及推荐人的上级都有提成",
        "validity":"推荐开店有效期",
        "sky":"天",
        "long_time":"长期",
        "validity_rule":"例：几天日内开店成功，才能算是这个人推荐的的店",
        "divided_time":"分成时间",
        "goods_receipt":"订单收货确认",
        "several_days":"天后可以分成,此时间由平台设置.",
        "committed":"确认提交",
        "distribution_rule_setting":"分销规则设置",
        "distribution_rule":"当前由平台设置了默认分销规则",
        "step":"级",
        "distributor_ratio":"三个分销商比例综合不得超过100%",
        "save_successfully":"保存成功",
        "fail_keeping":"保存失败",
    },
    "rebate_log":{
        "state":"状态",
        "user_id":"用户ID",
        "order_number":"订单编号",
        "starting_time":"起始时间",
        "cut_time":"截至时间",
        "seek":"搜索",
        "commission_user_id":"获佣用户ID",
        "nickname":"昵称",
        "mailbox":"邮箱",
        "phone":"手机",
        "without":"无",
        "commissions_paid_amount":"获佣金额",
        "user_level":"获佣用户级别",
        "generated_time":"记录生成时间",
        "operate":"操作",
        "remark":"备注",
        "rebate_log":"分成记录",
        "non_payment":"未付款",
        "account_paid":"已付款",
        "waiting_divided_into":"等待分成",
        "already_divide_into":"已分成",
        "canceled":"已取消",
        "please_comments":"请输入备注",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "please_remarks":"请填写备注！",
        "norevision":"没有修改",
        "modify_successfully":"修改成功",
        "fail_modify":"修改失败",
    }
}

export const zh = {
    "index":{
        "store_member":"店铺会员",
        "member_store":"会员店铺",
        "distribut_goods_list":"分销商品",
        "distribut_set":"分销设置",
        "rebate_log":"分成记录",
    },
    "store_member":{
        "members_nickname":"会员昵称",
        "registration_time":"注册时间",
        "store_member":"店铺会员列表",
        "user_id":"会员ID",
        "nickname":"会员昵称",
        "mobile":"手机号",
    },
    "member_store":{
        "shop_name":"店铺名称",
        "mobile":"手机号",
        "shop_members":"店铺会员数",
        "turnover":"营业额",
        "registration_time":"注册时间",
        "member_store":"店铺会员列表",
        "user_id":"会员ID",
        "nickname":"会员昵称",
    },
    "distribut_goods_list":{
        "release_goods":"发布新商品",
        "all_product":"所有分类",
        "all_brand":"所有品牌",
        "goods_name":"商品名称",
        "seek":"搜索",
        "merchant_itemno":"商家货号",
        "store_recommended":"店铺推荐",
        "straw":"荐",
        "new_shop":"店铺新品",
        "nov":"新",
        "store_hotsale":"店铺热卖",
        "hot":"热",
        "price":"价格",
        "inventory":"库存",
        "date_issued":"上架时间",
        "operate":"操作",
        "compile":"编辑",
        "remove":"删除",
        "distribution_goods":"分销商品",
        "isdelete":"确定删除该商品？",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "distribut_set":{
        "distribution_switch":"分销开关",
        "won_distribution":"一级分销比例",
        "distribution_won":"购买者直接推荐人返佣占商品分销金比例,单位：%",
        "tow_distribution":"二级分销比例",
        "distribution_tow":"购买者推荐人的上级返佣占商品分销金比例,单位：%",
        "three_distribution":"三级分销比例",
        "distribution_three":"购买者推荐人的上上级占商品分销金比例,单位：%",
        "commission_series":"返佣级数",
        "returned_level":"返一级",
        "back_two_levels":"返两级",
        "back_three_levels":"返三级",
        "rules_commission":"最多返佣三级，例如设为两级，即消费会员的推荐人，以及推荐人的上级都有提成",
        "validity":"推荐开店有效期",
        "sky":"天",
        "long_time":"长期",
        "validity_rule":"例：几天日内开店成功，才能算是这个人推荐的的店",
        "divided_time":"分成时间",
        "goods_receipt":"订单收货确认",
        "several_days":"天后可以分成,此时间由平台设置.",
        "committed":"确认提交",
        "distribution_rule_setting":"分销规则设置",
        "distribution_rule":"当前由平台设置了默认分销规则",
        "step":"级",
        "distributor_ratio":"三个分销商比例综合不得超过100%",
        "save_successfully":"保存成功",
        "fail_keeping":"保存失败",
    },
    "rebate_log":{
        "state":"状态",
        "user_id":"用户ID",
        "order_number":"订单编号",
        "starting_time":"起始时间",
        "cut_time":"截至时间",
        "seek":"搜索",
        "commission_user_id":"获佣用户ID",
        "nickname":"昵称",
        "mailbox":"邮箱",
        "phone":"手机",
        "without":"无",
        "commissions_paid_amount":"获佣金额",
        "user_level":"获佣用户级别",
        "generated_time":"记录生成时间",
        "operate":"操作",
        "remark":"备注",
        "rebate_log":"分成记录",
        "non_payment":"未付款",
        "account_paid":"已付款",
        "waiting_divided_into":"等待分成",
        "already_divide_into":"已分成",
        "canceled":"已取消",
        "please_comments":"请输入备注",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "please_remarks":"请填写备注！",
        "norevision":"没有修改",
        "modify_successfully":"修改成功",
        "fail_modify":"修改失败",
    }
}
