export const en = {
    "index":{
        "customer_service_set":"客服设置",
        "store_msg_list":"系统消息",
        "email":"邮件",
    },
    "customer_service_set":{
        "rule_one":"客服信息需要填写完整，不完整信息将不会被保存.",
        "aftsale":"售前客服",
        "add_aftsale":"添加售前客服",
        "service_name":"客服名称",
        "service_tool":"客服工具",
        "service_account ":"客服账号",
        "please_service_account":"请输入客服账号",
        "remove":"移除",
        "after_service":"售后客服",
        "after_sale":"售后",
        "pre_sales":"售前",
        "add_after_service":"添加售后客服",
        "working_time":"工作时间",
        "example":"例",
        "save":"保    存",
        "customer_service_set":"客服设置",
        "ww":"旺旺",
        "qq":"QQ",
        "IM":"站内IM",
        "save_successfully":"保存成功",
    },
     "store_msg_list":{
        "rule_one":"管理员可以看见全部消息。",
        "rule_two":"只有管理员可以删除消息，删除后其他账户的该条消息也将被删除。",
        "delet_batches":"批量删除",
        "mark_read":"标记已读",
        "messageblob":"消息内容",
        "time_departure":"发送时间",
        "state":"状态",
        "operation":"操作",
        "have_read":"已阅",
        "remove":"删除",
        "store_msg_list":"系统消息",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "operating_data":"请选择需要操作的记录!",
    },
    "email":{
        "subject_mail":"邮件标题",
        "member":"会员",
        "commodity":"商品",
        "select_goods":"选择商品",
        "product_name":"商品名称",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "operate":"操作",
        "remove":"删除",
        "email_describing":"邮件描述",
        "submit_send":"提交发送",
        "select_user":"选择用户",
        "level_membership":"会员等级",
        "members_nickname":"会员昵称",
        "mailbox":"邮箱",
        "seek":"搜索",
        "choose":"确认选择",
        "member_id":"会员id",
        "select_brand":"选择品牌",
        "please_select":"请选择",
        "goods_id":"商品id",
        "goods_name":"商品名称",
        "goods_code":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "repertory":"库存",
        "email":"邮件通知",
        "emaillog":"邮件日志",
        "unselected_user":"没有选中用户",
        "noemail":"选中的用户没有邮箱!",
        "choose_membership":"选择会员",
        "manipulate":"请选择需要操作的记录!",
        "astrict":"选中的商品不可以超过5条!",
        "no_empty_mail_title":"邮件标题不能为空!",
        "selected_membership":"没有选择会员!",
        "email_varchar":"选择会员邮箱不能为空!",
        "membership":"发送的会员数过多!",
        "no_empty_select_goods":"选择商品不能为空!",
        "content_sent":"发送的内容不能为空!",
        "send_successfully":"发送成功!",
    },
    "emailreceiver":{
        "subject_mail":"邮件标题：",
        "time_departure":"发送时间：",
        "sender":"发送人：",
        "mail_content":"邮件内容：",
        "recipient":"接收人",
        "receive_email":"接收邮箱",
        "accepting_state":"接收状态",
        "succeed":"成功",
    },
    "emaillog":{
        "start_date":"开始日期",
        "date_closed":"结束日期",
        "seek":"搜索",
        "application_scenarios":"应用场景",
        "surface_mail":"普通邮件",
        "inform":"通知",
        "auth_code":"验证码",
        "time_departure":"发送时间",
        "headline":"标题",
        "sender":"发送人",
        "operate":"操作",
        "view_details":"查看详情",
        "email":"邮件通知",
        "emaillog":"邮件日志",
    }
}
  
export const zh = {
    "index":{
        "customer_service_set":"客服设置",
        "store_msg_list":"系统消息",
        "email":"邮件",
    },
    "customer_service_set":{
        "rule_one":"客服信息需要填写完整，不完整信息将不会被保存.",
        "aftsale":"售前客服",
        "add_aftsale":"添加售前客服",
        "service_name":"客服名称",
        "service_tool":"客服工具",
        "service_account ":"客服账号",
        "please_service_account":"请输入客服账号",
        "remove":"移除",
        "after_service":"售后客服",
        "after_sale":"售后",
        "pre_sales":"售前",
        "add_after_service":"添加售后客服",
        "working_time":"工作时间",
        "example":"例",
        "save":"保    存",
        "customer_service_set":"客服设置",
        "ww":"旺旺",
        "qq":"QQ",
        "IM":"站内IM",
        "save_successfully":"保存成功",
    },
    "store_msg_list":{
        "rule_one":"管理员可以看见全部消息。",
        "rule_two":"只有管理员可以删除消息，删除后其他账户的该条消息也将被删除。",
        "delet_batches":"批量删除",
        "mark_read":"标记已读",
        "messageblob":"消息内容",
        "time_departure":"发送时间",
        "state":"状态",
        "operation":"操作",
        "have_read":"已阅",
        "remove":"删除",
        "store_msg_list":"系统消息",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
        "operating_data":"请选择需要操作的记录!",
    },
    "email":{
        "subject_mail":"邮件标题",
        "member":"会员",
        "commodity":"商品",
        "select_goods":"选择商品",
        "product_name":"商品名称",
        "specification":"规格",
        "inventory":"库存",
        "mall_price":"商城价格",
        "operate":"操作",
        "remove":"删除",
        "email_describing":"邮件描述",
        "submit_send":"提交发送",
        "select_user":"选择用户",
        "level_membership":"会员等级",
        "members_nickname":"会员昵称",
        "mailbox":"邮箱",
        "seek":"搜索",
        "choose":"确认选择",
        "member_id":"会员id",
        "select_brand":"选择品牌",
        "please_select":"请选择",
        "goods_id":"商品id",
        "goods_name":"商品名称",
        "goods_code":"商品货号",
        "price":"价格",
        "market_price":"市场价",
        "repertory":"库存",
        "email":"邮件通知",
        "emaillog":"邮件日志",
        "unselected_user":"没有选中用户",
        "noemail":"选中的用户没有邮箱!",
        "choose_membership":"选择会员",
        "manipulate":"请选择需要操作的记录!",
        "astrict":"选中的商品不可以超过5条!",
        "no_empty_mail_title":"邮件标题不能为空!",
        "selected_membership":"没有选择会员!",
        "email_varchar":"选择会员邮箱不能为空!",
        "membership":"发送的会员数过多!",
        "no_empty_select_goods":"选择商品不能为空!",
        "content_sent":"发送的内容不能为空!",
        "send_successfully":"发送成功!",

    },
    "emailreceiver":{
        "subject_mail":"邮件标题：",
        "time_departure":"发送时间：",
        "sender":"发送人：",
        "mail_content":"邮件内容：",
        "recipient":"接收人",
        "receive_email":"接收邮箱",
        "accepting_state":"接收状态",
        "succeed":"成功",
    },
    "emaillog":{
        "start_date":"开始日期",
        "date_closed":"结束日期",
        "seek":"搜索",
        "application_scenarios":"应用场景",
        "surface_mail":"普通邮件",
        "inform":"通知",
        "auth_code":"验证码",
        "time_departure":"发送时间",
        "headline":"标题",
        "sender":"发送人",
        "operate":"操作",
        "view_details":"查看详情",
        "email":"邮件通知",
        "emaillog":"邮件日志",
    }
}
  