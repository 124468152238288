export const en = {
  "title": "Business management center",
  "note": "Please enter the name of the business you applied for when you register the store. The login password is the general password of the front desk member of the mall.",
  "username": "username",
  "password": "password",
  "verification": "verification code",
  "login": "Sign in"
  
}

export const zh = {
  "title": "商家管理中心",
  "note": "请输入您注册店铺时申请的商家名称，登录密码为商城前台会员通用密码。",
  "username": "用户名",
  "password": "密码",
  "verification": "验证码",
  "login": "登 录"
}
