/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import Cookies from 'js-cookie'
import Config from '@/config'

const TokenKey = Config.TokenKey
const Seller = Config.Seller

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, {
      expires: Config.tokenCookieExpires
    })
  } else return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getSeller() {
	if(Cookies.get(TokenKey)){
		return JSON.parse(Cookies.get(Seller))
	}
	return null
}

export function setSeller(seller) {
  return Cookies.set(Seller, seller)
}

export function removeSeller() {
  return Cookies.remove(Seller)
}