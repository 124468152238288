export const en = {
    "index": {
        "shop":"门店",
        "door_set":"门店管理",
    }
  }
  
  export const zh = {
    "index": {
        "shop":"门店",
        "door_set":"门店管理",
    }
  }
  