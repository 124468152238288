/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
/* eslint-disable no-console */
import axios from 'axios'
import qs from 'qs'
import router from '@/router/router.js'
// import { Notification, MessageBox } from 'element-ui'
//import store from '@/store/store.js'
import Vue from 'vue'
import {
	getToken,removeToken
} from '@/utils/auth'
import Config from '@/config'
import i18n from '@/i18n/index.js'

console.log(process.env)
if(process.env.NODE_ENV === 'development'){
	axios.defaults.baseURL = process.env.VUE_APP_URL
	console.log('使用的是vue.config.js代理地址')
}else{
	axios.defaults.baseURL = process.env.VUE_APP_URL
}
Vue.prototype.apiHead = axios.defaults.baseURL //用于图片上传，注意开发环境和生产环境的区别

// 创建axios实例
const service = axios.create({
	timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
	config => {
		if (getToken()) {
			config.headers['Authorization'] = 'Bearer' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
		}
		if (config.method === 'post') {
			config.headers['Content-Type'] = "application/json"
		}
		if (config.method === 'put') {
			config.headers['Content-Type'] = "application/json"
		}
		config.headers['lang'] = i18n.locale
		return config
	},
	error => {
		// Do something with request error
		console.log(error) //
		Promise.reject(error)
	}
)

// response 拦截器
service.interceptors.response.use(
	response => {
		if(response.data.status == -10000){
			removeToken()
			router.push({
				name: "login",
			})
		}
		return response.data
	},
	error => {
		if (error.response) {

			switch (error.response.status) {
				case 401:
					router.push({
						name: "login",
					})
			}
		}
		return Promise.reject(error)
	}
)
export default service