export const en = {
    "index":{
        "store_set":"Store Settings",
        "store_nav_list":"Store Navigation",
        "bind_class_list":"Business category",
        "store_info":"Store Information",
        "store_reopen":"Apply for renewal",
        "store_class_list":"store category",
        "store_collect":"Store Follow",
        "guarantee_item":"Consumer Guarantee Service",
        "address_manage":"Address Management",
        "news_list":"News List",
    },
    "store_set":{
        "store_set":{
            "shop_class":"shop class",
            "shop_name":"shop name",
            "main_products":"Main Products",
            "search_box":`Keywords can be entered up to 50 characters, please use "," to separate, such as "men's clothing, women's clothing, children's clothing"`,
            "shop_logo":"shop logo",
            "store_page_logo":"This is the store page logo",
            "transparent_image": `It is recommended to use a GIF or PNG transparent image with a width of 200 pixels-a height of 60 pixels; it will take effect after clicking the "Submit" button below. `,
            "store_banner":"store banner",
            "navigation_banner":"This is the shop page banner navigation;",
            "limit_pictures": `It is recommended to use pictures with a width of 1200 pixels * a height of 130 pixels; it will take effect after clicking the "Submit" button below. `,
            "store_image":"store image",
            "square_logo":"Please upload a picture that is the same as the store logo; used to display a square LOGO",
            "small_limit_picture": `It is recommended to use a square picture with a width of 100 pixels * a height of 100 pixels; it will take effect after clicking the "Submit" button below. `,
            "second_Level":"second-level store domain name",
            "restricted_string":"Please enter a string of 3~50 characters, for example, enter'aa', the main domain name of the mall is xxx.com, then you can visit your store through aa.xxx.com",
            "then_enable": "Enabled: This shop can be accessed through the second-level domain name",
            "not_enable": "Not enabled: please contact the platform administrator to enable",
            "service_qq":"Customer Service QQ",
            "taobao":"Ali Wangwang",
            "postcode":"Postcode",
            "store_phone":"store phone",
            "number_format":"Phone number format, for example: 0755-86140485 400-12345678",
            "service_call":"Service Call",
            "reminder_text":"Important: Service call, when the customer places an order, a reminder message will be sent to the merchant's mobile phone, for example: 13800138006",
            "inventory_warning":"Inventory Warning",
            "alarm": "If the inventory is less than the warning number, the alarm will be prompted",
            "site":"Address",
            "detailed_address":"detailed address",
            "package_mail_quota":"Free shipping quota",
            "free_shipping_full":"Free shipping on full amount",
            "seo_keyword":"SEO keyword",
            "english_comma_separation": "For store search engine optimization, please separate keywords with English commas",
            "seo_store_description":"SEO store description",
            "the_word":"For shop search engine optimization, it is recommended to be within 120 words",
            "submit":"Submit",
            "store_set":"Store Settings",
            "slide_set":"Slide Set",
            "temp":"Shop Theme",
            "mobile_slide_set":"Mobile shop setting",
            "get_point": "Pick up shop coordinates",
            "wrong_number_format":"店铺电话号码格式错误",
            "fill_phone":"请填写店铺电话",
            "service_error":"服务电话号码格式错误",
            "service_call":"请填写服务电话",
            "parameter_error":"参数错误",
            "save_successfully":"保存成功",
            "fail_keeping":"保存失败",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "temp":{
            "store_template_name":"店铺模版名称", 
            "shop_name":"店铺名称", 
            "shop_home":"店铺首页", 
            "available_themes":"可用主题", 
            "skinname":"模版名称", 
            "employ":"使用", 
            "preview":"预览", 
            "store_set":"店铺设置", 
            "slide_set":"幻灯片设置", 
            "temp":"店铺主题", 
            "mobile_slide_set":"手机店铺设置", 
            "get_point":"拾取店铺坐标", 
            "successfully_set":"设置成功", 
        },
        "slide_set":{
            "rule_one":"最多可上传5张幻灯片图片。",
            "rule_two":"支持jpg、jpeg、gif、png格式上传，建议图片宽度940px、高度在300px到440px之间、大小1.00M以内的图片。提交2~5张图片可以进行幻灯片播放，一张图片没有幻灯片播放效果。",
            "rule_three":"操作完成以后，按“提交”按钮，可以在当前页面进行幻灯片展示。",
            "rule_four":"跳转链接必须带有",
            "rule_four_concent":"http://",
            "skip_url":"跳转URL...",
            "affirm_submit":"确认提交",
            "operate_successfully":"操作成功！",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "mobile_slide_set":{
            "rule_one":"最多可上传5张幻灯片图片。",
            "rule_two":"支持jpg、jpeg、gif、png格式上传，建议图片宽度940px、高度在300px到440px之间、大小1.00M以内的图片。提交2~5张图片可以进行幻灯片播放，一张图片没有幻灯片播放效果。",
            "rule_three":"操作完成以后，按“提交”按钮，可以在当前页面进行幻灯片展示。",
            "rule_four":"跳转链接必须带有",
            "rule_four_concent":"http://",
            "skip_url":"跳转URL...",
            "affirm_submit":"确认提交",
            "operate_successfully":"操作成功！",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "get_point":{
            "rule_one":"可以在此处对店铺坐标设置，修改后的设置需要点击“保存修改”按钮进行保存",
            "rule_two":"在坐标拾取系统获取店铺坐标,并分别复制到“经纬度”输入框里面",
            "rule_three":"输入坐标时经度与纬度之间用‘英文状态’下的逗号“ , ”隔开，如：116.431865,39.918477",
            "rule_four":"拾取坐标时尽可能准确，以方便用户能准确找到您的店铺位置",
            "store_fabric":"店铺经纬度：",
            "coordinate_copy":"将拾取坐标复制到这里",
            "search_content":"请输入你想要搜索的内容",
            "save_changes":"保存修改",
            "operate_successfully":"操作成功！",
        }
    },
    "store_nav":{
        "store_nav_list":{
            "sort":"sort",
            "navigation_name":"Navigation Name",
            "show":"Display",
            "open_new_window":"Open a new window",
            "operate":"operation",
            "compile":"Edit",
            "remove":"Remove",
            "store_nav_list":"Navigation List",
            "store_nav_add":"Add/Edit Navigation",
            "operate_succeed":"操作成功！",
            "confirm_delete":"确认删除?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "delete_succeed":"删除成功!",
            "undeleted":"已取消删除",
        },
        "store_nav":{
            "navigation_name":"导航名称",
            "visible":"是否显示：",
            "sort":"排序：",
            "maximum":"排序最大值为255",
            "content":"内容：",
            "navigation_url_address":"导航URL地址：",
            "jump_link":"请填写包含http://的完整URL地址,如果填写此项则点击该导航会跳转到该链接",
            "open_new_window":"新窗口打开：",
            "affirm_submit":"确认提交",
            "save_successfully":"保存成功",
            "parameter_error":"参数错误",
            "successfully_added":"添加成功",
        }
    },
    "bind_class_list":{
        "apply_business_category":"Apply for a new business category",
        "business_category":"business category",
        "commission_rate":"commission rate",
        "state":"State",
        "operate":"operation",
        "remove":"Remove",
        "submit":"Submit",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "delete_succeed":"删除成功!",
        "undeleted":"已取消删除",
        "selection_sort":"请选择分类",
        "commodity_classification":"商品分类",
        "application_approved":"申请成功",
    },
    "store_info":{
        "contact_information":"Company and Contact Information",
        "company_name":"Company Name",
        "location_company":"Company Location",
        "address_company":"Company detailed address",
        "company_tel":"Company Phone",
        "postal_code":"Postal Code",
        "fax":"Fax",
        "contact_name":"Contact Name",
        "contact_number":"Contact Number",
        "email_address":"Email address",
        "copy_license":"Business license information (copy)",
        "business_license_number":"Business License Number",
        "license-term":"Validity Period of Business License",
        "long_time":"long-term",
        "business_scope":"legal business scope",
        "registered_assets":"registered capital",
        "myriad":"myriad",
        "nstitution_code":"Organization Code",
        "legal_person_name":"Legal representative name",
        "tax_type_code":"tax type code",
        "settlement_account":"settlement account information",
        "bank_name":"Bank Account Name",
        "bank_account":"Company Bank Account",
        "bank_branch_name":"Name of the bank branch where the account is opened",
        "branch_connection":"branch connection number",
        "location_bank":"The location of the bank that opened the account",
        "tax_enrol_certificate":"Tax Registration Certificate",
        "register_number":"Tax Registration Certificate Number",
        "generally_prove":"General Taxpayer Certificate",
        "id_information": "Certificate Information",
        "licence_duplicate":"Duplicate of business license",
        "copy_certificate":"Copy of Tax Registration Certificate",
        "copy_institution": "Copy of Institution Code Certificate",
        "corporate_identity_card":"Corporate Identity Card",
        "storekeeper_card":"Storekeeper ID card",
        "store_business_data":"store business information",
        "seller_account":"Seller Account",
        "shop_name":"shop name",
        "shop_class":"shop class",
        "shop_classify":"shop classify",
        "business_category":"business category",
        "classify":"Classification",
        "ratio":"ratio",
        "all_categories":"All categories have been bound",
        "audit_opinion":"audit opinion",
        "store_info":"Store Information",
    },
    "store_reopen":{
        "apply_renewal":"Apply for renewal",
        "store_due":"Before the store expires",
        "within_apply_renewal":"Days can apply for store renewal",
        "sign_up":"Your shop has been signed up to",
        "oneself":"oneself",
        "thirtyithin_apply_renewal": "You can apply for renewal within 30 days",
        "time_application":"application time",
        "rates":"Charge standard",
        "renew_length":"Renewal length (years)",
        "payment_amount":"payment amount",
        "period_validity":"Validity period for renewal",
        "state":"State",
        "operate":"operation",
        "examine":"View",
        "current_store_level:":"Current store level:",
        "application_level:":"application level:",
        "cost":"cost",
        "continue_time":"Renewal time:",
        "handle_total":"Total amount payable:",
        "pay_order":"Payment Certificate:",
        "format_supported":"Support jpg, gif, png format",
        "submit":"Submit",
        "application_details":"Application Details",
        "payment_instructions":"payment instructions:",
        "review_note":"review note:",
        "audit_status":"audit status:",
        "year":"year",
        "parameter_error":"参数错误",
        "upload_documents":"请上传支付凭证",
        "application_approved":"申请成功",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    },
    "store_class":{
        "store_class_list":{
            "add_classification":"Add a new classification",
            "systematic_name":"Category Name",
            "sort":"sort",
            "show":"Display",
            "operate":"operation",
            "set_advertising":"Set the advertising",
            "add_junior":"Add a subordinate",
            "compile":"Edit",
            "remove":"Remove",
            "category_parent":"Parent Category",
            "display_status":"Display Status",
            "navigation_display":"Whether the navigation is displayed",
            "home_recommend":"Whether the home page is recommended",
            "submit":"Submit",
            "store_class_list":"store category",
            "isdelete":"是否删除?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "successfully_delete":"删除成功!",
            "undeleted":"已取消删除",
            "compile_classification":"编辑分类",
            "unable_set":"无法设置自身为上级分类",
            "save_successfully":"保存成功",
            "parameter_error":"参数错误",
            "successfully_added":"添加成功",
        },
        "setADs":{
            "poster_details":"海报详情",
            "poster_name":"海报名称",
            "poster_link":"海报链接",
            "start_date":"开始日期",
            "select_date_time":"选择日期时间",
            "date_closed":"结束日期",
            "poster_picture":"海报图片",
            "affirm_submit":"确认提交",
            "limited_choice":"当前限制选择 1 个文件，本次选择了",
            "total_choosing":"个文件，共选择了",
            "afile":"个文件",
            "please_poster_name":"请填写海报名称！",
            "please_start_date":"请选择开始时间！",
            "please_end_time":"请选择结束时间！",
            "please_upload_pictures":"请上传图片！",
            "reenter":"请重新进入该页面！",
            "submit_successfully":"提交成功!",
        }
    },
    "store_collect":{
        "event_notice":"Event Notice",
        "member_name":"Member Name",
        "seek":"Search",
        "member_id":"Member ID",
        "attention_time":"Attention time",
        "operate":"operation",
        "message":"Site Message",
        "send_scope":"Send scope:",
        "following_list":"Following list",
        "membership":"All members",
        "member_list":"Member List:",
        "send_title":"Send title:",
        "tip_text": "If the title is not filled, the default is the prompt text in the input box",
        "content":"Send content:",
        "data_translation": "If the content is not filled, the default is the text prompted in the input box, and the placeholder will be replaced by the corresponding data",
        "send":"send",
        "store_collect":"Store Follow",
        "send_successfully":"发送成功！",
    },
    "address":{
        "address_manage":{
            "address":"Click the Add Address button to add an address",
            "two_address":"There are two types of addresses, shipping address and shipping address.",
            "site":"Address",
            "linkman":"Contact",
            "phone":"Phone",
            "form":"Type",
            "default":"default",
            "operate":"operation",
            "redact":"Edit",
            "remove":"Remove",
            "address_manage":"Address List",
            "address_add":"Add/Edit Address",
            "isdelete":"是否删除该信息?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "successfully_delete":"删除成功!",
            "undeleted":"已取消删除",
        },
        "address":{
            "site":"地址：",
            "detailed_address":"详细地址",
            "linkman":"联系人：",
            "mobile_phone":"手机：",
            "postal_code":"邮政编码：",
            "address_type":"地址类型：",
            "shipments":"发货",
            "receiving":"收货",
            "is_default":"是否默认：",
            "are":"是",
            "deny":"否",
            "confirm":"确 定",
            "address_manage":"地址列表",
            "address_add":"添加/编辑地址",
            "save_successfully":"保存成功",
            "parameter_error":"参数错误",
            "successfully_added":"添加成功",
        }
    },
    "news":{
        "news_list":{
            "addnews": "Click the Add News button to add news",
            "news_category":"News Category",
            "news_title":"News Title",
            "seek":"Search",
            "title":"title",
            "category":"Article Category",
            "show":"Display",
            "release_time":"release time",
            "press_mark":"News Mark",
            "audit_status":"audit status",
            "operate":"operation",
            "redact":"Edit",
            "remove":"Remove",
            "news_list":"News List",
            "news_add":"Add/Edit News",
            "update_successfully":"更新成功",
            "confirm_delete":"确认删除?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "delete_succeed":"删除成功!",
            "undeleted":"已取消删除",
        },
        "news":{
            "title":"标题",
            "their_classify":"所属分类",
            "arise_location":`当选择发布"商城公告"时，还需要设置下面的"出现位置"项`,
            "journalism_label":"新闻标签",
            "seo_keyword":"seo关键字",
            "search_engine_friendly":"用于seo 搜索引擎友好",
            "link":"链接",
            "linking_format":`当填写"链接"后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头`,
            "release_time":"发布时间",
            "select_date_time":"选择日期时间",
            "webpage_describe":"网页描述",
            "news_map_one":"新闻导图一",
            "suggest_using":"上传宣传图片；支持jpg、gif、png格式上传，建议使用",
            "square_picture":"尺寸800x800像素以上、大小不超过1M的正方形图片。",
            "news_map_two":"新闻导图二",
            "news_map_three":"新闻导图三",
            "presentation_style":"导图展现样式",
            "not_showing_pictures":"不显示图片",
            "left_picture":"左边图片",
            "right_picture":"右边图片",
            "more_above_picture":"上边多图片",
            "more_below_picture":"下边多图片",
            "annotation":"注：左右显示图片只显示导图一，上下多图片显示为3张比例",
            "show":"显示",
            "audit":"审核：",
            "verified":"审核通过",
            "refuse_pass":"拒绝通过",
            "contents_article":"文章内容",
            "affirm":"确认提交",
            "newest":"最新",
            "hot":"热门",
            "recommend":"推荐",
            "boutique":"精品",
            "operate_successfully":"操作成功",
            "parameter_error":"参数错误",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        }
    },
    "supplier":{
        "add_supplier":"新增供货商",
        "supplier_details":"供货商信息可与商品关联，商品发布/编辑时可选择供货商，商品列表支持跟据供货商快速查找。",
        "supplier_name":"供货商名称",
        "seek":"搜索",
        "supplier":"供货商",
        "linkman":"联系人",
        "phone":"联系电话",
        "remark":"备注",
        "operate":"操作",
        "compile":"编辑",
        "remove":"删除",
        "postscript":"备注信息",
        "cancel":"取 消",
        "confirm":"确 定",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "guarantee":{
        "guarantee_item":{
            "wait_review":"Waiting for review",
            "verified":"verified",
            "click_pay_margin":"click to pay margin",
            "unapprove":"Approval failed",
            "activity":"Click to view details",
            "margin_reviewed":"Margin pending review",
            "exit_udit":"Exit under review",
            "exit_failure":"Exit failed",
            "failure_margin_review":"Margin review failed",
            "resubmit":"Resubmit",
            "join":"join",
            "application_progress":"application in progress",
            "has_joined":"has joined",
            "view_service_details":"View service details",
            "quit":"Quit",
            "margin_payment":"Payment Margin",
            "project_name":"Project name:",
            "required_margin:":"Required Margin:",
            "time_application":"Application time:",
            "already_uploaded":"Payment vouchers have been uploaded:",
            "last_picture":"The above picture is the payment voucher uploaded when the application was submitted last time",
            "bill_credit":"Payment Voucher:",
            "clear_image": "It is recommended to upload a picture with clear text information to facilitate the review of the information",
            "submit":"Submit",
            "guarantee_item":"Consumer Guarantee Service",
            "confirm_operation":"确认操作?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "application_approved":"申请成功！",
            "uploading_bill_credit":"请上传付款凭证",
            "submit_successfully":"提交成功！",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "guarantee_info":{
            "project_name":"项目名称：",
            "required_margin:":"所需保证金：",
            "state":"状态：",
            "safeguard_service":"保障服务日志",
            "operator":"操作人",
            "operation_time":"操作时间",
            "operation_description":"操作描述",
            "guarantee_item":"消费者保障服务",
            "guarantee_info":"保障服务详情",
            "notapply":"未申请",
        }
    },
}
  
export const zh = {
    "index":{
        "store_set":"店铺设置",
        "store_nav_list":"店铺导航",
        "bind_class_list":"经营类目",
        "store_info":"店铺信息",
        "store_reopen":"申请续签",
        "store_class_list":"店铺分类",
        "store_collect":"店铺关注",
        "guarantee_item":"消费者保障服务",
        "address_manage":"地址管理",
        "news_list":"新闻列表",
    },
    "store_set":{
        "store_set":{
            "shop_class":"店铺等级",
            "shop_name":"店铺名称",
            "main_products":"主营商品",
            "search_box":`关键字最多可输入50字，请用","进行分隔，例如”男装,女装,童装”`,
            "shop_logo":"店铺logo",
            "store_page_logo":"此处为店铺页logo",
            "transparent_image":`建议使用宽200像素-高60像素内的GIF或PNG透明图片；点击下方"提交"按钮后生效。`,
            "store_banner":"店铺条幅",
            "navigation_banner":"此处为店铺页banner导航；",
            "limit_pictures":`建议使用宽1200像素*高130像素的图片；点击下方"提交"按钮后生效。`,
            "store_image":"店铺头像",
            "square_logo":"请上传店铺logo一样的图片；用于需要显示正方形LOGO",
            "small_limit_picture":`建议使用宽100像素*高100像素内的方型图片；点击下方"提交"按钮后生效。`,
            "second_Level":"店铺二级域名",
            "restricted_string":"请输入3~50位的字符串, 例如输入'aa',商城主域名是xxx.com,那么可以通过aa.xxx.com访问你的店铺",
            "then_enable":"已启用:可通过二级域名访问此店铺",
            "not_enable":"未启用:请联系平台管理员开启",
            "service_qq":"客服QQ",
            "taobao":"阿里旺旺",
            "postcode":"邮编",
            "store_phone":"店铺电话",
            "number_format":"电话号码格式, 例如: 0755-86140485 400-12345678",
            "service_call":"服务电话",
            "reminder_text":"重要: 服务电话, 客户下单时发送提醒短信到到商家手机, 例如: 13800138006",
            "inventory_warning":"库存预警",
            "alarm":"库存少于预警数则报警提示",
            "site":"地址",
            "detailed_address":"详细地址",
            "package_mail_quota":"包邮额度",
            "free_shipping_full":"满多少免运费",
            "seo_keyword":"SEO关键字",
            "english_comma_separation":"用于店铺搜索引擎的优化，关键字之间请用英文逗号分隔",
            "seo_store_description":"SEO店铺描述",
            "the_word":"用于店铺搜索引擎的优化，建议120字以内",
            "submit":"提 交",
            "store_set":"店铺设置",
            "slide_set":"幻灯片设置",
            "temp":"店铺主题",
            "mobile_slide_set":"手机店铺设置",
            "get_point":"拾取店铺坐标",
            "wrong_number_format":"店铺电话号码格式错误",
            "fill_phone":"请填写店铺电话",
            "service_error":"服务电话号码格式错误",
            "service_call":"请填写服务电话",
            "parameter_error":"参数错误",
            "save_successfully":"保存成功",
            "fail_keeping":"保存失败",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "temp":{
            "store_template_name":"店铺模版名称", 
            "shop_name":"店铺名称", 
            "shop_home":"店铺首页", 
            "available_themes":"可用主题", 
            "skinname":"模版名称", 
            "employ":"使用", 
            "preview":"预览", 
            "store_set":"店铺设置", 
            "slide_set":"幻灯片设置", 
            "temp":"店铺主题", 
            "mobile_slide_set":"手机店铺设置", 
            "get_point":"拾取店铺坐标", 
            "successfully_set":"设置成功", 
        },
        "slide_set":{
            "rule_one":"最多可上传5张幻灯片图片。",
            "rule_two":"支持jpg、jpeg、gif、png格式上传，建议图片宽度940px、高度在300px到440px之间、大小1.00M以内的图片。提交2~5张图片可以进行幻灯片播放，一张图片没有幻灯片播放效果。",
            "rule_three":"操作完成以后，按“提交”按钮，可以在当前页面进行幻灯片展示。",
            "rule_four":"跳转链接必须带有",
            "rule_four_concent":"http://",
            "skip_url":"跳转URL...",
            "affirm_submit":"确认提交",
            "operate_successfully":"操作成功！",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "mobile_slide_set":{
            "rule_one":"最多可上传5张幻灯片图片。",
            "rule_two":"支持jpg、jpeg、gif、png格式上传，建议图片宽度940px、高度在300px到440px之间、大小1.00M以内的图片。提交2~5张图片可以进行幻灯片播放，一张图片没有幻灯片播放效果。",
            "rule_three":"操作完成以后，按“提交”按钮，可以在当前页面进行幻灯片展示。",
            "rule_four":"跳转链接必须带有",
            "rule_four_concent":"http://",
            "skip_url":"跳转URL...",
            "affirm_submit":"确认提交",
            "operate_successfully":"操作成功！",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "get_point":{
            "rule_one":"可以在此处对店铺坐标设置，修改后的设置需要点击“保存修改”按钮进行保存",
            "rule_two":"在坐标拾取系统获取店铺坐标,并分别复制到“经纬度”输入框里面",
            "rule_three":"输入坐标时经度与纬度之间用‘英文状态’下的逗号“ , ”隔开，如：116.431865,39.918477",
            "rule_four":"拾取坐标时尽可能准确，以方便用户能准确找到您的店铺位置",
            "store_fabric":"店铺经纬度：",
            "coordinate_copy":"将拾取坐标复制到这里",
            "search_content":"请输入你想要搜索的内容",
            "save_changes":"保存修改",
            "operate_successfully":"操作成功！",
        }
    },
    "store_nav":{
        "store_nav_list":{
            "sort":"排序",
            "navigation_name":"导航名称",
            "show":"显示",
            "open_new_window":"新窗口打开",
            "operate":"操作",
            "compile":"编辑",
            "remove":"删除",
            "store_nav_list":"导航列表",
            "store_nav_add":"添加/编辑导航",
            "operate_succeed":"操作成功！",
            "confirm_delete":"确认删除?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "delete_succeed":"删除成功!",
            "undeleted":"已取消删除",
        },
        "store_nav":{
            "navigation_name":"导航名称",
            "visible":"是否显示：",
            "sort":"排序：",
            "maximum":"排序最大值为255",
            "content":"内容：",
            "navigation_url_address":"导航URL地址：",
            "jump_link":"请填写包含http://的完整URL地址,如果填写此项则点击该导航会跳转到该链接",
            "open_new_window":"新窗口打开：",
            "affirm_submit":"确认提交",
            "save_successfully":"保存成功",
            "parameter_error":"参数错误",
            "successfully_added":"添加成功",
        }
    },
    "bind_class_list":{
        "apply_business_category":"申请新的经营类目",
        "business_category":"经营类目",
        "commission_rate":"分佣比例",
        "state":"状态",
        "operate":"操作",
        "remove":"删除",
        "submit":"提 交",
        "confirm_delete":"确认删除?",
        "hint":"提示",
        "confirm":"确定",
        "cancel":"取消",
        "delete_succeed":"删除成功!",
        "undeleted":"已取消删除",
        "selection_sort":"请选择分类",
        "commodity_classification":"商品分类",
        "application_approved":"申请成功",
    },
    "store_info":{
        "contact_information":"公司及联系人信息",
        "company_name":"公司名称",
        "location_company":"公司所在地",
        "address_company":"公司详细地址",
        "company_tel":"公司电话",
        "postal_code":"邮政编码",
        "fax":"传真",
        "contact_name":"联系人姓名",
        "contact_number":"联系人电话",
        "email_address":"电子邮箱",
        "copy_license":"营业执照信息（副本）",
        "business_license_number":"营业执照号",
        "license-term":"营业执照有效期",
        "long_time":"长期",
        "business_scope":"法定经营范围",
        "registered_assets":"注册资本",
        "myriad":"万",
        "nstitution_code":"组织机构代码",
        "legal_person_name":"法人代表姓名",
        "tax_type_code":"纳税类型码",
        "settlement_account":"结算账号信息",
        "bank_name":"银行开户名",
        "bank_account":"公司银行账号",
        "bank_branch_name":"开户银行支行名称",
        "branch_connection":"支行联行号",
        "location_bank":"开户银行所在地",
        "tax_enrol_certificate":"税务登记证",
        "register_number":"税务登记证号",
        "generally_prove":"一般纳税人证明",
        "id_information":"证件信息",
        "licence_duplicate":"企业营业执照副本",
        "copy_certificate":"税务登记证复印件",
        "copy_institution":"机构代码证复印件",
        "corporate_identity_card":"其它附件",
        "storekeeper_card":"店铺负责人身份证",
        "store_business_data":"店铺经营信息",
        "seller_account":"卖家账号",
        "shop_name":"店铺名称",
        "shop_class":"店铺等级",
        "shop_classify":"店铺分类",
        "business_category":"经营类目",
        "classify":"分类",
        "ratio":"比例",
        "all_categories":"已绑定全部类目",
        "audit_opinion":"审核意见",
        "store_info":"店铺信息",
    },
    "store_reopen":{
        "apply_renewal":"申请续签", 
        "store_due":"店铺到期前", 
        "within_apply_renewal":"天可以申请店铺续签", 
        "sign_up":"您的店铺已签约至", 
        "oneself":"自", 
        "thirtyithin_apply_renewal":"起的30天内可以申请续签", 
        "time_application":"申请时间", 
        "rates":"收费标准", 
        "renew_length":"续签时长（年）", 
        "payment_amount":"付款金额", 
        "period_validity":"续签起止有效期", 
        "state":"状态", 
        "operate":"操作", 
        "examine":"查看", 
        "current_store_level:":"当前店铺等级：", 
        "application_level:":"申请等级：",
        "cost":"费用",
        "continue_time":"续费时长：",
        "handle_total":"应付总额:",
        "pay_order":"支付凭证：",
        "format_supported":"支持jpg、gif、png格式",
        "submit":"提 交",
        "application_details":"申请详情",
        "payment_instructions":"支付说明：",
        "review_note":"审核备注：",
        "audit_status":"审核状态：",
        "year":"年",
        "parameter_error":"参数错误",
        "upload_documents":"请上传支付凭证",
        "application_approved":"申请成功",
        "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
        "picture_size":"上传图片大小不能超过 2MB!",
    },
    "store_class":{
        "store_class_list":{
            "add_classification":"新增分类",
            "systematic_name":"分类名称",
            "sort":"排序",
            "show":"显示",
            "operate":"操作",
            "set_advertising":"设置广告",
            "add_junior":"新增下级",
            "compile":"编辑",
            "remove":"删除",
            "category_parent":"上级分类",
            "display_status":"显示状态",
            "navigation_display":"是否导航显示",
            "home_recommend":"是否首页推荐",
            "submit":"提 交", 
            "store_class_list":"店铺分类", 
            "isdelete":"是否删除?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "successfully_delete":"删除成功!",
            "undeleted":"已取消删除",
            "compile_classification":"编辑分类",
            "unable_set":"无法设置自身为上级分类",
            "save_successfully":"保存成功",
            "parameter_error":"参数错误",
            "successfully_added":"添加成功",
        },
        "setADs":{
            "poster_details":"海报详情",
            "poster_name":"海报名称",
            "poster_link":"海报链接",
            "start_date":"开始日期",
            "select_date_time":"选择日期时间",
            "date_closed":"结束日期",
            "poster_picture":"海报图片",
            "affirm_submit":"确认提交",
            "limited_choice":"当前限制选择 1 个文件，本次选择了",
            "total_choosing":"个文件，共选择了",
            "afile":"个文件",
            "please_poster_name":"请填写海报名称！",
            "please_start_date":"请选择开始时间！",
            "please_end_time":"请选择结束时间！",
            "please_upload_pictures":"请上传图片！",
            "reenter":"请重新进入该页面！",
            "submit_successfully":"提交成功!",
        }
    },
    "store_collect":{
        "event_notice":"活动通知",
        "member_name":"会员名称",
        "seek":"搜索",
        "member_id":"会员ID",
        "attention_time":"关注时间",
        "operate":"操作",
        "message":"站内信",
        "send_scope":"发送范围：",
        "following_list":"以下列表",
        "membership":"全体会员",
        "member_list":"会员列表：",
        "send_title":"发送标题：",
        "tip_text":"发送标题不填默认为输入框中提示的文字",
        "content":"发送内容：",
        "data_translation":"发送内容不填默认为输入框中提示的文字，占位符会被相应数据替换",
        "send":"发 送",
        "store_collect":"店铺关注",
        "send_successfully":"发送成功！",
    },
    "address":{
        "address_manage":{
            "address":"点击新增地址按钮可以添加地址",
            "two_address":"地址分为两种，收货地址与发货地址.",
            "site":"地址",
            "linkman":"联系人",
            "phone":"电话",
            "form":"类型",
            "default":"默认",
            "operate":"操作",
            "redact":"编辑",
            "remove":"删除",
            "address_manage":"地址列表",
            "address_add":"添加/编辑地址",
            "isdelete":"是否删除该信息?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "successfully_delete":"删除成功!",
            "undeleted":"已取消删除",
        },
        "address":{
            "site":"地址：",
            "detailed_address":"详细地址",
            "linkman":"联系人：",
            "mobile_phone":"手机：",
            "postal_code":"邮政编码：",
            "address_type":"地址类型：",
            "shipments":"发货",
            "receiving":"收货",
            "is_default":"是否默认：",
            "are":"是",
            "deny":"否",
            "confirm":"确 定",
            "address_manage":"地址列表",
            "address_add":"添加/编辑地址",
            "save_successfully":"保存成功",
            "parameter_error":"参数错误",
            "successfully_added":"添加成功",
        }
    },
    "news":{
        "news_list":{
            "addnews":"点击新增新闻按钮可以新增新闻",
            "news_category":"新闻类别",
            "news_title":"新闻标题",
            "seek":"搜索",
            "title":"标题",
            "category":"文章分类",
            "show":"显示",
            "release_time":"发布时间",
            "press_mark":"新闻标记",
            "audit_status":"审核状态",
            "operate":"操作",
            "redact":"编辑",
            "remove":"删除",
            "news_list":"新闻列表",
            "news_add":"添加/编辑新闻",
            "update_successfully":"更新成功",
            "confirm_delete":"确认删除?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "delete_succeed":"删除成功!",
            "undeleted":"已取消删除",
        },
        "news":{
            "title":"标题",
            "their_classify":"所属分类",
            "arise_location":`当选择发布"商城公告"时，还需要设置下面的"出现位置"项`,
            "journalism_label":"新闻标签",
            "seo_keyword":"seo关键字",
            "search_engine_friendly":"用于seo 搜索引擎友好",
            "link":"链接",
            "linking_format":`当填写"链接"后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头`,
            "release_time":"发布时间",
            "select_date_time":"选择日期时间",
            "webpage_describe":"网页描述",
            "news_map_one":"新闻导图一",
            "suggest_using":"上传宣传图片；支持jpg、gif、png格式上传，建议使用",
            "square_picture":"尺寸800x800像素以上、大小不超过1M的正方形图片。",
            "news_map_two":"新闻导图二",
            "news_map_three":"新闻导图三",
            "presentation_style":"导图展现样式",
            "not_showing_pictures":"不显示图片",
            "left_picture":"左边图片",
            "right_picture":"右边图片",
            "more_above_picture":"上边多图片",
            "more_below_picture":"下边多图片",
            "annotation":"注：左右显示图片只显示导图一，上下多图片显示为3张比例",
            "show":"显示",
            "audit":"审核：",
            "verified":"审核通过",
            "refuse_pass":"拒绝通过",
            "contents_article":"文章内容",
            "affirm":"确认提交",
            "newest":"最新",
            "hot":"热门",
            "recommend":"推荐",
            "boutique":"精品",
            "operate_successfully":"操作成功",
            "parameter_error":"参数错误",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        }
    },
    "supplier":{
        "add_supplier":"新增供货商",
        "supplier_details":"供货商信息可与商品关联，商品发布/编辑时可选择供货商，商品列表支持跟据供货商快速查找。",
        "supplier_name":"供货商名称",
        "seek":"搜索",
        "supplier":"供货商",
        "linkman":"联系人",
        "phone":"联系电话",
        "remark":"备注",
        "operate":"操作",
        "compile":"编辑",
        "remove":"删除",
        "postscript":"备注信息",
        "cancel":"取 消",
        "confirm":"确 定",
        "save_successfully":"保存成功",
        "parameter_error":"参数错误",
        "isdelete":"是否删除该信息?",
        "hint":"提示",
        "successfully_delete":"删除成功!",
        "undeleted":"已取消删除",
    },
    "guarantee":{
        "guarantee_item":{
            "wait_review":"等待审核",
            "verified":"审核通过",
            "click_pay_margin":"点击支付保证金",
            "unapprove":"审核未通过",
            "activity":"点击查看详情",
            "margin_reviewed":"保证金待审核",
            "exit_udit":"退出审核中",
            "exit_failure":"退出失败",
            "failure_margin_review":"保证金审核失败",
            "resubmit":"重新提交",
            "join":"加入",
            "application_progress":"申请进行中",
            "has_joined":"已加入",
            "view_service_details":"查看服务详情",
            "quit":"退出",
            "margin_payment":"支付保证金",
            "project_name":"项目名称：",
            "required_margin:":"所需保证金：",
            "time_application":"申请时间：",
            "already_uploaded":"已上传付款凭证：",
            "last_picture":"以上图片为上次提交申请时上传的付款凭证",
            "bill_credit":"付款凭证：",
            "clear_image":"建议上传文字信息清晰的图片，便于审核时查看信息",
            "submit":"提 交",
            "guarantee_item":"消费者保障服务",
            "confirm_operation":"确认操作?",
            "hint":"提示",
            "confirm":"确定",
            "cancel":"取消",
            "application_approved":"申请成功！",
            "uploading_bill_credit":"请上传付款凭证",
            "submit_successfully":"提交成功！",
            "picture_format":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
            "picture_size":"上传图片大小不能超过 2MB!",
        },
        "guarantee_info":{
            "project_name":"项目名称：",
            "required_margin:":"所需保证金：",
            "state":"状态：",
            "safeguard_service":"保障服务日志",
            "operator":"操作人",
            "operation_time":"操作时间",
            "operation_description":"操作描述",
            "guarantee_item":"消费者保障服务",
            "guarantee_info":"保障服务详情",
            "notapply":"未申请",
        }
    }
}
  