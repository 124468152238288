/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import request from '@/utils/request'

/**
 * 会员签到配置
 */
export async function updateSignConfig(form) {
	return request({
		url: '/mall/configs/sign',
		method: 'put',
		data: form
	})
}

/**
 * 获取验证码
 */
export async function getVerifyImage(param) {
	return request({
		url: '/auth/verification',
		method: 'get',
		params: param
	})
}

/**
 * 退出
 */
export async function logout(form) {
	return request({
		url: '/seller/logout',
		method: 'post',
		data: form
	})
}

/**
 * seller修改密码
 */
export async function updatePassword(form) { 
	return request({
		url: '/user/password',
		method: 'put',
		data: form
	})
}

/** 
 * 发送站内信
 */
export async function sendNoticeMsg(form) {
	return request({
		url: '/user/message/send_notice_msg',
		method: 'post',
		data: form
	});
}



/** 
 * 发送私信
 */
export async function sendMsgPrivate(form) {
	return request({
		url: '/user/message/send_private_msg',
		method: 'post',
		data: form
	});
}

/** 
 * 获取用户
 */
export async function getUserById(id) {
	return request({
		url: '/user/user',
		method: 'get',
		params: {
			user_id: id
		}
	});
}
/** 
 * 获取消息模板
 */
export async function getMemberMsgTpl(params) {
	return request({
		url: '/user/member_msg_tpl',
		method: 'get',
		params: params
	});
}

/**
 * 修改商家新手向导显示
 */
export async function updateOpenTeach(param) {
	return request({
		url: '/seller/open_teach?open_teach=' + param,
		method: 'put'
	})
}

/**
 * 获取商家基础信息
 */
export async function getBaseInfo() {
	return request({
		url: '/seller/current',
		method: 'get',
	})
}

/**
 * 获取商家基础信息
 */
export async function getStore(params) {
	return request({
		url: '/seller/store',
		method: 'get',
		params: params
	})
}

/**
 * 获取店铺等级
 */
export async function getStoreGrade(params) {
	return request({
		url: '/seller/store_grade',
		method: 'get',
		params: params
	})
}

/**
 * 获取配置
 */
export async function getConfig() {
	return request({
		url: '/mall/config',
		method: 'get'
	})
}

export async function getConfigs(param) {
	return request({
		url: '/mall/configs',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品统计报告
 */
export async function getGoodsReport(param) {
	return request({
		url: '/mall/goods/report',
		method: 'get',
		params: param
	})
}

/**
 * 获取咨询统计报告
 */
export async function getConsultReport(param) {
	return request({
		url: '/mall/consult/report',
		method: 'get',
		params: param
	})
}

/**
 * 获取订单统计报告
 */
export async function getOrderReport(param) {
	return request({
		url: '/order/report',
		method: 'get',
		params: param
	})
}
/**
 * 获取退还货记录
 */
export async function getReturnGoods(param) {
	return request({
		url: '/order/return_goods/seller',
		method: 'get',
		params: param
	})
}
/**
 * 获取退还货统计报告
 */
export async function getReturnGoodsReport(param) {
	return request({
		url: '/order/return_goods/report',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺幻灯片设置
 */
export async function getStoreSlide() {
	return request({
		url: '/seller/store/slide',
		method: 'get',
	})
}

/**
 * 更新店铺幻灯片设置
 */
export async function updateStoreSlide(form) {
	return request({
		url: '/seller/store/slide',
		method: 'put',
		data: form
	})
}

/**
 * 获取店铺幻灯片设置
 */
export async function getStoreMobileSlide() {
	return request({
		url: '/seller/store/mobile_slide',
		method: 'get',
	})
}

/**
 * 更新店铺幻灯片设置
 */
export async function updateStoreMobileSlide(form) {
	return request({
		url: '/seller/store/mobile_slide',
		method: 'put',
		data: form
	})
}

/**
 * 获取店铺经纬度
 */
export async function getStorePoint(form) {
	return request({
		url: '/seller/store/point',
		method: 'get',
		data: form
	})
}

/**
 * 更新店铺经纬度
 */
export async function updateStorePoint(point) {
	return request({
		url: 'seller/store/point?point=' + point,
		method: 'put',
	})
}

/**
 * 获取下单的销售排行分页
 */
export async function getStockLogOrderGoodsPage(param) {
	return request({
		url: '/mall/stock_log/order_goods/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品评论列表
 */
export async function getCommentPage(param) {
	return request({
		url: '/order/comment/page/admin',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品评论
 */
export async function getComment(param) {
	return request({
		url: '/order/comment/' + param,
		method: 'get'
	})
}

/**
 * 获取商品评论列表
 */
export async function getCommentList(param) {
	return request({
		url: '/order/comment/record',
		method: 'get',
		params: param
	})
}

/**
 * 获取评论列表
 */
export async function getReplyPage(param) {
	return request({
		url: '/order/reply/page',
		method: 'get',
		params: param
	})
}

/** 
 * 添加评论
 */
export async function addCommentAdmin(param1, param2) {
	return request({
		url: '/order/comment/reply?comment_id=' + param1 + "&content=" + param2,
		method: 'post'
	});
}

/**
 * 获取商品库存列表
 */
export async function getStockPage(param) {
	return request({
		url: '/mall/stock/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改库存
 */
export async function updateStock(form) {
	return request({
		url: '/mall/stock',
		method: 'put',
		data: form
	})
}

/**
 * 获取商品分类下拉列表
 */
export async function getGoodsCategoryTree(param) {
	return request({
		url: '/mall/goods/category/tree',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺商品分类下拉列表
 */
export async function getStoreGoodsCategoryTree(param) {
	return request({
		url: '/seller/store_bind_class/goods_category',
		method: 'get',
		params: param
	})
}

/**
 * 获取本店铺商品分类下拉列表
 */
export async function getISellerGoodsType(param) {
	return request({
		url: '/seller/store_bind_class/list',
		method: 'get',
		params: param
	})
}
/**
 * 获取商品
 */
export async function getGoods(param) {
	return request({
		url: '/mall/goods/' + param,
		method: 'get'
	})
}

/**
 * 获取商品相册
 */
export async function getGoodsImages(param) {
	return request({
		url: '/mall/goods/image/list',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品属性
 */
export async function getGoodsAttributes(param) {
	return request({
		url: '/mall/goods/category/attributes',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品属性
 */
export async function getGoodsAttrs(param) {
	return request({
		url: '/mall/goods_attr/list',
		method: 'get',
		params: param
	})
}

/**
 * 修改商品
 */
export async function updateGoods(form) {
	return request({
		url: '/mall/goods',
		method: 'put',
		data: form
	})
}

/**
 * 修改商品-商品编辑页
 */
export async function updateGoodsAll(param) {
	return request({
		url: '/mall/goods/all',
		method: 'put',
		data: param
	})
}

/**
 * 添加商品
 */
export async function addGoods(form) {
	return request({
		url: '/mall/goods',
		method: 'post',
		data: form
	})
}

/**
 * 根据商品模型id获取商品规格项
 */
export async function getSpecAndItem(param) {
	return request({
		url: '/mall/spec/store_spec_item',
		method: 'get',
		params: param
	})
}

/**
 * 根据商品模型id获取商品规格项
 */
export async function getGoodsSpecAndItem(param) {
	return request({
		url: '/mall/spec/goods_spec_item',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品的商品分类
 */
export async function getGoodsCatList(param) {
	return request({
		url: '/mall/goods/category/list',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品的品牌
 */
// export async function getBandList(param) {
// 	return request({
// 		url: '/mall/brand/list',
// 		method: 'get',
// 		params: param
// 	})
// }

export async function getBandList(param) {
	return request({
		url: '/mall/brand/seller_list',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品的规格组合
 */
export async function getSpecGoodsPriceList(param) {
	return request({
		url: '/mall/spec/spec_goods_price/list',
		method: 'get',
		params: param
	})
}

/**
 * 添加规格
 */
export async function addSpecItem(form) {
	return request({
		url: '/mall/spec/item',
		method: 'post',
		data: form
	})
}

/**
 * 删除规格
 */
export async function deleteSpecItem(param) {
	return request({
		url: '/mall/spec/item',
		method: 'delete',
		params: param
	})
}

/**
 * 获取运费模板列表
 */
export async function getFreightTemplate() {
	return request({
		url: '/mall/freight_template/list',
		method: 'get'
	})
}

/**
 * 获取供应商列表
 */
export async function getSuppliers() {
	return request({
		url: '/seller/suppliers/list',
		method: 'get'
	})
}

/**
 * 获取品牌
 */
export async function getBrand(param) {
	return request({
		url: '/mall/brand',
		method: 'get',
		params: param
	})
}

/**
 * 修改品牌
 */
export async function updateBrand(form) {
	return request({
		url: '/mall/brand',
		method: 'put',
		data: form
	})
}

/**
 * 添加品牌
 */
export async function addBrand(form) {
	return request({
		url: '/mall/brand',
		method: 'post',
		data: form
	})
}

/**
 * 获取抢购活动信息
 */
export async function getFlashSale(param) {
	return request({
		url: '/mall/flash_sale/' + param,
		method: 'get'
	})
}

/**
 * 获取能参与活动的商品列表和规格
 */
export async function selectCanPromList(param) {
	return request({
		url: '/mall/goods/spec_goods_price/page',
		method: 'get',
		params: param
	})
}

/**
 * 新增抢购活动
 */
export async function addFlashSale(form) {
	return request({
		url: '/mall/flash_sale',
		method: 'post',
		data: form
	})
}

/**
 * 编辑抢购活动
 */
export async function updateFlashSale(form) {
	return request({
		url: '/mall/flash_sale',
		method: 'put',
		data: form
	})
}

/**
 * 获取抢购活动列表
 */
export async function getFlashSalePage(param) {
	return request({
		url: '/mall/flash_sale/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除抢购活动
 */
export async function deleteFlashSale(param) {
	return request({
		url: '/mall/flash_sale/' + param,
		method: 'delete'
	})
}

/**
 * 获取团购活动列表
 */
export async function getGroupBuyPage(param) {
	return request({
		url: '/mall/group_buy/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除团购活动
 */
export async function deleteGroupBuy(param) {
	return request({
		url: '/mall/group_buy/' + param,
		method: 'delete'
	})
}

/**
 * 获取团购活动信息
 */
export async function getGroupBuy(param) {
	return request({
		url: '/mall/group_buy/' + param,
		method: 'get'
	})
}

/**
 * 新增团购活动
 */
export async function addGroupBuy(form) {
	return request({
		url: '/mall/group_buy',
		method: 'post',
		data: form
	})
}

/**
 * 编辑团购活动
 */
export async function updateGroupBuy(form) {
	return request({
		url: '/mall/group_buy',
		method: 'put',
		data: form
	})
}

/**
 * 获取促销活动列表
 */
export async function getGoodsSalePage(param) {
	return request({
		url: '/mall/prom_goods/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除促销活动
 */
export async function deleteGoodsSale(param) {
	return request({
		url: '/mall/prom_goods/' + param,
		method: 'delete'
	})
}

/**
 * 获取促销活动信息
 */
export async function getGoodsSale(param) {
	return request({
		url: '/mall/prom_goods/' + param,
		method: 'get'
	})
}

/**
 * 获取优惠券列表
 */
export async function getCouponList(param) {
	return request({
		url: '/mall/coupon/list',
		method: 'get',
		params: param
	})
}

/**
 * 新增促销活动
 */
export async function addGoodsSale(form) {
	return request({
		url: '/mall/prom_goods',
		method: 'post',
		data: form
	})
}

/**
 * 编辑促销活动
 */
export async function updateGoodsSale(form) {
	return request({
		url: '/mall/prom_goods',
		method: 'put',
		data: form
	})
}

/**
 * 获取订单促销列表
 */
export async function getOrderSalePage(param) {
	return request({
		url: '/mall/prom_order/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取订单列表
 */
export async function getOrderPage(param) {
	return request({
		url: '/order/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除订单促销活动
 */
export async function deleteOrderSale(param) {
	return request({
		url: '/mall/prom_order/' + param,
		method: 'delete'
	})
}

/**
 * 获取订单促销
 */
export async function getOrderSale(param) {
	return request({
		url: '/mall/prom_order/' + param,
		method: 'get'
	})
}

/**
 * 新增订单促销活动
 */
export async function addOrderSale(form) {
	return request({
		url: '/mall/prom_order',
		method: 'post',
		data: form
	})
}

/**
 * 编辑订单促销活动
 */
export async function updateOrderSale(form) {
	return request({
		url: '/mall/prom_order',
		method: 'put',
		data: form
	})
}

/**
 * 获取拼团活动列表
 */
export async function getTeamActivityPage(param) {
	return request({
		url: '/mall/team/activity/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除拼团活动
 */
export async function deleteTeamActivity(param) {
	return request({
		url: '/mall/team/activity/' + param,
		method: 'delete'
	})
}

/**
 * 抽奖
 */
export async function lottery(form) {
	return request({
		url: '/mall/team/lottery',
		method: 'post',
		data: form
	})
}

/**
 * 获取拼团活动
 */
export async function getTeamActivity(param) {
	return request({
		url: '/mall/team/activity/' + param,
		method: 'get'
	})
}

/**
 * 新增拼团活动
 */
export async function addTeamActivity(form) {
	return request({
		url: '/mall/team/activity',
		method: 'post',
		data: form
	})
}

/**
 * 编辑拼团活动
 */
export async function updateTeamActivity(form) {
	return request({
		url: '/mall/team/activity',
		method: 'put',
		data: form
	})
}

/**
 * 获取预售活动列表
 */
export async function getPreSellPage(param) {
	return request({
		url: '/mall/pre_sell/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除预售活动
 */
export async function deletePreSell(param) {
	return request({
		url: '/mall/pre_sell/delete',
		method: 'put',
		data: param
	})
}

/**
 * 结束预售活动
 */
export async function endPreSell(form) {
	return request({
		url: '/mall/pre_sell/is_finished',
		method: 'put',
		data: form
	})
}

/**
 * 获取预售活动
 */
export async function getPreSell(param) {
	return request({
		url: '/mall/pre_sell/' + param,
		method: 'get'
	})
}

/**
 * 新增预售活动
 */
export async function addPreSell(form) {
	return request({
		url: '/mall/pre_sell',
		method: 'post',
		data: form
	})
}

/**
 * 编辑预售活动
 */
export async function updatePreSell(form) {
	return request({
		url: '/mall/pre_sell',
		method: 'put',
		data: form
	})
}

/**
 * 获取商品列表分页
 */
export async function getGoodsPage(param) {
	return request({
		url: '/mall/goods/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取优惠券列表
 */
export async function getCouponPage(param) {
	return request({
		url: '/mall/coupon/store/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除优惠券
 */
export async function deleteCoupon(param) {
	return request({
		url: '/mall/coupon/' + param,
		method: 'delete'
	})
}

/**
 * 获取优惠券
 */
export async function getCoupon(param) {
	return request({
		url: '/mall/coupon',
		method: 'get',
		params: param
	})
}

/**
 * 获取优惠券面额列表
 */
export async function getCouponPrices(param) {
	return request({
		url: '/mall/coupon_price/list',
		method: 'get',
		params: param
	})
}

/**
 * 新增优惠券
 */
export async function addCoupon(form) {
	return request({
		url: '/mall/coupon',
		method: 'post',
		data: form
	})
}

/**
 * 编辑优惠券
 */
export async function updateCoupon(form) {
	return request({
		url: '/mall/coupon',
		method: 'put',
		data: form
	})
}

/**
 * 获取地址列表
 */
export async function getRegionList(param) {
	return request({
		url: '/mall/region/list',
		method: 'get',
		params: param
	})
}

/**
 * 获取地址列表,树状结构，目前用于运费模板
 */
export async function getRegionParentList(param) {
	return request({
		url: '/mall/region/parent/list',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺信息
 */
export async function updateStore(form) {
	return request({
		url: '/seller/store',
		method: 'put',
		data: form
	})
}

/**
 * 获取店铺导航列表
 */
export async function getStoreNavPage(param) {
	return request({
		url: '/seller/store_navigation/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺导航
 */
export async function updateStoreNav(form) {
	return request({
		url: '/seller/store_navigation',
		method: 'put',
		data: form
	})
}

/**
 * 删除店铺导航
 */
export async function deleteStoreNav(param) {
	return request({
		url: '/seller/store_navigation',
		method: 'delete',
		params: param
	})
}

/**
 * 获取店铺导航
 */
export async function getStoreNav(param) {
	return request({
		url: '/seller/store_navigation',
		method: 'get',
		params: param
	})
}

/**
 * 新增店铺导航
 */
export async function addStoreNav(form) {
	return request({
		url: '/seller/store_navigation',
		method: 'post',
		data: form
	})
}

/**
 * 获取店铺经营类目列表
 */
export async function getStoreBindClassPage(param) {
	return request({
		url: '/seller/store_bind_class/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除店铺经营类目
 */
export async function deleteStoreBindClass(param) {
	return request({
		url: '/seller/store_bind_class',
		method: 'delete',
		params: param
	})
}

/**
 * 新增店铺经营类目
 */
export async function addStoreBindClass(form) {
	return request({
		url: '/seller/store_bind_class',
		method: 'post',
		data: form
	})
}

/**
 * 新增店铺经营类目
 */
export async function addStoreBindClassList(form) {
	return request({
		url: '/seller/store_bind_class/batch',
		method: 'post',
		data: form
	})
}

/**
 * 获取店铺商品分类列表
 */
export async function getStoreGoodsClassTree(param) {
	return request({
		url: '/seller/store_goods_class/tree',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺商品顶级分类列表
 */
export async function getStoreGoodsClassList(param) {
	return request({
		url: '/seller/store_goods_class/list',
		method: 'get',
		params: param
	})
}

/**
 * 删除店铺商品分类
 */
export async function deleteStoreGoodsClass(param) {
	return request({
		url: '/seller/store_goods_class',
		method: 'delete',
		params: param
	})
}

/**
 * 获取店铺商品分类
 */
export async function getStoreGoodsClass(param) {
	return request({
		url: '/seller/store_goods_class',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺商品分类
 */
export async function updateStoreGoodsClass(form) {
	return request({
		url: '/seller/store_goods_class',
		method: 'put',
		data: form
	})
}

/**
 * 新增店铺商品分类
 */
export async function addStoreGoodsClass(form) {
	return request({
		url: '/seller/store_goods_class',
		method: 'post',
		data: form
	})
}

/**
 * 获取供货商列表
 */
export async function getSupplierList(param) {
	return request({
		url: '/seller/suppliers/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改供货商
 */
export async function updateSupplier(form) {
	return request({
		url: '/seller/suppliers',
		method: 'put',
		data: form
	})
}

/**
 * 新增供货商
 */
export async function addSupplier(form) {
	return request({
		url: '/seller/suppliers',
		method: 'post',
		data: form
	})
}

/**
 * 删除店铺商品分类
 */
export async function deleteSupplier(param) {
	return request({
		url: '/seller/suppliers/' + param,
		method: 'delete'
	})
}

/**
 * 获取关注店铺会员列表
 */
export async function getStoreCollectList(param) {
	return request({
		url: '/user/store_collect/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺地址列表
 */
export async function getStoreAddressList(param) {
	return request({
		url: '/seller/store_address/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除店铺地址
 */
export async function deleteAddress(param) {
	return request({
		url: '/seller/store_address',
		method: 'delete',
		params: param
	})
}

/**
 * 获取店铺地址
 */
export async function getStoreAddress(param) {
	return request({
		url: '/seller/store_address',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺地址
 */
export async function updateStoreAddress(form) {
	return request({
		url: '/seller/store_address',
		method: 'put',
		data: form
	})
}

/**
 * 新增店铺地址
 */
export async function addStoreAddress(form) {
	return request({
		url: '/seller/store_address',
		method: 'post',
		data: form
	})
}

/**
 * 获取运费模板
 */
export async function getFreightTemplateById(id) {
	return request({
		url: '/mall/freight_template/' + id,
		method: 'get'
	})
}

/**
 * 新增运费模板
 */
export async function addFreightemplate(param) {
	return request({
		url: '/mall/freight_template',
		method: 'post',
		data: param
	})
}

/**
 * 获取默认运费模板
 */
export async function getFreightConfigs(param) {
	return request({
		url: '/mall/freight_template/configs',
		method: 'get',
		params: param
	})
}

/**
 * 获取默认运费模板
 */
export async function getFreightConfigList(id, param) {
	return request({
		url: '/mall/freight_template/configs/' + id,
		method: 'get',
		params: param
	})
}

export async function addFreightConfig(id, data) {
	return request({
		url: '/mall/freight_template/config/' + id,
		method: 'post',
		data: data
	})
}

export async function deleteFreightConfig(id) {
	return request({
		url: '/mall/freight_template/config/' + id,
		method: 'delete'
	})
}

export async function updateFreightConfig(param) {
	return request({
		url: '/mall/freight_template/config',
		method: 'put',
		data: param
	})
}

export async function getFreightRegions(id) {
	return request({
		url: '/mall/freight_template/regions/' + id,
		method: 'get'
	})
}

export async function addOrUpdateFreightRegions(id, param) {
	return request({
		url: '/mall/freight_template/regions/' + id,
		method: 'post',
		data: param
	})
}


/**
 * 编辑运费模板
 */
export async function updateFreightemplate(param) {
	return request({
		url: '/mall/freight_template',
		method: 'put',
		data: param
	})
}

/**
 * 新增搭配购
 */
export async function addCombination(param) {
	return request({
		url: '/mall/combination',
		method: 'post',
		data: param
	})
}


/**
 * 搭配购详情
 */
export async function updateCombinationInfo(param) {
	return request({
		url: '/mall/combination/info',
		method: 'put',
		data: param
	})
}

/**
 * 删除搭配购
 */
export async function deleteCombinationById(id) {
	return request({
		url: '/mall/combination/' + id,
		method: 'delete'
	})
}

/**
 * 获取搭配购信息
 */
export async function getCombination(id) {
	return request({
		url: '/mall/combination/' + id,
		method: 'get'
	})
}


/**
 * 搭配购是否上架
 */
export async function isOnSaleCollocation(param) {
	return request({
		url: '/mall/combination/is_on_sale',
		method: 'put',
		params: param
	})
}

/**
 * 获取订单详情
 */
export async function getOrder(param) {
	return request({
		url: '/order/seller',
		method: 'get',
		params: param
	})
}

/**
 * 获取订单详情
 */
export async function getOrderGoods(param) {
	return request({
		url: '/order/order_goods',
		method: 'get',
		params: param
	})
}

/**
 * 获取默认店铺
 */
export async function getSellerDefaultStore() {
	return request({
		url: '/seller/store/default_store',
		method: 'get',
	})
}

/**
 * 获取订单发货列表D
 */
export async function getDeliveryList(param) {
	return request({
		url: '/order/delivery_doc/list/seller',
		method: 'get',
		params: param
	})
}

/**
 * 获取订单评论
 */
export async function getOrderComment(param) {
	return request({
		url: '/order/order_comment',
		method: 'get',
		params: param
	})
}

/**
 * 获取订单评论
 */
export async function getOrderActionByOrderId(param) {
	return request({
		url: '/order/order_action/' + param,
		method: 'get'
	})
}

/**
 * 无效订单
 */
export async function invailOrder(param) {
	return request({
		url: '/order/order_status/5',
		method: 'put',
		data: param
	})
}

/**
 * 确认订单
 */
export async function comfirmOrder(param) {
	return request({
		url: '/order/order_status/1',
		method: 'put',
		data: param
	})
}

/**
 * 取消确认订单
 */
export async function cancelComfirmOrder(param) {
	return request({
		url: '/order/order_status/0',
		method: 'put',
		data: param
	})
}

/**
 * 订单退款
 */
export async function refundOrder(refundType, param) {
	return request({
		url: '/order/pay_status/3/seller?refund_type=' + refundType,
		method: 'put',
		data: param
	})
}

/**
 * 订单退款
 */
export async function modifyOrder(param) {
	return request({
		url: '/order/seller',
		method: 'put',
		data: param
	})
}

/**
 * 获取团长
 */
export async function getFound(param) {
	return request({
		url: '/mall/team/found',
		method: 'get',
		params: param
	})
}
/**
 * 获取团员
 */
export async function getFollows(param) {
	return request({
		url: '/mall/team/follows',
		method: 'get',
		params: param
	})
}

/**
 * 同意售后单
 */
export async function agreeReturnGoods(param) {
	return request({
		url: '/order/return_goods/status/1',
		method: 'put',
		data: param
	})
}

/**
 * 拒绝售后单
 */
export async function refundReturnGoods(param) {
	return request({
		url: '/order/return_goods/status/-1',
		method: 'put',
		data: param
	})
}

/**
 * 售后单确认收货
 */
export async function receiveReturnGoods(param) {
	return request({
		url: '/order/return_goods/status/3',
		method: 'put',
		data: param
	})
}

/**
 * 获取用户
 */
export async function getUserList(param) {
	return request({
		url: '/user/page/coupon',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺指定类目
 */
export async function getStoreBindClassOne(params) {
	return request({
		url: '/seller/store_bind_class',
		method: 'get',
		params: params
	})
}


/**
 * 群发邮件
 */
// const Qs = require('qs');
export async function getEmailSends(param) {
	return request({
		url: '/user/mail/send',
		method: 'post',
		data: param,
		// paramsSerializer: params => {
		//      return Qs.stringify(params, { indices: false });
		//   } 
	})
}


/**
 * 商家邮件日志
 */
export async function getEmaillog(param) {
	return request({
		url: '/user/mail/log',
		method: 'get',
		params: param
	})
}

/*
接收人详情
*/
export async function getEmailReceive(param) {
	return request({
		url: '/user/mail/receiver',
		method: 'get',
		params: param
	})
}


/**
 * 申请类目
 */
export async function applyClass(params) {
	return request({
		url: '/seller/store_bind_class/has_bind_class',
		method: 'get',
		data: params
	})
}

/**
 * 商品绑定运费模板
 */
export async function goodsBindTpl(params) {
	return request({
		url: '/mall/freight_template/bind_goods',
		method: 'post',
		params: params
	})
}

export async function getCatTax(params) {
	return request({
		url: '/mall/tax/cat',
		method: 'get',
		params: params
	})
}

export async function getParentGoodsCatRegionTax(params) {
	return request({
		url: '/mall/tax/parent/cat',
		method: 'get',
		params: params
	})
}

//获取快递公司列表
export async function getShippingList(params) {
	return request({
		url: '/seller/shipping/list',
		method: 'get',
		params: params
	})
}

//商家发货
export async function sellerDelivery(id, params) {
	return request({
		url: '/order/return_goods/delivery/seller?id=' + id,
		method: 'post',
		data: params
	})
}

//获取店铺主题列表
export async function getStoreTheme() {
	return request({
		url: '/seller/store/template',
		method: 'get'
	})
}

//获取店铺绑定主题
export async function getStoreBindTheme(storeId) {
	return request({
		url: '/seller/store/bind_template/' + storeId,
		method: 'get'
	})
}

//店铺绑定主题
export async function storeBindTpl(params) {
	return request({
		url: '/seller/store/bind_template',
		method: 'put',
		params: params
	})
}

/**
 * 添加多条商品
 */
export async function addListGoods(form) {
	return request({
		url: '/mall/goods_list_add',
		method: 'post',
		data: form
	})
}
//获取店铺绑定主题
export async function getgoodsList(params) {
	return request({
		url: '/mall/goods/list',
		method: 'get',
		params: params
	})
}
 
 //添加海报信息
 export async function addfoundPoster(data) {
   return request({
     url: '/mall/poster/found_poster',
     method: 'post',
 	data: data
   })
 }
 
 //获得商家海报
 export async function getPosterSeller(params) {
   return request({
     url: '/mall/poster/seller_poster',
     method: 'get',
     params: params
   })
 }
 
 //添加试用商品
 export async function addTrialtGoods(data) {
   return request({
     url: '/mall/trial_goods',
     method: 'post',
 	data: data
   })
 }
 
 
 //获得商家试用商品
 export async function getIpageTrialtGoods(params) {
   return request({
     url: '/mall/trial_goods',
     method: 'get',
     params: params
   })
 }
 
 
 
 //所有申请者
 export async function getTrialtApply(params) {
   return request({
     url: '/mall/trial_apply',
     method: 'get',
     params: params
   })
 }
 
 
 //可抽取人
 export async function getExtractCount(params) {
   return request({
     url: '/mall/trial_apply/is_get',
     method: 'get',
     params: params
   })
 }
 
 
 
 //单个抽取
 export async function addTextTakeRomPost(params) {
 	return request({
 		url: '/mall/trial_apply/extract_user',
 		method: 'put',
 		params: params
 	})
 }
 
 
 //全部抽取
 export async function addsTextTakeRomId(params) {
 	return request({
 		url: '/mall/trial_apply/all_extract_user',
 		method: 'put',
 		params: params
 	})
 }
 
 
 
 //本店商家的试用订单
 export async function getTrialOrderIPage(params) {
 	return request({
 	  url: '/order/trial_order',
 	  method: 'get',
 	  params: params
 	})
 }
 
 
 
 //获得试用订单收货人信息
 export async function getTrialOrderShippingByOrderSn(params) {
 	return request({
 	  url: '/order/trial_order_shipping',
 	  method: 'get',
 	  params: params
 	})
 }
 
 //获得试用订单信息
 export async function getTrialOrderGoods(params) {
 	return request({
 	  url: '/order/trial_order_goods',
 	  method: 'get',
 	  params: params
 	})
 }
 
 
 //发货试用
 export async function addTrialOrderDeliver(data) {
   return request({
     url: '/order/trial_order_deliver',
     method: 'post',
 	data: data
   })
 }
 
 
 //修改试用商品
 export async function updateTrialGoods(data) {
   return request({
     url: '/mall/trial_goods',
     method: 'put',
 	data: data
   })
 }
 
 
 //获得该试用商品的申请人数量
 export async function getTrialIdCounts(params) {
 	return request({
 	  url: '/mall/trial_apply/trial_id_count',
 	  method: 'get',
 	  params: params
 	})
 }
 
 
 /**
  * 删除试用商品
  */
 export async function deleteTaialBYid(param) {
 	return request({
 		url: '/mall/trial_goods',
 		method: 'delete',
 		params: param
 	})
 }

 
 
 //获得该试用商品的申请人数量
 export async function getemaillogBYemail(params) {
 	return request({
 	  url: '/user/mail/',
 	  method: 'get',
 	  params: params
 	})
 }
 

 
 
 //获得所有的头衔等级
 export async function getuserLevelpage(params) {
 	return request({
 	  url: '/user/user_level/page',
 	  method: 'get',
 	  params: params
 	})
 }
 
 
 /**
  * 获取商品的规格组合
  */
 export async function getSpecimgList(param) {
 	return request({
 		url: '/mall/specImage',
 		method: 'get',
 		params: param
 	})

 }


 /**
  * 获取动态类型分页
  */
 export async function getTrendTypes(param){
 	return request({
 		url: '/mall/trend/type_list',
 		method: 'get',
 		params: param
 	})
 }
 /**
  * 回复动态消息
  */
 export async function addReplyComment(data){
 	return request({
 		url: '/mall/trend_comment/comment_message',
 		method: 'post',
 		data: data
 	})
 }
 /**
  * 动态删除
  */
 export async function deleteTrend(param){
 	return request({
 		url: '/mall/trend/trendModify',
 		method: 'put',
 		params: param
 	})
 }
 /**
  * 获取评论人消息内容
  */
 export async function getTrendComment(param){
 	return request({
 		url: '/mall/trend_comment',
 		method: 'get',
 		params: param
 	})
 }
 /**
 /**
  * 获取关联商品
  */
 export async function getTrendGoods(param){
 	return request({
 		url: '/mall/goods/page',
 		method: 'get',
 		params: param
 	})
 }
 /**
  * 获取图片标签类型分页
  */
 export async function getImageLabels(param){
 	return request({
 		url: '/mall/trend/label_list',
 		method: 'get',
 		params: param
 	})
 }
 /**
  * 动态修改
  */
 export async function updateTrend(param){
 	return request({
 		url: '/mall/trend/updateTrend',
 		method: 'put',
 		data: param
 	})
 }
/**
 * 获取动态发布信息
 */
export async function getTrend(param){
	return request({
		url: '/mall/trend/trendInfo',
		method: 'get',
		params: param
	})
}
 
 /**
  * 获取商家动态发布
  */
 export async function  getTrendGoodsPage(param){
 	return request({
 		url: '/mall/trend/goods_list',
 		method: 'get',
 		params: param
 	})
 }
 /**
  * 发布动态
  */
 export async function addTrend(data){
 	return request({
 		url: '/mall/trend',
 		method: 'post',
 		data: data
 	})
 }

  /**
  * 兑换码
  */
 export async function exchangeCode(code){
	return request({
		url: '/order/virtual_code/' + code,
		method: 'put'
	})
}

 /**
  * 获取兑换码
  */
 export async function getVirtualCode(param){
	return request({
		url: '/order/virtual_code',
		method: 'get',
		params: param
	})
}

 /**
  * 获取兑换码分页
  */
 export async function getVirtualCodePage(param){
	return request({
		url: '/order/virtual_code/page',
		method: 'get',
		params: param
	})
}

/**
  * tidio
  */
export async function saveTidio(data){
	return request({
		url: '/seller/tidio',
		method: 'post',
		data: data
	})
}

/**
  * tidio
  */
 export async function getTidio(params){
	return request({
		url: '/seller/tidio',
		method: 'get',
		params: params
	})
}

 export async function clearGoodsThumImage(params){
	return request({
		url: '/mall/goods/thumb_image',
		method: 'delete',
		params: params
	})
}

export async function sellerReceiveCodOrder(sn){
	return request({
		url: '/order/order_status/2/seller?order_sn=' + sn,
		method: 'put'
	})
}
export async function getGoodsUserLevelPriceList(params){
	return request({
		url: '/mall/goods/user_level/list',
		method: 'get',
		params: params
	})
}

export async function getUserLevel(params){
	return request({
		url: '/user/user_level/list',
		method: 'get',
		params: params
	})
}

 /**
  * 获取门店
  */
 export async function  getShopPage(param){
     return request({
         url: '/shop/shop',
         method: 'get',
         params: param
     })
 }
 
 /**
  * 添加门店
  */
 export async function addShop(data){
     return request({
         url: '/shop/shop',
         method: 'post',
         data: data
     })
 }
 
/**
 * 删除门店
 */
export async function deleteShop(param) {
    return request({
        url: '/shop/shop',
        method: 'delete',
        params: param
    })
}

 /**
  * 添加门店
  */
 export async function updateShop(data){
     return request({
         url: '/shop/shop',
         method: 'put',
         data: data
     })
 }
 
 /**
  * 获用户是否存在
  */
 export async function  getUserCheck(param){
     return request({
         url: '/user/get_check',
         method: 'get',
         params: param
     })
 }
 /**
  * 获取门店轮播图
  */
 export async function  getShopSlide(param){
     return request({
         url: '/shop/shop_slide',
         method: 'get',
         params: param
     })
 }
 
 
 /**
  * 删除门店轮播图
  */
 export async function deleteShopSlide(param) {
     return request({
         url: '/shop/shop_slide',
         method: 'delete',
         params: param
     })
 }
 
 
 /**
  * 添加或修改门店轮播图
  */
 export async function addorupdateShopSlide(data){
     return request({
         url: '/shop/shop_slide',
         method: 'post',
         data: data
     })
 }
 /*获取选中的货币*/ 
export async function getCurrency () {
    return request({
        url: "/mall/currency",
        method: 'get'
    })
}

export async function getT (param) {
    return request({
        url: "order/delivery_doc/print/list",
        method: 'get',
		params: param
    })
}